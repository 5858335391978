import { ReactNode } from "react";
import { ButtonProps, HStack } from "@chakra-ui/react";
import { MdAdd, MdOutlineCancel, MdOutlineSave } from "react-icons/md";
import { Button } from "@shared/components/Button/Button.component";
import { USER_ROLES } from "@config/constants.config";
import { usePermission } from "@hooks/usePermission.hook";

type AddAccountButtonsProps = {
  isCreating: boolean;
  onAdd: () => void;
  onSave: () => void;
  onCancel: () => void;
  isLoading?: boolean;
  extraButton?: ReactNode;
  onAddButtonProps?: ButtonProps;
  onSaveButtonProps?: ButtonProps;
  onCancelButtonProps?: ButtonProps;
  onAddButtonText?: string;
  onSaveButtonText?: string;
  onCancelButtonText?: string;
};

export function AddAccountButtons({
  isCreating,
  onAdd,
  onSave,
  onCancel,
  isLoading,
  extraButton,
  onAddButtonProps,
  onSaveButtonProps,
  onCancelButtonProps,
  onAddButtonText,
  onSaveButtonText,
  onCancelButtonText,
}: Readonly<AddAccountButtonsProps>) {
  const { isPermitted } = usePermission();

  const hasAddPermission = isPermitted([USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_EDITOR]);
  const hasSavePermission = isPermitted([USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_EDITOR]);

  return (
    <>
      {isCreating ? (
        <HStack flexWrap="wrap">
          <Button
            leftIcon={<MdOutlineCancel />}
            variant="outline"
            borderRadius="6px"
            colorScheme="gray.400"
            isLoading={isLoading}
            onClick={onCancel}
            {...onCancelButtonProps}
          >
            {onCancelButtonText ?? "Cancel"}
          </Button>
          {extraButton}
          <Button
            leftIcon={<MdOutlineSave />}
            variant="whiteLabel"
            onClick={onSave}
            isLoading={isLoading}
            {...onSaveButtonProps}
            display={hasSavePermission ? "flex" : "none"}
          >
            {onSaveButtonText ?? "Save"}
          </Button>
        </HStack>
      ) : (
        <Button
          leftIcon={<MdAdd />}
          variant="whiteLabel"
          onClick={onAdd}
          isLoading={isLoading}
          _loading={{
            "&:hover": {
              backgroundColor: "whiteLabel.primary",
            },
          }}
          {...onAddButtonProps}
          display={hasAddPermission ? "flex" : "none"}
        >
          {onAddButtonText ?? "Add Account"}
        </Button>
      )}
    </>
  );
}
