import { HStack, Heading, Stack, Text, VStack } from "@chakra-ui/react";

export function UnauthorizedTemplate() {
  return (
    <Stack position="relative" h="full" w="full" alignItems="flex-start" gap={6} p={9} pt={10}>
      <VStack w="full" gap={7} px={1} alignItems="flex-start">
        <HStack w="full" justifyContent="space-between" alignItems="flex-start">
          <Heading variant="h1" fontSize="24px" color="gray.700" lineHeight="36px" fontWeight={600}>
            Unauthorized
          </Heading>
        </HStack>
        <Text fontSize={14} color="#718096" fontWeight={700} lineHeight="20px">
          Please contact your administrator.
        </Text>
      </VStack>
    </Stack>
  );
}
