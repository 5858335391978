import { FC, ReactNode, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ModalProps,
} from "@chakra-ui/react";

type ModalOptions = {
  isOpen: boolean;
  isLoading: boolean;
  onAccept: () => void;
  onDecline: () => void;
  modalBody?: ReactNode;
  modalHeader?: ReactNode;
  modalCancelButton?: ReactNode;
  modalConfirmButton?: ReactNode;
  modalProps?: Partial<ModalProps>;
};

export const useConfirmationModal = (
  closeOnAccept?: boolean
): [
  ModalOptions,
  (onAccept: () => void, onDecline?: () => void) => void,
  () => void,
  (isLoading: boolean) => void,
] => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onAcceptRef = useRef<() => void>();
  const onDeclineRef = useRef<(() => void) | undefined>();

  const handleAccept = () => {
    closeOnAccept && setIsOpen(false);

    return onAcceptRef.current?.();
  };

  const handleDecline = () => {
    setIsOpen(false);

    onDeclineRef.current?.();
  };

  const openModal = (onAccept: () => void, onDecline?: () => void): void => {
    onAcceptRef.current = onAccept;
    onDeclineRef.current = onDecline;

    setIsOpen(true);
  };

  const closeModal = (): void => {
    setIsOpen(false);
  };

  const handleSetIsLoading = (isLoading: boolean): void => {
    setIsLoading(isLoading);
  };

  return [
    { isOpen, isLoading, onAccept: handleAccept, onDecline: handleDecline },
    openModal,
    closeModal,
    handleSetIsLoading,
  ];
};

export const ConfirmationModal: FC<ModalOptions> = ({
  isOpen,
  isLoading,
  onAccept,
  onDecline,
  modalHeader,
  modalBody,
  modalConfirmButton,
  modalCancelButton,
  modalProps,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onDecline} isCentered {...modalProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalHeader ?? "Confirmation"}</ModalHeader>
        <ModalBody>{modalBody ?? "Are you sure you want to proceed?"}</ModalBody>
        <ModalFooter>
          <Button
            variant={"whiteLabel"}
            mr={3}
            onClick={onAccept}
            isLoading={isLoading}
            _hover={{
              color: "white",
              bg: "whiteLabel.secondary",
              borderColor: "whiteLabel.secondary",
            }}
          >
            {modalConfirmButton ?? "Confirm"}
          </Button>
          <Button onClick={onDecline} isLoading={isLoading} variant="whiteLabelOutline">
            {modalCancelButton ?? "Cancel"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
