import { chakra, Skeleton, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";

type DataTableSkeletonProps = {
  skeletonRows: number;
  skeletonColumns: number;
  isInsight?: boolean;
};

export function DataTableSkeleton({
  skeletonRows,
  skeletonColumns,
  isInsight = false,
}: Readonly<DataTableSkeletonProps>) {
  console.log(skeletonColumns);
  return (
    <TableContainer borderRadius="12px" border="1px solid #CBD5E0" backgroundColor="white" w="full">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th px={8} paddingBottom={3} borderColor="#CBD5E0">
              <chakra.span pl={4} verticalAlign="text-bottom">
                INHERITOR
              </chakra.span>
            </Th>
            <Th px={8} paddingBottom={3} borderColor="#CBD5E0">
              {isInsight ? (
                <chakra.span pl={4} verticalAlign="text-bottom">
                  {/* we should have a check to see if the institution is a bank or credit union, but we don't have the data loaded yet */}
                  MEMBER
                </chakra.span>
              ) : (
                <chakra.span pl={4} verticalAlign="text-bottom">
                  DECEASED
                </chakra.span>
              )}
            </Th>
            <Th px={8} paddingBottom={3} borderColor="#CBD5E0">
              {isInsight ? (
                <chakra.span pl={4} verticalAlign="text-bottom">
                  INHERITANCE
                </chakra.span>
              ) : (
                <chakra.span pl={4} verticalAlign="text-bottom">
                  ASSIGNED AGENT
                </chakra.span>
              )}
            </Th>
            <Th px={8} paddingBottom={3} borderColor="#CBD5E0">
              <chakra.span pl={4} verticalAlign="text-bottom">
                ACCOUNTS
              </chakra.span>
            </Th>
            <Th paddingTop={6} px={8} paddingBottom={3} borderColor="#CBD5E0">
              {isInsight ? (
                <chakra.span pl={4} verticalAlign="text-bottom">
                  CONDOLENCES GIFT
                </chakra.span>
              ) : (
                <chakra.span pl={4} verticalAlign="text-bottom">
                  INHERITANCE STATUS
                </chakra.span>
              )}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {[...Array(skeletonRows).keys()].map(id => (
            <Tr key={id}>
              {Array.from({ length: skeletonColumns }, (_, idx) => (
                <Th key={idx} paddingTop={6} paddingBottom={3} px={8}>
                  <Skeleton height="25px" {...{ startColor: "#c0eeee", endColor: "#79d5d5" }} />
                </Th>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
