import { Stack } from "@chakra-ui/react";
import { DeceasedOverview } from "../InheritorsView/DeceasedOverview.component";

export function DeceasedsViewTemplate() {
  return (
    <Stack position="relative" w="full" alignItems="flex-start" gap={3} p={9} pt={12}>
      <DeceasedOverview />
    </Stack>
  );
}
