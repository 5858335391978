import { ImSpinner2 } from "react-icons/im";
import { Stack, StackProps, Text } from "@chakra-ui/react";
import * as styles from "./styles/Loading.css";

type LoadingProps = {
  label?: string;
} & StackProps;

export function Loading({ label, ...rest }: LoadingProps) {
  return (
    <Stack className={styles.loading} {...rest}>
      <ImSpinner2 size={"50"} color={"#56c8c8"} className={styles.spinner} />
      <Text textAlign={"center"} fontWeight={500}>
        {label ?? "Loading..."}
      </Text>
    </Stack>
  );
}
