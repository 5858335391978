import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Divider, Flex, HStack, Heading, VStack } from "@chakra-ui/react";
import { DocumentType } from "@trustribbon/ribbon-ec-types";
import { useDocumentStore } from "@shared/store/document.store";
import { UploadDocument } from "@shared/components/FormComponents/UploadDocument.component";
import { EnhancedFile } from "@shared/types";
import { AdminNotifyNavigationBlock } from "../components/AdminNotifyNavigation.component";

type FileUpload = { [fieldName in DocumentType]: EnhancedFile | null };

type UploadDocumentFormProps = {
  fieldName: DocumentType;
  uploadFormLabel: string;
  onClickNext: () => void;
  onClickBack?: () => void;
  isRequired?: boolean;
};

export function UploadDocumentForm({
  fieldName,
  uploadFormLabel,
  onClickNext,
  onClickBack,
  isRequired,
}: Readonly<UploadDocumentFormProps>) {
  const { document, setDocument } = useDocumentStore(state => ({
    document: state.document,
    setDocument: state.setDocument,
  }));
  const formMethods = useForm<FileUpload>({
    defaultValues: {
      [fieldName]: document?.[fieldName] ?? null,
    },
  });

  const { handleSubmit, formState } = formMethods;
  const { errors } = formState;
  const hasErrors = !!errors["death-certificates"] || !!errors["government-ids"];

  const onSubmit: SubmitHandler<FileUpload> = data => {
    setDocument(data ?? null);

    return onClickNext();
  };

  return (
    <FormProvider {...formMethods}>
      <VStack w="full" gap={7}>
        <HStack w="full" justifyContent="space-between">
          <Heading variant="h1" fontSize="24px" color="gray.700" lineHeight="40px" fontWeight={600}>
            {uploadFormLabel}
          </Heading>
        </HStack>
      </VStack>
      <form onSubmit={handleSubmit(onSubmit)} id={`${fieldName}-form`}>
        <Flex direction="column">
          <Flex gap={8} direction="column" pb={16}>
            <HStack gap={6} align="flex-start" direction="row" flexWrap="wrap">
              <UploadDocument
                documentType={fieldName}
                rules={{ required: isRequired && "Please upload a document" }}
              />
            </HStack>
          </Flex>
          <Divider color="#CBD5E0" />
          <AdminNotifyNavigationBlock
            isDisabled={hasErrors}
            onClickNext={handleSubmit(onSubmit)}
            onClickBack={onClickBack}
          />
        </Flex>
      </form>
    </FormProvider>
  );
}
