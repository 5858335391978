import React from "react";
import ReactDOM from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { CONSTANTS, ROUTES } from "@config/constants.config.ts";
import { Sentry } from "./sentry.ts";
import { App } from "./App.tsx";

if (process.env.NODE_ENV === "production" && import.meta.env.VITE_SENTRY_DSN_ADMIN) {
  Sentry.init();
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Auth0Provider
      domain={CONSTANTS.VITE_APP_AUTH0_DOMAIN}
      clientId={CONSTANTS.VITE_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin + ROUTES.INHERITORS,
        audience: CONSTANTS.VITE_APP_AUTH0_AUDIENCE,
      }}
      cacheLocation="localstorage"
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);
