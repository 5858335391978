import { useEffect, useRef } from "react";
import {
  GetDeceasedPersonDetailsResponse,
  InheritorWithoutSsn,
} from "@trustribbon/ribbon-ec-types";
import { DeceasedPersonWithCaseId, useInheritorStore } from "@store/inheritor.store";
import { useInheritorDetails } from "@hooks/useInheritorDetails.hook";
import { DeceasedCard } from "./components/DeceasedCard/DeceasedCard.component";
import {
  InheritorCard,
  InheritorCardRef,
} from "./components/InheritorCard/InheritorCard.component";
import {
  DeceasedAccountsCard,
  FinancialAccountWithAllocationAndSelect,
} from "./components/DeceasedAccountsCard/DeceasedAccounts.component";
import { OtherInheritors } from "./components/OtherInheritors/OtherInheritors.component";
import { DeceasedCardLoading } from "./components/CardLoading/DeceasedCardLoading.component";
import { useInheritanceAccountsWithAllocation } from "@src/hooks/useInheritanceAccountsWithAllocation.hook";
import { RequestMissingDocumentsModal } from "./components/RequestMissingDocumentsModal/RequestMissingDocumentsModal.component";
import { useDisclosure } from "@chakra-ui/react";

export function InheritorOverview() {
  const { isOpen: isModalOpen, onClose, onOpen: onOpenModal } = useDisclosure();
  const inheritorCardRef = useRef<InheritorCardRef>(null);

  const { currentInheritor, setCurrentInheritor, setCurrentDeceased } = useInheritorStore();
  const {
    inheritorWithDetails,
    isLoading: inheritorIsLoading,
    mutate: inheritorDetailsMutate,
  } = useInheritorDetails(currentInheritor?.id ?? "");
  const {
    inheritanceAccountsWithAllocation,
    isLoading: accountsAreLoading,
    mutate: accountsMutate,
  } = useInheritanceAccountsWithAllocation(inheritorWithDetails?.deceasedPersons.at(0)?.caseId);

  useEffect(() => {
    setCurrentInheritor(inheritorWithDetails as unknown as InheritorWithoutSsn);

    setCurrentDeceased(
      inheritorWithDetails?.deceasedPersons[0] as unknown as DeceasedPersonWithCaseId
    );
  }, [inheritorWithDetails, inheritorIsLoading, setCurrentDeceased, setCurrentInheritor]);

  function onCloseModal() {
    onClose();
    if (inheritorCardRef && inheritorCardRef.current) {
      inheritorCardRef.current.cancelEditing();
    }
  }

  async function onSaveModal() {
    if (inheritorCardRef && inheritorCardRef.current) {
      onClose();
      await inheritorCardRef.current.submitForm();
    }
  }

  return (
    <>
      <InheritorCard
        ref={inheritorCardRef}
        inheritorWithDetails={inheritorWithDetails}
        isLoading={inheritorIsLoading}
        mutate={inheritorDetailsMutate}
        openMissingDocumentsModal={onOpenModal}
      />
      {inheritorIsLoading ? (
        <DeceasedCardLoading headingLabel="Deceased" withEditButton />
      ) : (
        inheritorWithDetails?.deceasedPersons?.map(deceasedPerson => {
          const deceasedDetails = {
            id: deceasedPerson.id,
            firstName: deceasedPerson.firstName,
            middleName: deceasedPerson?.middleName,
            lastName: deceasedPerson?.lastName,
            totalAssets: inheritorWithDetails?.totalAssets,
            latestDeathCertificate: deceasedPerson.latestDeathCertificate,
            dateOfBirth: deceasedPerson.dateOfBirth,
          };

          return (
            <DeceasedCard
              key={deceasedPerson.id}
              deceasedDetails={deceasedDetails as unknown as GetDeceasedPersonDetailsResponse}
              isLoading={inheritorIsLoading}
              mutate={inheritorDetailsMutate}
            />
          );
        })
      )}
      <DeceasedAccountsCard
        financialAccounts={
          inheritanceAccountsWithAllocation as FinancialAccountWithAllocationAndSelect[]
        }
        isLoading={accountsAreLoading}
        mutate={accountsMutate}
      />
      <OtherInheritors
        isLoading={inheritorIsLoading}
        otherInheritors={inheritorWithDetails?.deceasedPersons.at(0)?.otherInheritors}
      />

      <RequestMissingDocumentsModal
        isOpen={isModalOpen}
        onClose={onCloseModal}
        onSave={onSaveModal}
      />
    </>
  );
}
