import { HStack, Heading, Skeleton, Text, VStack, GridItem, Divider } from "@chakra-ui/react";
import { MdOutlineEditNote } from "react-icons/md";
import { GridWrapper } from "@components/Layout/Grid/GridWrapper/GridWrapper.component";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { Button } from "@shared/components/Button/Button.component";

type CardLoadingProps = {
  headingLabel: string;
  withEditButton?: boolean;
};

export function DeceasedCardLoading({ headingLabel, withEditButton }: Readonly<CardLoadingProps>) {
  const isMobile = useIsMobile();

  return (
    <VStack w="full" gap={3} mb={9}>
      <HStack w="full" justifyContent="space-between">
        <Heading variant="h1" fontSize="24px" color="gray.700" lineHeight="36px" fontWeight={600}>
          {headingLabel}
        </Heading>
        {withEditButton ? (
          <Button
            variant="whiteLabelOutline"
            leftIcon={<MdOutlineEditNote />}
            _loading={{
              "&:hover": {
                backgroundColor: "whiteLabel.primary",
              },
            }}
            isLoading={true}
          >
            Edit
          </Button>
        ) : null}
      </HStack>

      <GridWrapper
        as="div"
        w="full"
        templateRows="auto auto"
        templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(4, 1fr)"}
        gap={isMobile ? 2 : 0}
      >
        <GridItem colSpan={isMobile ? 4 : 1} padding="14px 24px 0">
          <VStack alignItems="flex-start" gap={2}>
            <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
              FIRST NAME
            </Text>
            <Skeleton {...{ startColor: "#c0eeee", endColor: "#79d5d5" }} w="full" height="32px" />
          </VStack>
        </GridItem>
        <GridItem colSpan={isMobile ? 4 : 1} padding="14px 24px 0">
          <VStack alignItems="flex-start" gap={2}>
            <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
              MIDDLE NAME
            </Text>
            <Skeleton {...{ startColor: "#c0eeee", endColor: "#79d5d5" }} w="full" height="32px" />
          </VStack>
        </GridItem>
        <GridItem colSpan={isMobile ? 4 : 1} padding="14px 24px 0">
          <VStack alignItems="flex-start" gap={2}>
            <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
              LAST NAME
            </Text>
            <Skeleton {...{ startColor: "#c0eeee", endColor: "#79d5d5" }} w="full" height="32px" />
          </VStack>
        </GridItem>
        <GridItem colSpan={isMobile ? 4 : 1} padding="14px 24px 0">
          <VStack alignItems="flex-start">
            <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
              ACCOUNT BALANCE
            </Text>
            <Skeleton {...{ startColor: "#c0eeee", endColor: "#79d5d5" }} w="full" height="32px" />
          </VStack>
        </GridItem>

        <GridItem colSpan={4}>
          <Divider my={4} />
        </GridItem>

        <GridItem colSpan={isMobile ? 4 : 1} padding="8px 24px 0">
          <VStack alignItems="flex-start" gap={2}>
            <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
              DEATH CERTIFICATE
            </Text>
            <Skeleton {...{ startColor: "#c0eeee", endColor: "#79d5d5" }} w="full" height="32px" />
          </VStack>
        </GridItem>
        <GridItem colSpan={isMobile ? 4 : 1} padding="8px 24px 0">
          <VStack alignItems="flex-start" gap={2}>
            <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
              SSN
            </Text>
            <Skeleton {...{ startColor: "#c0eeee", endColor: "#79d5d5" }} w="full" height="32px" />
          </VStack>
        </GridItem>
        <GridItem colSpan={isMobile ? 4 : 1} padding="8px 24px 16px">
          <VStack alignItems="flex-start" gap={2}>
            <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
              DATE OF BIRTH
            </Text>
            <Skeleton {...{ startColor: "#c0eeee", endColor: "#79d5d5" }} w="full" height="32px" />
          </VStack>
        </GridItem>
      </GridWrapper>
    </VStack>
  );
}
