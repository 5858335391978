import { ReactNode } from "react";
import { Card, CardBody, CardProps, Stack, StackDivider, StackProps } from "@chakra-ui/react";
import { Loading } from "@shared/components/Loading/Loading.component";

type CardWrapperProps = {
  children: ReactNode;
  isLoading?: boolean;
  stackProps?: StackProps;
} & CardProps;

export function CardWrapper({ children, isLoading, stackProps, ...rest }: CardWrapperProps) {
  return (
    <Card {...rest} width="100%" borderRadius="xl">
      <CardBody p="10px" position="relative">
        {isLoading ? (
          <Loading
            position="absolute"
            top="0"
            left="0"
            height="100%"
            width="100%"
            backgroundColor="#c4c4c436"
            zIndex={9}
            borderRadius="xl"
          />
        ) : null}
        <Stack divider={<StackDivider />} spacing={6} {...stackProps}>
          {children}
        </Stack>
      </CardBody>
    </Card>
  );
}
