import { captureException } from "@sentry/react";
import { GetTokenSilentlyOptions } from "@auth0/auth0-react";
import { getCookie, getExpirationDateFromToken, setCookie } from "@shared/utils/storage.utils";

type GetAccessTokenSilently = (options?: GetTokenSilentlyOptions) => Promise<string> | null;

let internalGetAccessTokenSilently: GetAccessTokenSilently = () => null;
let internalLogout = () => {};

export const getAccessToken = async () => {
  try {
    // get accessToken from cookies
    const cookieName = "3dj89ql0"; // random typed string for concealment
    let accessToken = getCookie(cookieName);

    if (accessToken) {
      accessToken = atob(accessToken);

      const expiresIn = getExpirationDateFromToken(accessToken);

      if (expiresIn > new Date()) {
        return accessToken;
      }

      accessToken = null;
    }

    if (!accessToken) {
      accessToken = await internalGetAccessTokenSilently();

      // get expiration time
      const expiresIn = getExpirationDateFromToken(accessToken);

      // encode accessToken
      const encodedAccessToken = btoa(accessToken ?? "");

      // set accessToken cookie
      setCookie(cookieName, encodedAccessToken, {
        expires: expiresIn,
      });
    }

    return accessToken;
  } catch (error) {
    captureException(error);

    return internalLogout();
  }
};

export const AuthenticationModule = {
  getAccessTokenSilently: () => {
    return getAccessToken();
  },
  logout: () => {
    internalLogout();
  },
  setAccessTokenSilently: (getAccessTokenSilently: GetAccessTokenSilently) => {
    internalGetAccessTokenSilently = getAccessTokenSilently;
  },
  setLogout: (logout: () => void) => {
    internalLogout = logout;
  },
};
