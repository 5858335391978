import { ReactNode, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useAuth0, User } from "@auth0/auth0-react";
import { Flex } from "@chakra-ui/react";
import { AgentRoles } from "@trustribbon/ribbon-ec-types";
import { usePermission } from "@hooks/usePermission.hook";
import { sidebarRoutes } from "@routes/admin.routes";
import { ROUTES } from "@config/constants.config";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { Loading } from "@shared/components/Loading/Loading.component";
import { UnauthorizedTemplate } from "@templates/Unauthorized/Unauthorized.template";
import { Sidebar } from "@components/Layout/Sidebar/Sidebar.component";
import { LogoutMenu } from "@components/LogoutMenu/LogoutMenu.component";
import { Logo } from "@components/Logo/Logo.component";
import { Header } from "../Header/Header.component";
import { SessionTimeout } from "../SessionTimeout/SessionTimeout.component";

type PrivateLayoutProps = {
  children: ReactNode;
  viewPermissions: AgentRoles;
  title?: string;
};

export function PrivateLayout({ children, title, viewPermissions }: Readonly<PrivateLayoutProps>) {
  const {
    isAuthenticated,
    isLoading,
    user,
  }: { isAuthenticated: boolean; isLoading: boolean; user?: User } = useAuth0();
  const { navigate, currentPathname } = useNavigation();
  const { isPermitted, isLoading: isRolesLoading } = usePermission();

  useEffect(() => {
    const onRedirectCallback = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const error = urlParams.get("error");

      if (error) {
        const errorDesc = urlParams.get("error_description");
        console.error(`Authentication error: ${errorDesc}`);
      }
    };

    onRedirectCallback();
  }, []);

  useEffect(() => {
    const shouldRedirect =
      !isLoading &&
      !isAuthenticated &&
      (currentPathname !== ROUTES.SIGNIN || currentPathname === ROUTES.ROOT);

    if (shouldRedirect) {
      navigate(ROUTES.SIGNIN);
    }
  }, [currentPathname, isAuthenticated, isLoading, navigate]);

  const isInternalLoading = isRolesLoading || isLoading;

  if (isInternalLoading || !isAuthenticated) {
    return <Loading />;
  }

  const hasPermission = isPermitted(viewPermissions);

  return (
    <Flex height="100%">
      <SessionTimeout />
      {title && (
        <Helmet>
          <title>{`${title}`}</title>
        </Helmet>
      )}
      <Sidebar
        LogoComponent={Logo}
        sidebarRoutes={sidebarRoutes}
        FooterItemElementComponent={LogoutMenu}
        sidebarHeaderText={`Hi, ${user?.user_metadata.first_name || user?.name}`}
      />
      <Flex position="relative" as="main" direction="column" flex="1" w="full">
        <Header />
        {hasPermission ? children : <UnauthorizedTemplate />}
      </Flex>
    </Flex>
  );
}
