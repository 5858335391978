import { ReactNode } from "react";
import { Button as ChakraButton, ButtonProps } from "@chakra-ui/react";

type GenericButtonProps = {
  children: ReactNode;
  isLoading?: boolean;
  CustomLoadingComponent?: ReactNode;
} & ButtonProps;

export function Button({
  children,
  onClick,
  isLoading,
  CustomLoadingComponent,
  ...rest
}: GenericButtonProps) {
  if (CustomLoadingComponent && isLoading) {
    return CustomLoadingComponent;
  }

  return (
    <ChakraButton
      colorScheme="whiteLabel"
      variant="whiteLabel"
      onClick={onClick}
      isLoading={isLoading}
      {...rest}
    >
      {children}
    </ChakraButton>
  );
}
