import { useCallback, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { Flex, HStack, Heading, Stack, StackDivider, Text, VStack } from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";
import {
  InheritanceCaseStatus,
  ListInheritanceAccountsWithAllocationResponse,
} from "@trustribbon/ribbon-ec-types";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import {
  cleanNumberString,
  cleanPercentageString,
  formatNumberToCurrency,
  formatNumberToPercentage,
} from "@shared/utils/string.utils";
import { ConfirmationModal, useConfirmationModal } from "@shared/hooks/useConfirmationModal.hook";
import { getValuesFromDirtyFieldsIndexes } from "@shared/utils/form.utils";
import { Button } from "@shared/components/Button/Button.component";
import { ACCOUNT_TYPES_SELECT_OPTIONS } from "@shared/config/constants.config";
import { useInheritorStore } from "@store/inheritor.store";
import { useInstitutionStore } from "@store/institution.store";
import {
  deleteFinancialAccount,
  patchFinancialAccount,
  postFinancialAccount,
} from "@services/inheritor.service";
import { CardWrapper } from "@components/Layout/Card/Card.component";
import { DeceasedAccountLoading } from "../CardLoading/DeceasedAccountLoading.component";
import { EditButtons } from "../EditButtons/EditButtons.component";
import { DeceasedAccount } from "./DeceasedAccount.component";
import { AddAccountButtons } from "../EditButtons/AddAccountButtons.component";
import { useNavigation } from "@shared/hooks/useNavigation.hook";

export type FinancialAccountWithAllocationAndSelect =
  ListInheritanceAccountsWithAllocationResponse[number] & {
    selected: boolean;
  };

type DeceasedAccountsCardProps = {
  isLoading: boolean;
  mutate: () => void;
  financialAccounts?: FinancialAccountWithAllocationAndSelect[];
};

interface DeceasedAccountsHeaderProps {
  isLoading?: boolean;
  status?: InheritanceCaseStatus;
}

const DeceasedAccountsHeader = ({
  isLoading = false,
  status = undefined,
}: DeceasedAccountsHeaderProps) => {
  const { location } = useNavigation();
  const isMobile = useIsMobile();
  const shouldDisplayAllocation = !location.pathname.includes("deceased-view");
  const shouldShowDistributionRequest =
    shouldDisplayAllocation &&
    (status === "confirmed" ||
      status === ("withdrawal_requested" as unknown as InheritanceCaseStatus)) &&
    !isLoading;

  const headers = [
    { title: "ACCOUNT NUMBER", shouldDisplay: true },
    { title: "ACCOUNT TYPE", shouldDisplay: true },
    { title: "AMOUNT", shouldDisplay: true },
    { title: "INHERITOR'S ALLOCATION", shouldDisplay: shouldDisplayAllocation },
    { title: "INHERITOR'S DISTRIBUTION METHOD", shouldDisplay: shouldShowDistributionRequest },
  ];

  const renderedHeaders = headers.filter(header => header.shouldDisplay).length;

  return (
    <>
      {headers.map(
        header =>
          header.shouldDisplay && (
            <VStack
              key={header.title}
              alignItems="flex-start"
              gap={2}
              justifyContent="space-evenly"
              width={isMobile ? "100%" : 1 / renderedHeaders}
            >
              <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                {header.title}
              </Text>
            </VStack>
          )
      )}
    </>
  );
};

export function DeceasedAccountsCard({
  financialAccounts,
  isLoading,
  mutate,
}: Readonly<DeceasedAccountsCardProps>) {
  const { currentInheritor, currentDeceased } = useInheritorStore();
  const { currentInstitution } = useInstitutionStore();
  const isMobile = useIsMobile();
  const [internalLoading, setInternalLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [newAccounts, setNewAccounts] = useState<FinancialAccountWithAllocationAndSelect[]>([]);

  const defaultValues = {
    financialAccounts,
    newAccounts,
  };

  const formMethods = useForm<typeof defaultValues>({
    defaultValues,
  });

  const { handleSubmit, control, formState, reset, setValue } = formMethods;
  const isDirty = formState.isDirty;
  const dirtyFields = formState.dirtyFields;

  const financialAccountsValue = useWatch({
    control,
    name: "financialAccounts",
  });

  const selectedValues = financialAccountsValue?.filter(
    account => (account as FinancialAccountWithAllocationAndSelect & { selected: boolean }).selected
  );

  const newAccountsValue = useWatch({
    control,
    name: "newAccounts",
  });

  const newAccountsValueDirty = formState.dirtyFields.newAccounts;

  const [modalOptions, openModal, closeModal, setIsLoading] = useConfirmationModal();

  const handleResetForm = useCallback(() => {
    reset({
      financialAccounts: financialAccounts?.map(account => {
        return {
          ...account,
          selected: false,
          amount: account.amount ? formatNumberToCurrency(+account.amount) : "",
          percentage: account.percentage ? formatNumberToPercentage(+account.percentage) : "",
          accountNumber: account.accountNumber ?? "",
        };
      }),
    });
  }, [financialAccounts, reset]);

  useEffect(() => {
    handleResetForm();
  }, [financialAccounts, handleResetForm]);

  const handleEdit = () => {
    return setIsEditing(true);
  };

  const handleCancelEditAccounts = () => {
    setIsEditing(false);
    handleResetForm();
  };

  const handleDelete = () => {
    if (selectedValues) {
      setIsLoading(true);

      deleteFinancialAccount("/financial-accounts", {
        financialAccountIds: selectedValues
          .map(account => {
            if (account.selected) {
              return account.id;
            }

            return null;
          })
          .filter(Boolean) as string[],
      })
        .then(() => mutate())
        .then(() => closeModal())
        .finally(() => {
          setIsLoading(false);
          setIsEditing(false);
        });
    }
  };

  const handleAddAccount = () => {
    setIsCreating(true);

    return setNewAccounts([
      ...newAccounts,
      {
        id: "",
        selected: true,
        accountNumber: "",
        accountType: "",
        amount: "",
        percentage: "",
        distributionMethod: "pending",
      },
    ]);
  };

  const handleCancelNewAccounts = () => {
    setIsCreating(false);
    setNewAccounts([]);
    setValue("newAccounts", []);
  };

  const handleSaveNewAccounts = async () => {
    if (isCreating && newAccountsValue.length > 0) {
      setIsCreating(false);
      setInternalLoading(true);

      await postFinancialAccount("/financial-accounts", {
        financialAccounts: newAccountsValue.map(account => {
          return {
            accountNumber: account.accountNumber ?? "",
            accountType: account.accountType ?? "",
            amount: cleanNumberString(account.amount ?? ""),
            inheritorId: currentInheritor?.id ?? "",
            deceasedPersonId: currentDeceased?.id ?? "",
            institutionId: currentInstitution?.id ?? 0,
          };
        }),
      })
        .then(() => {
          setNewAccounts([]);
          setValue("newAccounts", []);
        })
        .then(() => mutate());

      setInternalLoading(false);
    }
  };

  const onSubmit: SubmitHandler<typeof defaultValues> = async data => {
    setIsEditing(false);

    if (!currentInheritor || !currentDeceased || !currentInstitution) return;

    if (isDirty) {
      setInternalLoading(true);

      const onlyChangedData = getValuesFromDirtyFieldsIndexes(
        data.financialAccounts,
        dirtyFields.financialAccounts
      ) as FinancialAccountWithAllocationAndSelect[];

      await patchFinancialAccount("/financial-accounts", {
        financialAccounts: onlyChangedData.map(account => {
          return {
            id: account.id,
            accountNumber: account.accountNumber ?? "",
            accountType: account.accountType ?? "",
            amount: cleanNumberString(account.amount ?? ""),
            percentage: +cleanPercentageString(account.percentage),
            inheritorId: currentInheritor.id,
            deceasedPersonId: currentDeceased.id,
            institutionId: currentInstitution.id,
          };
        }),
      }).then(() => mutate());

      setInternalLoading(false);
    }
  };

  const isComponentLoading = internalLoading || isLoading;

  return (
    <FormProvider {...formMethods} control={control}>
      <ConfirmationModal
        {...modalOptions}
        modalProps={{
          closeOnEsc: false,
          closeOnOverlayClick: false,
        }}
        modalHeader={
          <Text fontSize={18} color="#2D3748" fontWeight={700} lineHeight="28px">
            Delete account
          </Text>
        }
        modalBody={
          <VStack alignItems="flex-start">
            <Text fontSize={14} color="#2D3748" fontWeight={400} lineHeight="20px" pb={5}>
              Are you sure that you want to delete this account? <br />
              <Text as="span" fontWeight={600}>
                You can't undo this action afterwards.
              </Text>
            </Text>

            {isEditing ? (
              <>
                <Stack
                  w="full"
                  justifyContent="space-between"
                  flexDirection={isMobile ? "column" : "row"}
                  borderBottom="1px solid"
                  borderColor="gray.300"
                  padding="8px 0"
                >
                  <DeceasedAccountsHeader
                    isLoading={isLoading}
                    status={currentDeceased?.caseStatus}
                  />
                  isLoading? = false{" "}
                </Stack>

                <Stack w="full" padding={0} divider={<StackDivider borderColor="gray.300" />}>
                  {selectedValues?.map(account => {
                    const accountType = ACCOUNT_TYPES_SELECT_OPTIONS.find(
                      currentType => currentType.value === account.accountType
                    );

                    return (
                      <Stack
                        key={account.id}
                        w="full"
                        justifyContent="space-between"
                        flexDirection={isMobile ? "column" : "row"}
                      >
                        <VStack
                          alignItems="flex-start"
                          gap={2}
                          width={isMobile ? "100%" : "40%"}
                          justifyContent="space-evenly"
                        >
                          <HStack w="100%">
                            <Text
                              aria-label="account number"
                              fontSize={14}
                              color="#171923"
                              fontWeight={500}
                              lineHeight="20px"
                              w="100%"
                            >
                              {account.accountNumber ?? ""}
                            </Text>
                          </HStack>
                        </VStack>

                        <VStack
                          alignItems="flex-start"
                          gap={2}
                          width={isMobile ? "100%" : "40%"}
                          justifyContent="space-evenly"
                        >
                          <Text
                            aria-label="account type"
                            fontSize={14}
                            color="#171923"
                            fontWeight={400}
                            lineHeight="20px"
                            w="100%"
                          >
                            {accountType?.label ?? ""}
                          </Text>
                        </VStack>

                        <VStack
                          alignItems="flex-start"
                          gap={2}
                          width={isMobile ? "100%" : "20%"}
                          justifyContent="space-evenly"
                        >
                          <Text
                            aria-label="account amount"
                            fontSize={16}
                            color="#2D3748"
                            fontWeight={700}
                            lineHeight="24px"
                            w="100%"
                          >
                            {account.amount ?? ""}
                          </Text>
                        </VStack>
                      </Stack>
                    );
                  })}
                </Stack>
              </>
            ) : null}
          </VStack>
        }
        modalConfirmButton="Delete"
      />
      <Flex as="form" id="deceased-accounts-form" w="full" gap={3} flexDirection="column" mb={9}>
        <VStack w="full" gap={7}>
          <HStack w="full" justifyContent="space-between" flexWrap="wrap">
            <Heading
              variant="h1"
              fontSize="24px"
              color="gray.700"
              lineHeight="36px"
              fontWeight={600}
            >
              Deceased accounts
            </Heading>
            <HStack>
              {!isCreating && (
                <EditButtons
                  defaultValues={defaultValues}
                  onDeleteButtonProps={{
                    width: isMobile ? "100%" : "auto",
                    isDisabled: !selectedValues || selectedValues.length === 0,
                  }}
                  onEditButtonProps={{
                    isDisabled: !financialAccountsValue || financialAccountsValue.length === 0,
                  }}
                  onDelete={() => {
                    if (selectedValues && selectedValues.length > 0) {
                      return openModal(handleDelete);
                    }
                  }}
                  onCancelButtonProps={{
                    width: isMobile ? "100%" : "auto",
                  }}
                  onAcceptButtonProps={{
                    width: isMobile ? "100%" : "auto",
                    isDisabled: !isDirty,
                  }}
                  onEdit={handleEdit}
                  onSave={handleSubmit(onSubmit)}
                  onCancel={handleCancelEditAccounts}
                  isEditing={isEditing}
                  isLoading={isComponentLoading}
                />
              )}
              {!isEditing && (
                <AddAccountButtons
                  onAdd={handleAddAccount}
                  onSave={handleSaveNewAccounts}
                  onCancel={handleCancelNewAccounts}
                  onCancelButtonProps={{
                    width: isMobile ? "100%" : "auto",
                  }}
                  onSaveButtonProps={{
                    width: isMobile ? "100%" : "auto",
                    isDisabled:
                      !newAccountsValue ||
                      newAccountsValue.length === 0 ||
                      newAccountsValueDirty?.length !== newAccountsValue?.length,
                  }}
                  onAddButtonProps={{
                    width: isMobile ? "100%" : "auto",
                    sx: {
                      "& .chakra-button__icon": {
                        marginRight: isCreating ? 0 : "0.5rem",
                      },
                    },
                  }}
                  isCreating={isCreating}
                  extraButton={
                    <Button
                      leftIcon={<MdAdd />}
                      variant="whiteLabel"
                      onClick={handleAddAccount}
                      width={isMobile ? "100%" : "auto"}
                    >
                      Add Account
                    </Button>
                  }
                  isLoading={isComponentLoading}
                />
              )}
            </HStack>
          </HStack>
        </VStack>

        <CardWrapper
          stackProps={{
            spacing: "10px",
          }}
        >
          <Stack
            w="full"
            justifyContent="space-between"
            gap={8}
            flexDirection={isMobile ? "column" : "row"}
            padding="14px 24px 0"
          >
            <DeceasedAccountsHeader isLoading={isLoading} status={currentDeceased?.caseStatus} />
            isLoading? = false{" "}
          </Stack>

          {!isCreating && !isComponentLoading && financialAccountsValue?.length === 0 && (
            <Text
              fontSize={14}
              color="#2D3748"
              fontWeight={400}
              lineHeight="20px"
              padding="14px 24px"
            >
              No accounts found.
            </Text>
          )}

          {isCreating &&
            newAccounts.map((newAccount, idx, newAccountsArray) => {
              const isLastNewAccountIndex = idx === newAccountsArray.length - 1;
              const existingAccountsLength = financialAccounts?.length ?? 0;
              const zIndexFromLastIndex = existingAccountsLength + newAccounts.length - idx;

              return (
                <Stack
                  key={`${newAccount.id}-${idx}`}
                  gap={8}
                  w="full"
                  justifyContent="space-between"
                  flexDirection={isMobile ? "column" : "row"}
                  padding={`8px 24px ${isLastNewAccountIndex ? "14px" : "4px"}`}
                >
                  <DeceasedAccount
                    idx={idx}
                    zIndex={zIndexFromLastIndex}
                    account={newAccount}
                    isEditing={isCreating}
                    isLoading={isComponentLoading}
                    caseStatus={currentDeceased?.caseStatus}
                    newAccount
                  />
                </Stack>
              );
            })}

          {!isComponentLoading && financialAccounts
            ? financialAccounts?.map((account, idx, financialAccountsArray) => {
                const isLastIndex = idx === financialAccountsArray.length - 1;

                const zIndexFromLastIndex = financialAccountsArray.length - idx;

                return (
                  <Stack
                    key={account.id}
                    gap={8}
                    w="full"
                    justifyContent="space-between"
                    flexDirection={isMobile ? "column" : "row"}
                    padding={`8px 24px ${isLastIndex ? "14px" : "4px"}`}
                  >
                    <DeceasedAccount
                      idx={idx}
                      zIndex={zIndexFromLastIndex}
                      account={account}
                      isEditing={isEditing}
                      isLoading={isComponentLoading}
                      caseStatus={currentDeceased?.caseStatus}
                    />
                  </Stack>
                );
              })
            : ([...Array(3).keys()] as number[]).map((number, idx, fakeNumberArray) => {
                const isLastIndex = idx === fakeNumberArray.length - 1;

                return (
                  <Stack
                    key={number}
                    gap={8}
                    w="full"
                    justifyContent="space-between"
                    flexDirection={isMobile ? "column" : "row"}
                    padding={`8px 24px ${isLastIndex ? "14px" : "4px"}`}
                  >
                    <DeceasedAccountLoading />
                  </Stack>
                );
              })}
        </CardWrapper>
      </Flex>
    </FormProvider>
  );
}
