import { CONSTANTS } from "@src/config/constants.config";
import { getAccessToken } from "../authenticate.service";
import { getMethod } from "../base.service";
import { captureException } from "@sentry/react";
import { ListInheritanceAccountsWithAllocationResponse } from "@trustribbon/ribbon-ec-types";

export async function listInheritanceFinancialAccount(
  endpoint: string
): Promise<ListInheritanceAccountsWithAllocationResponse> {
  try {
    const accessToken = await getAccessToken();

    return await getMethod<ListInheritanceAccountsWithAllocationResponse>(
      `${CONSTANTS.VITE_APP_ADMIN_API_URL}${endpoint}`,
      {
        headers: accessToken ? { Authorization: accessToken } : undefined,
      }
    );
  } catch (error) {
    captureException(error);
    throw error;
  }
}
