import {
  VStack,
  HStack,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  useDisclosure,
  StackProps,
} from "@chakra-ui/react";
import { MdOutlineMenu } from "react-icons/md";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { SidebarContent } from "./SidebarContent.component";

type SidebarItem = {
  path: string;
  title: string;
  icon: JSX.Element;
  children?: SidebarItem[];
};

type SidebarProps = {
  sidebarHeaderText: string;
  sidebarRoutes: SidebarItem[];
  LogoComponent?: () => JSX.Element;
  FooterItemElementComponent?: () => JSX.Element;
  vStackProps?: StackProps;
};

export function Sidebar({
  sidebarHeaderText,
  sidebarRoutes,
  LogoComponent,
  FooterItemElementComponent,
  vStackProps,
}: SidebarProps) {
  const isMobile = useIsMobile();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return isMobile ? (
    <Flex>
      <IconButton
        aria-label="Notification"
        icon={<MdOutlineMenu size={28} />}
        variant="whiteLabelGhost"
        color="#718096"
        colorScheme="teal"
        onClick={onOpen}
        position="absolute"
        top={6}
        left={6}
        zIndex={999}
      />
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{sidebarHeaderText}</DrawerHeader>
          <DrawerBody>
            <SidebarContent
              routes={sidebarRoutes}
              onItemClick={() => onClose()}
              FooterItemElementComponent={FooterItemElementComponent}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  ) : (
    <VStack
      as="aside"
      width="fit-content"
      minWidth="280px"
      height="100vh"
      position="sticky"
      top="0"
      p={6}
      boxShadow="lg"
      gap={12}
      backgroundColor="#fff"
      {...vStackProps}
    >
      {LogoComponent ? (
        <HStack width="100%" justifyContent="flex-start" px={4}>
          <LogoComponent />
        </HStack>
      ) : null}
      <SidebarContent
        routes={sidebarRoutes}
        onItemClick={() => onClose()}
        FooterItemElementComponent={FooterItemElementComponent}
      />
    </VStack>
  );
}
