import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

export interface CampaignStatDelta {
  value: string;
  isUpwardsTrend: boolean;
}

export type CampaignStatKey =
  | "targetedMembers"
  | "accountOpeningClicks"
  | "accountsOpened"
  | "accountsChurned"
  | "accountsRetained"
  | "inheritanceRetainedAmount"
  | "inheritanceRetainedPercentage"
  | "inheritanceChurnedAmount";

export interface CampaignStatData {
  key: CampaignStatKey;
  value: string;
  delta?: CampaignStatDelta;
}

export interface Campaign {
  id: number;
  title: string;
  status: string;
  members: string;
  minAmount: number | null;
  maxAmount: number | null;
  accountTypes: string[];
  startDate: Date;
  endDate: Date | null;
  stats?: CampaignStatData[];
}

interface CampaignStore {
  campaigns: Campaign[];
  campaign: Campaign | undefined;
  createCampaign: (campaign: Omit<Campaign, "id" | "stats">) => Promise<void>;
  getCampaign: (id: number) => Promise<void>;
}

const initialCampaigns: Campaign[] = [
  {
    id: 1,
    title: "Promotional offer: open an account and receive a $300 cash bonus.",
    status: "active",
    members: "all",
    minAmount: 2000,
    maxAmount: 100000,
    accountTypes: ["all"],
    startDate: new Date("05-01-2024"),
    endDate: new Date("07-04-2024"),
    stats: [
      { key: "targetedMembers", value: "18", delta: { value: "2", isUpwardsTrend: true } },
      { key: "accountOpeningClicks", value: "15", delta: { value: "1", isUpwardsTrend: false } },
      { key: "accountsOpened", value: "10", delta: { value: "3", isUpwardsTrend: true } },
      { key: "accountsChurned", value: "2", delta: { value: "1", isUpwardsTrend: false } },
      { key: "accountsRetained", value: "5", delta: { value: "1", isUpwardsTrend: true } },
      {
        key: "inheritanceRetainedAmount",
        value: "$1,560",
        delta: { value: "$120", isUpwardsTrend: true },
      },
      {
        key: "inheritanceRetainedPercentage",
        value: "84%",
        delta: { value: "56%", isUpwardsTrend: true },
      },
      {
        key: "inheritanceChurnedAmount",
        value: "$306",
        delta: { value: "$50", isUpwardsTrend: false },
      },
    ],
  },
  {
    id: 2,
    title: "Promotional offer: receive $250 for opening a savings account.",
    status: "draft",
    members: "no",
    minAmount: 0,
    maxAmount: 5000,
    accountTypes: ["savings"],
    startDate: new Date("10-05-2024"),
    endDate: new Date("11-06-2024"),
  },
  {
    id: 3,
    title: "Promotional offer: we'll double your savings account interest rate.",
    status: "canceled",
    members: "all",
    minAmount: 0,
    maxAmount: 500,
    accountTypes: ["savings"],
    startDate: new Date("02-20-2024"),
    endDate: new Date("03-20-2024"),
  },
  {
    id: 4,
    title: "Receive 4.5% APY on your checking account during 2 years.",
    status: "completed",
    members: "yes",
    minAmount: 0,
    maxAmount: 100000,
    accountTypes: ["checking"],
    startDate: new Date("01-01-2023"),
    endDate: new Date("12-31-2023"),
    stats: [
      { key: "targetedMembers", value: "120" },
      { key: "accountOpeningClicks", value: "83" },
      { key: "accountsOpened", value: "32" },
      { key: "accountsChurned", value: "5" },
      { key: "accountsRetained", value: "22" },
      { key: "inheritanceRetainedAmount", value: "$50,924" },
      { key: "inheritanceRetainedPercentage", value: "86%" },
      { key: "inheritanceChurnedAmount", value: "$8,132" },
    ],
  },
  {
    id: 5,
    title: "Receive 5.5% for your opened accounts during 1 year.",
    status: "completed",
    members: "all",
    minAmount: 100000,
    maxAmount: 1000000,
    accountTypes: ["all"],
    startDate: new Date("01-01-2023"),
    endDate: new Date("12-31-2023"),
    stats: [
      { key: "targetedMembers", value: "34" },
      { key: "accountOpeningClicks", value: "20" },
      { key: "accountsOpened", value: "12" },
      { key: "accountsChurned", value: "2" },
      { key: "accountsRetained", value: "10" },
      { key: "inheritanceRetainedAmount", value: "$44,730" },
      { key: "inheritanceRetainedPercentage", value: "90%" },
      { key: "inheritanceChurnedAmount", value: "$5,020" },
    ],
  },
];

export const useCampaignStore = create(
  devtools(
    persist<CampaignStore>(
      set => ({
        campaigns: initialCampaigns,
        campaign: undefined,
        createCampaign: async newCampaign =>
          set(({ campaigns }) => ({
            campaigns: [...campaigns, { ...newCampaign, id: campaigns.length + 1 }],
          })),
        getCampaign: async id =>
          set(({ campaigns }) => ({
            campaign: campaigns.find(campaign => campaign.id === id),
          })),
      }),
      {
        name: "campaignStore-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    {
      enabled: process.env.NODE_ENV === "development",
      anonymousActionType: "set campaigns",
      store: "campaignStore",
      name: "Campaigns",
    }
  )
);
