import { defineStyleConfig } from "@chakra-ui/react";

const Alert = defineStyleConfig({
  // Styles for the base style
  baseStyle: {
    container: {
      borderRadius: "lg",
    },
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {},
});

export const alertStyles = {
  components: {
    Alert,
  },
};
