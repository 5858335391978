import { ReactNode } from "react";
import { Text, TextProps, Skeleton } from "@chakra-ui/react";

type TextWithLoadingProps = {
  children: ReactNode;
  isLoading: boolean;
} & TextProps;

export function TextWithLoading({ children, isLoading, ...rest }: TextWithLoadingProps) {
  if (isLoading) {
    return <Skeleton {...{ startColor: "#c0eeee", endColor: "#79d5d5" }} w="full" height="32px" />;
  }

  return (
    <Text fontSize={12} fontWeight={400} lineHeight="16px" color="#2D3748" {...rest}>
      {children}
    </Text>
  );
}
