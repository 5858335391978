import { extendTheme, HTMLChakraProps, ThemingProps } from "@chakra-ui/react";
import { buttonStyles } from "@shared/theme/components/button";
import { textStyles } from "@shared/theme/components/text";
import { inputStyles } from "@shared/theme/components/input";
import { headingStyles } from "@shared/theme/components/headings";
import { cardStyles } from "@shared/theme/components/card";
import { globalStyles } from "@shared/theme/styles";
import { formStyles } from "@shared/theme/components/form";
import { alertStyles } from "@shared/theme/components/alert";

export const theme = extendTheme(
  globalStyles,
  buttonStyles,
  inputStyles,
  textStyles,
  cardStyles,
  headingStyles,
  formStyles,
  alertStyles
);

export interface CustomCardProps extends HTMLChakraProps<"div">, ThemingProps {}
