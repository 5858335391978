import { ReactNode, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Flex } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { ROUTES } from "@config/constants.config";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { Loading } from "@shared/components/Loading/Loading.component";

type PublicLayoutProps = {
  children: ReactNode;
  title?: string;
};

export function PublicLayout({ children, title }: Readonly<PublicLayoutProps>) {
  const { isLoading, isAuthenticated } = useAuth0();
  const { navigate, currentPathname } = useNavigation();

  useEffect(() => {
    if (isAuthenticated && currentPathname !== ROUTES.INHERITORS) {
      navigate(ROUTES.INHERITORS);
    }
  }, [currentPathname, isAuthenticated, navigate]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Flex height="100%">
      {title && (
        <Helmet>
          <title>{`${title}`}</title>
        </Helmet>
      )}
      <Flex as="main" direction="column" flex="1" height="100%" w="full" px={8} py={24}>
        {children}
      </Flex>
    </Flex>
  );
}
