import {
  Box,
  Checkbox,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UseModalProps,
  VStack,
} from "@chakra-ui/react";
import { Button } from "@shared/components/Button/Button.component";
import {
  CustomSelect,
  StatusSelectOption,
} from "@shared/components/FormComponents/CustomSelect.component";
import { UploadedDocument } from "@shared/components/UploadedDocument/UploadedDocument.component";
import {
  ACCOUNT_TYPES_SELECT_OPTIONS,
  YES_NO_ALL_SELECT_OPTIONS,
} from "@shared/config/constants.config";
import { Campaign, useCampaignStore } from "@src/store/campaign.store";
import { useInstitutionStore } from "@src/store/institution.store";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { MdRemove } from "react-icons/md";

export interface CreateCampaignModalProps {
  isOpen: boolean;
  onClose: UseModalProps["onClose"];
}

interface CreateCampaignFormValues {
  title: string;
  minInheritanceAmount: string;
  noMinAmount: boolean;
  maxInheritanceAmount: string;
  noMaxAmount: boolean;
  members: string;
  accountTypes: StatusSelectOption[];
  startDate: string;
  startNow: boolean;
  endDate: string;
  noEndDate: boolean;
}

export function CreateCampaignModalComponent({ isOpen, onClose }: CreateCampaignModalProps) {
  const { currentInstitution } = useInstitutionStore();
  const memberTerm =
    currentInstitution && currentInstitution.type === "bank" ? "Customers" : "Members";
  const defaultValues: CreateCampaignFormValues = {
    title: "",
    accountTypes: [],
    members: "",
    minInheritanceAmount: "",
    noMinAmount: false,
    maxInheritanceAmount: "",
    noMaxAmount: false,
    startDate: "",
    startNow: false,
    endDate: "",
    noEndDate: false,
  };
  const { createCampaign } = useCampaignStore();
  const formMethods = useForm<CreateCampaignFormValues>({
    defaultValues,
  });
  const { handleSubmit, register, watch, control } = formMethods;

  const onSubmit: SubmitHandler<CreateCampaignFormValues> = async data => {
    function getNumberFromCurrency(value: string) {
      return parseFloat(value.replace(/[^0-9.-]+/g, ""));
    }

    const newCampaign: Omit<Campaign, "id"> = {
      title: data.title,
      members: data.members,
      status: "active",
      startDate: data.startNow ? new Date() : new Date(data.startDate),
      endDate: data.noEndDate ? null : new Date(data.endDate),
      minAmount: data.noMinAmount ? null : getNumberFromCurrency(data.minInheritanceAmount),
      maxAmount: data.noMaxAmount ? null : getNumberFromCurrency(data.maxInheritanceAmount),
      accountTypes:
        data.accountTypes.length === ACCOUNT_TYPES_SELECT_OPTIONS.length
          ? ["all"]
          : data.accountTypes.map(option => option.value as string),
    };

    await createCampaign(newCampaign);
    formMethods.reset(defaultValues);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight={700}>Create a new campaign</ModalHeader>
        <ModalCloseButton />

        <ModalBody py="16px">
          <FormProvider {...formMethods} control={control}>
            <form id="create-campaign-form" onSubmit={handleSubmit(onSubmit)}>
              <VStack align="flex-start" gap="24px">
                {/* Campaign title */}
                <VStack align="flex-start" gap="6px" w="100%">
                  <Text color="gray.900" fontWeight="600">
                    Campaign title
                  </Text>
                  <Input
                    aria-label="Campaign title"
                    placeholder="Enter the title of the campaign"
                    outline="1px solid var(--chakra-color-gray-400)"
                    outlineOffset="0px"
                    bgColor="blackAlpha.50"
                    _placeholder={{ color: "gray.500" }}
                    fontSize="14px"
                    lineHeight="20px"
                    {...register("title", {})}
                  />
                </VStack>
                {/* Amount */}
                <VStack align="flex-start" gap="6px" w="100%">
                  <Text color="gray.900" fontWeight="600">
                    Amount inheriting
                  </Text>
                  <HStack
                    w="100%"
                    gap="8px"
                    align="flex-start"
                    divider={
                      <Box style={{ marginTop: "6px" }} color="gray.300" border="none">
                        <MdRemove size="24px" />
                      </Box>
                    }
                  >
                    <VStack gap="8px" align="flex-start">
                      <Input
                        aria-label="Min inheritance amount"
                        placeholder="$ min"
                        outline="1px solid var(--chakra-color-gray-400)"
                        outlineOffset="0px"
                        bgColor="blackAlpha.50"
                        _placeholder={{ color: "gray.500" }}
                        fontSize="14px"
                        lineHeight="20px"
                        disabled={watch("noMinAmount")}
                        {...register("minInheritanceAmount", {})}
                      />

                      <Checkbox
                        aria-label="No min inheritance amount"
                        colorScheme="teal"
                        size="sm"
                        {...register("noMinAmount")}
                      >
                        None
                      </Checkbox>
                    </VStack>

                    <VStack gap="8px" align="flex-start">
                      <Input
                        aria-label="Max inheritance amount"
                        placeholder="$ max"
                        outline="1px solid var(--chakra-color-gray-400)"
                        outlineOffset="0px"
                        bgColor="blackAlpha.50"
                        _placeholder={{ color: "gray.500" }}
                        fontSize="14px"
                        lineHeight="20px"
                        disabled={watch("noMaxAmount")}
                        {...register("maxInheritanceAmount", {})}
                      />

                      <Checkbox
                        aria-label="Unlimited inheritance amount"
                        colorScheme="teal"
                        size="sm"
                        {...register("noMaxAmount")}
                      >
                        Unlimited
                      </Checkbox>
                    </VStack>
                  </HStack>
                </VStack>
                {/* Members */}
                <VStack align="flex-start" gap="6px" w="100%">
                  <Text color="gray.900" fontWeight="600">
                    {memberTerm}
                  </Text>
                  <CustomSelect
                    aria-label={`${memberTerm} select`}
                    isEditing={true}
                    name="members"
                    isMulti={false}
                    placeholder="Choose targeted audience"
                    closeMenuOnSelect
                    options={YES_NO_ALL_SELECT_OPTIONS.map(option => ({
                      ...option,
                      value:
                        typeof option.value === "boolean" ? option.value.toString() : option.value,
                    }))}
                    // isLoading={isLoading}
                    inputGroupProps={{
                      bgColor: "blackAlpha.50",
                      outline: "1px solid var(--chakra-color-gray-400)",
                      outlineOffset: "0px",
                      fontSize: "14px",
                      lineHeight: "20px",
                      _placeholder: { color: "gray.500" },
                      sx: {
                        "& .react-select": {
                          height: "auto",
                        },
                      },
                    }}
                    chakraStyles={{
                      container: styles => ({
                        ...styles,
                        width: "100%",
                        borderRadius: "6px",
                      }),
                      placeholder: styles => ({
                        ...styles,
                        fontSize: "14px",
                        lineHeight: "20px",
                        margin: 0,
                        py: "9px",
                      }),
                      valueContainer: styles => ({
                        ...styles,
                        fontSize: "14px",
                        lineHeight: "20px",
                        py: 0,
                      }),
                      option: styles => ({
                        ...styles,
                        backgroundColor: "#F8F8F8",
                      }),
                      dropdownIndicator: provided => ({
                        ...provided,
                        px: 4,
                      }),
                    }}
                  />
                </VStack>
                {/* Account types */}
                <VStack align="flex-start" gap="6px" w="100%">
                  <Text color="gray.900" fontWeight="600">
                    Targeted account types
                  </Text>
                  <CustomSelect
                    aria-label="Account types select"
                    isEditing={true}
                    name="accountTypes"
                    isMulti={true}
                    placeholder="Choose targeted account types"
                    closeMenuOnSelect
                    options={ACCOUNT_TYPES_SELECT_OPTIONS}
                    // isLoading={isLoading}
                    inputGroupProps={{
                      bgColor: "blackAlpha.50",
                      outline: "1px solid var(--chakra-color-gray-400)",
                      outlineOffset: "0px",
                      fontSize: "14px",
                      lineHeight: "20px",
                      _placeholder: { color: "gray.500" },
                      sx: {
                        "& .react-select": {
                          height: "auto",
                          "& > div": {
                            minHeight: "",
                          },
                        },
                      },
                    }}
                    chakraStyles={{
                      container: styles => ({
                        ...styles,
                        width: "100%",
                        borderRadius: "6px",
                      }),
                      placeholder: styles => ({
                        ...styles,
                        fontSize: "14px",
                        lineHeight: "20px",
                        margin: 0,
                        py: "9px",
                      }),
                      valueContainer: styles => ({
                        ...styles,
                        fontSize: "14px",
                        lineHeight: "20px",
                        py: 0,
                      }),
                      option: styles => ({
                        ...styles,
                        backgroundColor: "#F8F8F8",
                      }),
                      dropdownIndicator: provided => ({
                        ...provided,
                        px: 4,
                      }),
                    }}
                  />
                </VStack>
                {/* Dates */}
                <VStack align="flex-start" gap="6px" w="100%">
                  <Text color="gray.900" fontWeight="600">
                    Date running
                  </Text>
                  <HStack
                    w="100%"
                    gap="8px"
                    align="flex-start"
                    divider={
                      <Box style={{ marginTop: "6px" }} color="gray.300" border="none">
                        <MdRemove size="24px" />
                      </Box>
                    }
                  >
                    <VStack gap="8px" align="flex-start" w="full">
                      <Input
                        type="date"
                        aria-label="Start date"
                        placeholder="Start date"
                        outline="1px solid var(--chakra-color-gray-400)"
                        outlineOffset="0px"
                        bgColor="blackAlpha.50"
                        _placeholder={{ color: "gray.500" }}
                        fontSize="14px"
                        lineHeight="20px"
                        disabled={watch("startNow")}
                        {...register("startDate", {})}
                      />

                      <Checkbox
                        aria-label="No min inheritance amount"
                        colorScheme="teal"
                        size="sm"
                        {...register("startNow")}
                      >
                        Start now
                      </Checkbox>
                    </VStack>

                    <VStack gap="8px" align="flex-start" w="full">
                      <Input
                        type="date"
                        aria-label="End date"
                        placeholder="End date"
                        outline="1px solid var(--chakra-color-gray-400)"
                        outlineOffset="0px"
                        bgColor="blackAlpha.50"
                        _placeholder={{ color: "gray.500" }}
                        fontSize="14px"
                        lineHeight="20px"
                        disabled={watch("noEndDate")}
                        {...register("endDate", {})}
                      />

                      <Checkbox
                        aria-label="Unlimited inheritance amount"
                        colorScheme="teal"
                        size="sm"
                        {...register("noEndDate")}
                      >
                        No end date
                      </Checkbox>
                    </VStack>
                  </HStack>
                </VStack>
                {/* Banners */}
                <VStack align="flex-start" gap="12px" w="100%">
                  <HStack justify="space-between" w="100%">
                    <VStack align="flex-start" gap={0}>
                      <Text color="gray.900" fontWeight="600">
                        Promotion offer banners
                      </Text>
                      <Text color="gray.500" fontSize="xs" lineHeight="16px">
                        Please add a jpg, jpeg, or png file.
                      </Text>
                    </VStack>

                    <Button
                      variant="whiteLabelOutline"
                      size="md"
                      fontSize="14px"
                      lineHeight="20px"
                      onClick={() => {}}
                    >
                      Upload banners
                    </Button>
                  </HStack>

                  <Text color="gray.500" fontSize="xs" lineHeight="16px">
                    Banner formats for the campaign <strong>900x100, 600x100, 335x230</strong>{" "}
                    pixels
                  </Text>

                  <HStack w="100%" justify="space-between" gap="8px">
                    {Array.from({ length: 0 /* not showing any banners on the demo */ }).map(
                      (_, index) => (
                        <UploadedDocument key={index} title={`Banner ${index + 1}`} />
                      )
                    )}
                  </HStack>
                </VStack>
              </VStack>
            </form>
          </FormProvider>
        </ModalBody>

        <ModalFooter display="flex" gap="16px">
          <Button
            w="100%"
            variant="outline"
            borderRadius="6px"
            _hover={{ bg: "whiteLabel.primary", color: "white" }}
            onClick={onClose}
            aria-label="Cancel"
          >
            Cancel
          </Button>
          <Button
            w="100%"
            variant="whiteLabel"
            type="submit"
            aria-label="Start the campaign"
            form="create-campaign-form"
          >
            Save campaign
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
