import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf.mjs";
// @ts-expect-error Description: This import is necessary to load the pdf worker module.
await import("pdfjs-dist/build/pdf.worker.min.mjs");

export async function compressImage(file: File): Promise<Blob> {
  // Files smaller than 500KB are not compressed because the compression may impact the quality of the image too much
  if (file.size < 500000) return file;

  return new Promise((resolve, reject) => {
    const img = new window.Image();
    img.src = URL.createObjectURL(file);

    img.onload = async () => {
      try {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (ctx === null) throw new Error("Canvas context not found");

        const maxWidth = 2400;
        const maxHeight = 1800;

        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        const compressedBlob = await compressCanvasToBlob(canvas);

        resolve(compressedBlob);
      } catch (error) {
        reject(error);
      }
    };

    img.onerror = (error: Event | string) => {
      reject(error);
    };
  });
}

export async function compressPdfImage(file: File) {
  const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file)).promise;
  const page = await pdf.getPage(1);

  const scale = 300 / 72;
  const viewport = page.getViewport({ scale });
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = "high";

  canvas.height = viewport.height;
  canvas.width = viewport.width;

  canvas.style.width = "100%";
  canvas.style.height = "100%";

  await page.render({ canvasContext: ctx, viewport: viewport }).promise;

  return await compressCanvasToBlob(canvas);
}

export async function compressCanvasToBlob(
  canvas: HTMLCanvasElement,
  quality: number | undefined = 0.7
) {
  return await new Promise<Blob>(resolve =>
    canvas.toBlob(resolve as BlobCallback, "image/jpeg", quality)
  );
}
