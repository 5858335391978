import { BaseSyntheticEvent, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Divider, Heading, Text, GridItem, Grid } from "@chakra-ui/react";
import { DocumentType } from "@trustribbon/ribbon-ec-types";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { EnhancedFile } from "@shared/types";
import { useDocumentStore } from "@shared/store/document.store";
import { ROUTES } from "@config/constants.config";
import { useAdminNotifyInfoStore } from "@store/adminNotifyInfo.store";
import { DeceasedReview } from "@shared/components/InformationReview/DeceasedReview.component";
import { InheritorReview } from "@shared/components/InformationReview/InheritorReview.component";
import { UploadedReview } from "@shared/components/InformationReview/UploadedReview.component";
import { AdminNotifyNavigationBlock } from "../components/AdminNotifyNavigation.component";

type InformationReviewFormProps = {
  onClickNext?: () => void;
  onClickBack?: () => void;
};

export function InformationReviewForm({
  onClickBack,
  onClickNext,
}: Readonly<InformationReviewFormProps>) {
  const [error, setError] = useState({ message: "" });
  const isMobile = useIsMobile();
  const [isLoading, setIsLoading] = useState(false);
  const { inheritorInfo, deceasedInfo, createInheritanceCase } = useAdminNotifyInfoStore();
  const { document } = useDocumentStore();
  const { navigate } = useNavigation();
  const { user } = useAuth0();

  const isDisabled =
    !document?.["death-certificates"] ||
    !document?.["government-ids"] ||
    !deceasedInfo ||
    !inheritorInfo;

  const documents = Object.entries(document ?? {}) as [DocumentType, EnhancedFile | null][];

  useEffect(() => {
    if (!deceasedInfo || !inheritorInfo) {
      navigate(ROUTES.INHERITORS_ADD);
    }
  }, [deceasedInfo, inheritorInfo, navigate]);

  const handleSubmitInfo = async (e: BaseSyntheticEvent) => {
    e.preventDefault();

    if (isDisabled) return null;

    setIsLoading(true);

    try {
      await createInheritanceCase(
        {
          inheritor: { ...inheritorInfo, middleName: inheritorInfo?.middleName ?? null },
          deceasedPerson: { ...deceasedInfo, middleName: deceasedInfo?.middleName ?? null },
        },
        documents,
        user?.org_metadata?.name
      );

      navigate(ROUTES.INHERITORS);
    } catch (error) {
      setError({ message: "An error occurred while submitting your information." });
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmitInfo} id="review-information">
      <Grid templateColumns="1fr" gap={12}>
        <GridItem>
          <Heading variant="h1" fontSize="24px" color="gray.700" lineHeight="40px" fontWeight={600}>
            Information review
          </Heading>
        </GridItem>
        <GridItem>
          <Text fontSize="20px" fontWeight={600} lineHeight="32px" pb={4}>
            Deceased personal information
          </Text>
          <DeceasedReview isMobile={isMobile} deceasedInfo={deceasedInfo} />
        </GridItem>
        <GridItem>
          <Text fontSize="20px" fontWeight={600} lineHeight="32px" pb={4}>
            Your personal information
          </Text>
          <InheritorReview isMobile={isMobile} inheritorInfo={inheritorInfo} />
        </GridItem>
        <GridItem>
          <Divider color="#CBD5E0" />
        </GridItem>
        <GridItem>
          <Text fontSize="20px" fontWeight={600} lineHeight="32px" pb={4}>
            Uploaded files
          </Text>
          <UploadedReview isDisabled={isDisabled} documentEntries={documents} />
        </GridItem>
        <GridItem>
          <Divider color="#CBD5E0" />
        </GridItem>
        {error?.message && (
          <GridItem>
            <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="red">
              {error.message || "An error occurred while submitting your information."}
            </Text>
          </GridItem>
        )}
        <GridItem>
          <AdminNotifyNavigationBlock
            onClickNext={onClickNext}
            onClickBack={onClickBack}
            isLoading={isLoading}
            isDisabled={isDisabled}
            onContinueLabel="Submit information"
            pt="16px"
            pb="32px"
          />
        </GridItem>
      </Grid>
    </form>
  );
}
