import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Table as TableType, flexRender } from "@tanstack/react-table";

type DataTableEmptyProps<Data extends object> = {
  table: TableType<Data>;
  emptyStateLabel?: string;
};

export function DataTableEmpty<Data extends object>({
  table,
  emptyStateLabel,
}: Readonly<DataTableEmptyProps<Data>>) {
  return (
    <TableContainer borderRadius="12px" border="1px solid #CBD5E0" backgroundColor="white" w="full">
      <Table variant="simple">
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <Th key={header.id} cursor="pointer" paddingTop={6} paddingBottom={3} px={8}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          <Tr>
            <Td paddingTop={4} paddingBottom={3} px={8}>
              {emptyStateLabel ?? "No data available"}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
}
