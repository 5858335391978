import { HStack, StackProps } from "@chakra-ui/react";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa6";
import { Button } from "@shared/components/Button/Button.component";

type AdminNotifyNavigationBlockProps = {
  onClickNext?: () => void;
  onClickBack?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  goBackLabel?: string;
  onContinueLabel?: string;
} & StackProps;

const buttonProps = {
  size: "lg",
  borderRadius: 6,
  border: "1px solid",
  borderColor: "whiteLabel.primary",
  padding: "16px 32px",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "24px",
  variant: "whiteLabel",
  _hover: {
    color: "white",
    bg: "whiteLabel.secondary",
    border: "1.9px solid",
    borderColor: "whiteLabel.secondary",
  },
};

export function AdminNotifyNavigationBlock({
  onClickNext,
  isDisabled,
  isLoading,
  goBackLabel,
  onContinueLabel,
  onClickBack,
  ...rest
}: AdminNotifyNavigationBlockProps) {
  return (
    <HStack spacing={3} align="center" justify="flex-start" flexWrap="wrap" {...rest}>
      {onClickBack ? (
        <Button
          {...buttonProps}
          onClick={onClickBack}
          backgroundColor="#fff"
          color="whiteLabel.primary"
          variant="whiteLabelOutline"
          isDisabled={isLoading}
          gap={2}
        >
          <FaArrowLeft size={16} /> {goBackLabel ?? "Back"}
        </Button>
      ) : null}
      <Button
        {...buttonProps}
        type={onClickNext ? "button" : "submit"}
        gap={2}
        isDisabled={isDisabled}
        isLoading={isLoading}
        onClick={onClickNext}
      >
        {onContinueLabel ?? "Continue"} <FaArrowRight size={16} />
      </Button>
    </HStack>
  );
}
