import { StatusSelectOption } from "@shared/components/FormComponents/CustomSelect.component";
import { AccountTransferStatus, FinancialAccounts } from "../types";
import { MdAssuredWorkload, MdHourglassEmpty } from "react-icons/md";
import { BiTransfer } from "react-icons/bi";
import { CiMoneyCheck1 } from "react-icons/ci";
import { IconType } from "react-icons";

export type TagWithIcon = StatusSelectOption & { icon: IconType };

export const STATUS_SELECT_OPTIONS: StatusSelectOption[] = [
  {
    value: "submitted",
    label: AccountTransferStatus.Submitted,
    textColor: "#744210",
    tagColor: "#FEEBCB",
  },
  {
    value: "needs_info",
    label: AccountTransferStatus.NeedsInfo,
    textColor: "#822727",
    tagColor: "#fed7d7",
  },
  {
    value: "under_review",
    label: AccountTransferStatus.UnderReview,
    textColor: "#2A4365",
    tagColor: "#BEE3F8",
  },
  {
    value: "confirmed",
    label: AccountTransferStatus.Confirmed,
    textColor: "#22543D",
    tagColor: "#C6F6D5",
  },
  {
    value: "transfer_completed",
    label: AccountTransferStatus.TransferCompleted,
    textColor: "#22543D",
    tagColor: "#C6F6D5",
  },
];

export const ACCOUNT_TYPES_SELECT_OPTIONS: StatusSelectOption[] = [
  {
    value: "checking",
    label: FinancialAccounts.Checking,
    textColor: "#22543D",
    tagColor: "#C6F6D5",
  },
  { value: "savings", label: FinancialAccounts.Savings, textColor: "#234E52", tagColor: "#B2F5EA" },
  {
    value: "traditional_ira",
    label: FinancialAccounts.TraditionalIra,
    textColor: "#2A4365",
    tagColor: "#BEE3F8",
  },
  {
    value: "roth_ira",
    label: FinancialAccounts.RothIra,
    textColor: "#086F83",
    tagColor: "#C4F1F9",
  },
  { value: "cd", label: FinancialAccounts.CD, textColor: "#44337A", tagColor: "#E9D8FD" },
];

export const YES_NO_SELECT_OPTIONS: StatusSelectOption[] = [
  {
    value: true,
    label: "YES",
    tagColor: "#C6F6D5",
    textColor: "#22543D",
  },
  {
    value: false,
    label: "NO",
    tagColor: "#FED7D7",
    textColor: "#822727",
  },
];

export const YES_NO_ALL_SELECT_OPTIONS: StatusSelectOption[] = [
  {
    value: "all",
    label: "All",
    tagColor: "blue.100",
    textColor: "blue.800",
  },
  ...YES_NO_SELECT_OPTIONS,
];

export const DISTRIBUTION_REQUEST_VIEW_OPTIONS: TagWithIcon[] = [
  {
    value: "open_account",
    label: "Opened account",
    tagColor: "rgba(56, 161, 105, 1)",
    textColor: "#ffffff",
    icon: MdAssuredWorkload,
  },
  {
    value: "transfer_money",
    label: "Transfer to another acc",
    tagColor: "rgba(128, 90, 213, 1)",
    textColor: "#ffffff",
    icon: BiTransfer,
  },
  {
    value: "requested_check",
    label: "Requested a check",
    tagColor: "rgba(128, 90, 213, 1)",
    textColor: "#ffffff",
    icon: CiMoneyCheck1,
  },
  {
    value: "pending",
    label: "Pending request",
    tagColor: "rgba(49, 130, 206, 1)",
    textColor: "#ffffff",
    icon: MdHourglassEmpty,
  },
];
