import { ReactNode, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "@shared/theme/theme";
import { useInstitutionData } from "@src/hooks/useInstitutionData.hook";
import { useInstitutionStore } from "@src/store/institution.store";

interface MainLayoutProps {
  children: ReactNode;
}

export function MainLayout({ children }: Readonly<MainLayoutProps>) {
  const { institution } = useInstitutionData();
  const { setCurrentInstitution } = useInstitutionStore();

  useEffect(() => {
    if (institution) setCurrentInstitution(institution);
  }, [institution, setCurrentInstitution]);

  /**
   * Sets the current theme based on the user's organization metadata:
   *
   * const { user, isAuthenticated } = useAuth0(); *
   * let currentTheme = theme;
   *
   * if (isAuthenticated && user?.org_metadata) {
   *   currentTheme = {
   *     ...currentTheme,
   *     colors: {
   *       ...currentTheme.colors,
   *       whiteLabel: {
   *         ...currentTheme.colors.brand,
   *         primary: user?.org_metadata?.primary_color || currentTheme.colors.brand[500],
   *         secondary: user?.org_metadata?.secondary_color || currentTheme.colors.brand[600],
   *       },
   *     },
   *     button: {
   *       [`${institution?.subdomain}Outline`]: {
   *         bg: "rgba(255, 205, 0, .2)",
   *         color: "black",
   *         borderColor: institution?.config?.primaryColor ?? globalStyles?.colors.whiteLabel.primary,
   *         borderRadius:
   *           institution?.config?.buttonBorderRadius ??
   *           globalStyles?.radii.whiteLabel.buttonBorderRadius,
   *       },
   *     },
   *   };
   * }
   */

  return (
    <ChakraProvider theme={theme}>
      <Helmet>
        <title>Estate center services</title>
        <meta property="og:title" content="Estate center services" />
        <meta property="og:site_name" content="https://ribbon.trustribbon.com" />
        <meta property="og:url" content="https://ribbon.trustribbon.com" />
      </Helmet>
      {children}
    </ChakraProvider>
  );
}
