import { useRef, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useAuth0 } from "@auth0/auth0-react";
import { ROUTES } from "@config/constants.config";
import { IdleDialog } from "./components/IdleDialog.component";

let countdownInterval: string | number | NodeJS.Timeout | undefined;
let timeout: string | number | NodeJS.Timeout | undefined;

export function SessionTimeout() {
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const countdownRef = useRef(10);

  const { isAuthenticated, logout } = useAuth0();

  const onActive = () => {
    if (!timeoutModalOpen) {
      clearSessionInterval();
      clearSessionTimeout();
    }
  };

  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  const handleLogout = () => {
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}${ROUTES.SIGNIN}`,
      },
    });
  };

  const handleContinue = () => {
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
  };

  const onIdle = () => {
    const delay = 1000 * 5 * 1;

    if (isAuthenticated && !timeoutModalOpen) {
      timeout = setTimeout(() => {
        setTimeoutModalOpen(true);

        countdownInterval = setInterval(() => {
          if (countdownRef.current > 0) {
            countdownRef.current = --countdownRef.current;
          } else {
            handleLogout();
          }
        }, 1000);
      }, delay);
    }
  };

  useIdleTimer({
    onIdle,
    onActive,
    timeout: process.env.NODE_ENV === "production" ? 1200000 : 999999999,
    debounce: 250,
  });

  return <IdleDialog onContinue={handleContinue} onLogout={handleLogout} open={timeoutModalOpen} />;
}
