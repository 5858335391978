import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { FormProvider, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { Divider, GridItem, HStack, Heading, Skeleton, Text, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { GetDeceasedPersonDetailsResponse } from "@trustribbon/ribbon-ec-types";
import { ROUTES, USER_ROLES } from "@config/constants.config";
import { deceasedCardSchema } from "@config/schema.validator";
import { patchDeceasedPerson } from "@services/inheritor.service";
import { useInheritorStore } from "@store/inheritor.store";
import { usePermission } from "@hooks/usePermission.hook";
import { Button } from "@shared/components/Button/Button.component";
import { EditableInput } from "@shared/components/FormComponents/EditableInput.component";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { dateOfBirthMask } from "@shared/config/masks.config";
import { useAlert } from "@shared/hooks/useAlert.hook";
import { formatNumberToCurrency } from "@shared/utils/string.utils";
import { TextWithLoading } from "@shared/components/TextWithLoading/TextWithLoading.component";
import { GridWrapper } from "@components/Layout/Grid/GridWrapper/GridWrapper.component";
import { EditButtons } from "../EditButtons/EditButtons.component";
import { UploadedDocumentsList } from "../UploadedDocumentList/UploadedDocumentList.component";

type DeceasedCardProps = {
  isLoading: boolean;
  mutate: () => void;
  deceasedDetails?: GetDeceasedPersonDetailsResponse;
};

const getDefaultValues = (deceasedDetails?: GetDeceasedPersonDetailsResponse) => {
  const rawTotalAssets = +(deceasedDetails?.totalAssets ?? "0");

  return {
    totalAssets: formatNumberToCurrency(rawTotalAssets),
    firstName: deceasedDetails?.firstName ?? "",
    middleName: deceasedDetails?.middleName ?? "",
    lastName: deceasedDetails?.lastName ?? "",
    dateOfBirth: deceasedDetails?.dateOfBirth
      ? dayjs(deceasedDetails?.dateOfBirth).format("MM/DD/YYYY")
      : "",
  };
};

const documents = [
  // { title: "Certificate of Trust", subtitle: "certificate_of_trust.pdf" },
  // { title: "Will", subtitle: "will_copy(2).pdf" },
  // { title: "Small Estate Affidavit", subtitle: "affidavit.pdf", archived: true },
  { title: "Death Certificate", subtitle: "abraham_kian_death_certificate.jpg" },
];

export function DeceasedCard({ isLoading, mutate, deceasedDetails }: Readonly<DeceasedCardProps>) {
  const { setIsInheritor } = useInheritorStore();
  const isMobile = useIsMobile();
  const { navigate } = useNavigation();
  const [isEditing, setIsEditing] = useState(false);
  const [internalLoading, setInternalLoading] = useState(false);
  const { setShowAlert, AlertComponent } = useAlert();
  const { isPermitted } = usePermission();

  const hasPermission = isPermitted([USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_EDITOR]);

  const defaultValues = getDefaultValues(deceasedDetails);

  const formMethods = useForm<typeof defaultValues>({
    defaultValues,
    resolver: yupResolver(deceasedCardSchema),
  });

  const { handleSubmit, control, reset, formState } = formMethods;
  const isDirty = formState.isDirty;

  useEffect(() => {
    const rawTotalAssets = +(deceasedDetails?.totalAssets ?? "0");

    reset(
      {
        totalAssets: formatNumberToCurrency(rawTotalAssets),
        firstName: deceasedDetails?.firstName,
        middleName: deceasedDetails?.middleName ?? "",
        lastName: deceasedDetails?.lastName,
        dateOfBirth: deceasedDetails?.dateOfBirth
          ? dayjs(deceasedDetails?.dateOfBirth).format("MM/DD/YYYY")
          : "11/12/1947",
      },
      { keepValues: false, keepDefaultValues: false }
    );
  }, [deceasedDetails, reset]);

  const totalAssetsValue = useWatch({ name: "totalAssets", control });

  const handleEditing = () => {
    setIsEditing(!isEditing);
  };

  const onSubmit: SubmitHandler<typeof defaultValues> = async data => {
    setIsEditing(false);

    if (isDirty) {
      setInternalLoading(true);

      try {
        await patchDeceasedPerson(`/deceased-persons/${deceasedDetails?.id}`, {
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          dateOfBirth: data.dateOfBirth,
        });
      } catch {
        setShowAlert({
          title: "An error occurred while submitting your information.",
          description: "Please try again later.",
          status: "error",
        });
      }

      setInternalLoading(false);

      mutate();
    }
  };

  const isComponentLoading = internalLoading || isLoading;

  return (
    <FormProvider {...formMethods} control={control}>
      <AlertComponent />
      <HStack w="full" justifyContent="space-between" flexWrap="wrap">
        <Heading variant="h1" fontSize="24px" color="gray.700" lineHeight="36px" fontWeight={600}>
          Deceased
        </Heading>
        <HStack>
          {/* <Button variant="whiteLabelOutline" leftIcon={<MdAdd size="16px" />}>
            Upload document
          </Button> */}

          <EditButtons
            defaultValues={defaultValues}
            onEdit={handleEditing}
            onSave={handleSubmit(onSubmit)}
            onCancel={() => setIsEditing(false)}
            isEditing={isEditing}
            isLoading={isComponentLoading}
          />
        </HStack>
      </HStack>

      <GridWrapper
        as="form"
        id="deceased-form"
        w="full"
        templateRows="auto auto"
        templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(4, 1fr)"}
        gap={isMobile ? 2 : 0}
        mb={9}
      >
        <GridItem colSpan={isMobile ? 4 : 1} padding="14px 24px 0">
          <VStack alignItems="flex-start" gap={2}>
            <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
              FIRST NAME
            </Text>
            <EditableInput
              aria-label="Deceased First Name"
              name="firstName"
              fontSize={18}
              fontWeight={700}
              lineHeight="28px"
              color="#2D3748"
              w="full"
              isEditing={isEditing}
              isLoading={isComponentLoading}
              registerOptions={{ required: "Deceased First Name is required." }}
            />
          </VStack>
        </GridItem>
        <GridItem colSpan={isMobile ? 4 : 1} padding="14px 24px 0">
          <VStack alignItems="flex-start" gap={2}>
            <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
              MIDDLE NAME
            </Text>
            <EditableInput
              aria-label="Deceased Middle Name"
              name="middleName"
              fontSize={18}
              fontWeight={700}
              lineHeight="28px"
              color="#2D3748"
              w="full"
              isEditing={isEditing}
              isLoading={isComponentLoading}
              registerOptions={{ required: false }}
            />
          </VStack>
        </GridItem>
        <GridItem colSpan={isMobile ? 4 : 1} padding="14px 24px 0">
          <VStack alignItems="flex-start" gap={2}>
            <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
              LAST NAME
            </Text>
            <EditableInput
              aria-label="Deceased Last Name"
              name="lastName"
              fontSize={18}
              fontWeight={700}
              lineHeight="28px"
              color="#2D3748"
              w="full"
              isEditing={isEditing}
              isLoading={isComponentLoading}
              registerOptions={{ required: "Deceased Last Name is required." }}
            />
          </VStack>
        </GridItem>
        <GridItem colSpan={isMobile ? 4 : 1} padding="14px 24px 0">
          <VStack alignItems="flex-start">
            <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
              ACCOUNT BALANCE
            </Text>
            <TextWithLoading
              fontSize={18}
              fontWeight={700}
              lineHeight="28px"
              color="#2D3748"
              isLoading={isComponentLoading}
            >
              {totalAssetsValue}
            </TextWithLoading>
          </VStack>
        </GridItem>

        <GridItem colSpan={4}>
          <Divider my={4} />
        </GridItem>

        <GridItem colSpan={isMobile ? 4 : 1} padding="8px 24px 0">
          <VStack alignItems="flex-start" gap={2}>
            <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
              SSN
            </Text>
            <Button
              textAlign="left"
              px={0}
              variant="whiteLabelLink"
              fontSize={14}
              color="#319795"
              fontWeight={700}
              lineHeight="20px"
              textDecoration="underline"
              isLoading={isComponentLoading}
              isDisabled={!hasPermission}
              CustomLoadingComponent={
                <Skeleton
                  {...{ startColor: "#c0eeee", endColor: "#79d5d5" }}
                  w="full"
                  height="32px"
                />
              }
              onClick={() => {
                setIsInheritor(false);
                navigate(
                  `${ROUTES.INHERITORS_DECEASED_SSN_PREVIEW.replace(":id", deceasedDetails?.id ?? "")}`
                );
              }}
            >
              View SSN
            </Button>
          </VStack>
        </GridItem>
        <GridItem colSpan={isMobile ? 4 : 2} padding="8px 24px 16px">
          <VStack alignItems="flex-start" gap={2}>
            <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
              DATE OF BIRTH
            </Text>
            <EditableInput
              aria-label="Date of Birth"
              name="dateOfBirth"
              fontSize={14}
              fontWeight={400}
              lineHeight="20px"
              color="#2D3748"
              w="full"
              isEditing={isEditing}
              isLoading={isComponentLoading}
              mask={dateOfBirthMask}
              maxLength={10}
              registerOptions={{ required: "Date of Birth is required." }}
            />
          </VStack>
        </GridItem>

        <UploadedDocumentsList
          documents={documents}
          deathCertificateUrl={deceasedDetails?.latestDeathCertificate?.url}
        />
      </GridWrapper>
    </FormProvider>
  );
}
