import { UseMediaQueryOptions, useMediaQuery } from "@chakra-ui/react";

export function useIsMobile(values?: string | string[], arg?: UseMediaQueryOptions) {
  const [isMobile] = useMediaQuery(values ?? "(max-width: 767px)", {
    ssr: false,
    ...arg,
  });

  return isMobile;
}
