import { useAuth0, User } from "@auth0/auth0-react";
import { Box, HStack, Heading, useBreakpointValue } from "@chakra-ui/react";
import { useInheritorStore } from "@store/inheritor.store";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { Breadcrumbs } from "@shared/components/Breadcrumbs/Breadcrumbs.component";

export function Header() {
  const { user }: { user?: User | undefined } = useAuth0();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { currentPathsArray } = useNavigation();
  const { currentInheritor, currentDeceased, isInheritor } = useInheritorStore();

  const replaceUuidWith = (uuid: string) => {
    if (isInheritor && currentInheritor) {
      return `${currentInheritor.firstName} ${currentInheritor.lastName}`;
    }

    if (currentDeceased) {
      return `${currentDeceased.firstName} ${currentDeceased.lastName}`;
    }

    return uuid;
  };

  return (
    <Box
      as="header"
      position="relative"
      w="full"
      py={6}
      px={8}
      _before={{
        content: "''",
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "1px",
        background: "#CBD5E0",
      }}
      minHeight="88px"
    >
      <Box
        _before={{
          content: "''",
          position: "absolute",
          bottom: 0,
          right: 0,
          width: "32px",
          height: "1px",
          background: "#fff",
        }}
        _after={{
          content: "''",
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "32px",
          height: "1px",
          background: "#fff",
        }}
      >
        <HStack justify={isMobile ? "flex-end" : "space-between"}>
          <Heading
            variant="h1"
            size={isMobile ? "md" : "lg"}
            visibility={isMobile ? "hidden" : "visible"}
          >
            {currentPathsArray.length < 2 ? (
              `Hi, ${user?.user_metadata.first_name || user?.name}`
            ) : (
              <Breadcrumbs replaceUuidWith={replaceUuidWith} />
            )}
          </Heading>
        </HStack>
      </Box>
    </Box>
  );
}
