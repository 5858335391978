import { useAuth0 } from "@auth0/auth0-react";
import { Box, Flex, Heading, Stack, useBreakpointValue } from "@chakra-ui/react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ROUTES } from "@config/constants.config";
import { useClearAllStores } from "@hooks/useClearStores.hook";
import { Button } from "@shared/components/Button/Button.component";
import { FormInput } from "@shared/components/FormComponents/FormInput.component";

const defaultValues = {
  preferredName: "",
};

export function OnboardingTemplate() {
  const { user, loginWithRedirect } = useAuth0();
  const { clearAllStores } = useClearAllStores();

  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const formMethods = useForm<typeof defaultValues>({
    defaultValues,
  });
  const { handleSubmit, formState } = formMethods;
  const { errors, isSubmitting } = formState;
  const hasErrors = !!errors.preferredName;

  const onSubmit: SubmitHandler<typeof defaultValues> = data => {
    // TODO: calls for user meta data update
    const updateUserPayload = { ...data, id: user?.sub };
    console.log("updateUserPayload", updateUserPayload);

    clearAllStores();

    return loginWithRedirect({
      authorizationParams: {
        organization: user?.org_id,
        redirect_uri: `${window.location.origin}${ROUTES.INHERITORS}`,
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Box as="form" onSubmit={handleSubmit(onSubmit)} id="inheritor-form" h="full">
        <Stack
          position="relative"
          h="full"
          w="full"
          alignItems="center"
          justifyContent="flex-start"
          spacing={8}
          p={8}
        >
          <Flex
            gap={4}
            width={isDesktop ? "auto" : "100%"}
            direction="column"
            padding={isDesktop ? 8 : 0}
            borderRadius={6}
          >
            <Heading variant="h1" size={{ base: "md", md: "lg" }} mb={2}>
              What should we call you?
            </Heading>
            <FormInput
              aria-label="Preferred name"
              size="md"
              label="Enter the name of your preferred name"
              name="preferredName"
              registerOptions={{ required: "Preferred name is required.", minLength: 3 }}
              labelProps={{ backgroundColor: "#fff" }}
            />
            <Button
              aria-label="Continue"
              type="submit"
              variant="whiteLabel"
              isDisabled={hasErrors}
              isLoading={isSubmitting}
            >
              Continue
            </Button>
          </Flex>
        </Stack>
      </Box>
    </FormProvider>
  );
}
