/* eslint-disable react-refresh/only-export-components */
import { lazy, Suspense } from "react";
import { Outlet, RouteObject } from "react-router-dom";
import { SearchIcon } from "@chakra-ui/icons";
import { FiUser, FiUserPlus } from "react-icons/fi";
import { BsFileEarmarkLock } from "react-icons/bs";
import {
  MdOutlineAnalytics,
  MdOutlineBarChart,
  MdOutlineCampaign,
  MdOutlineSpaceDashboard,
} from "react-icons/md";
import { ROUTES, USER_ROLES } from "@config/constants.config";
import { Loading } from "@shared/components/Loading/Loading.component";
import { SidebarItem } from "@src/components/Layout/Sidebar/SidebarContent.component";
import { PrivateLayout } from "@components/Layout/PrivateLayout/PrivateLayout.component";
import { PublicLayout } from "@components/Layout/PublicLayout/PublicLayout.component";
import InheritorsPage from "@pages/private/Inheritors";
import DeceasedsViewPage from "@pages/private/Inheritors/DeceasedsView";
import InheritorsViewPage from "@pages/private/Inheritors/InheritorsView";
import EmbedPage from "@pages/private/Embed";
import InheritorsSSNViewPage from "@pages/private/Inheritors/InheritorsSSNView";
import AddInheritorPage from "@pages/private/Inheritors/AddInheritor";
import InsightsPage from "@pages/private/Insights";
import UserInsightsPage from "@pages/private/Insights/UserInsights";
import OnboardingPage from "@pages/private/Onboarding";
import FindAccountsPage from "@pages/private/FindAccounts";
import FindAccountsUserViewPage from "@pages/private/FindAccounts/FindAccountsUserView";
import CampaignsPage from "@src/pages/private/Campaigns";
import CampaignOverviewPage from "@src/pages/private/Campaigns/CampaignOverview";

//lazy loaded pages
const SigninPage = lazy(() => import("@pages/public/Signin"));
const SignupInvitationPage = lazy(() => import("@pages/public/SignupInvitation"));

//private lazy loaded pages

export const EstateAdminRoutes: RouteObject[] = [
  {
    path: ROUTES.SIGNIN,
    element: (
      <Suspense fallback={<Loading />}>
        <PublicLayout title="Sign In to Your Organization">
          <SigninPage />
        </PublicLayout>
      </Suspense>
    ),
  },
  {
    path: ROUTES.SIGNUP_INVITATION,
    element: (
      <Suspense fallback={<Loading />}>
        <PublicLayout title="Sign Up to Your Organization">
          <SignupInvitationPage />
        </PublicLayout>
      </Suspense>
    ),
  },
  {
    path: ROUTES.EMBED,
    element: (
      <Suspense fallback={<Loading />}>
        <PrivateLayout
          title="Embeddables"
          viewPermissions={[USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_EDITOR]}
        >
          <EmbedPage />
        </PrivateLayout>
      </Suspense>
    ),
  },
  {
    path: ROUTES.ONBOARDING,
    element: (
      <Suspense fallback={<Loading />}>
        <PrivateLayout
          title="Onboarding"
          viewPermissions={[USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_EDITOR]}
        >
          <OnboardingPage />
        </PrivateLayout>
      </Suspense>
    ),
  },
  {
    path: ROUTES.INHERITORS,
    element: (
      <Suspense fallback={<Loading />}>
        <PrivateLayout
          title="Inheritors"
          viewPermissions={[USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_EDITOR]}
        >
          <Outlet />
        </PrivateLayout>
      </Suspense>
    ),
    children: [
      {
        path: ROUTES.INHERITORS,
        element: <InheritorsPage />,
      },
      {
        path: ROUTES.INHERITORS_VIEW,
        element: <InheritorsViewPage />,
      },
      {
        path: ROUTES.INHERITORS_DECEASEDS_VIEW,
        element: <DeceasedsViewPage />,
      },
      {
        path: ROUTES.INHERITORS_SSN_PREVIEW,
        element: <InheritorsSSNViewPage />,
      },
      {
        path: ROUTES.INHERITORS_DECEASED_SSN_PREVIEW,
        element: <InheritorsSSNViewPage />,
      },
      {
        path: ROUTES.INHERITORS_ADD,
        element: <AddInheritorPage />,
      },
    ],
  },
  {
    path: ROUTES.INSIGHTS,
    element: (
      <Suspense fallback={<Loading />}>
        <PrivateLayout
          title="Insights"
          viewPermissions={[
            USER_ROLES.ORG_ADMIN,
            USER_ROLES.ORG_EDITOR,
            USER_ROLES.MARKETING_SPECIALIST,
          ]}
        >
          <Outlet />
        </PrivateLayout>
      </Suspense>
    ),
    children: [
      {
        path: ROUTES.INSIGHTS,
        element: <InsightsPage />,
      },
      {
        path: ROUTES.INSIGHTS_VIEW,
        element: <UserInsightsPage />,
      },
    ],
  },
  {
    path: ROUTES.CAMPAIGNS,
    element: (
      <Suspense fallback={<Loading />}>
        <PrivateLayout
          title="Campaigns"
          viewPermissions={[
            USER_ROLES.ORG_ADMIN,
            USER_ROLES.ORG_EDITOR,
            USER_ROLES.MARKETING_SPECIALIST,
          ]}
        >
          <Outlet />
        </PrivateLayout>
      </Suspense>
    ),
    children: [
      {
        path: ROUTES.CAMPAIGNS,
        element: <CampaignsPage />,
      },
      {
        path: ROUTES.CAMPAIGN_OVERVIEW,
        element: <CampaignOverviewPage />,
      },
    ],
  },
  {
    path: ROUTES.FIND_ACCOUNTS,
    element: (
      <Suspense fallback={<Loading />}>
        <PrivateLayout
          title="Find accounts"
          viewPermissions={[USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_EDITOR]}
        >
          <Outlet />
        </PrivateLayout>
      </Suspense>
    ),
    children: [
      {
        path: ROUTES.FIND_ACCOUNTS,
        element: <FindAccountsPage />,
      },
      {
        path: ROUTES.FIND_ACCOUNTS_USER_VIEW,
        element: <FindAccountsUserViewPage />,
      },
    ],
  },
];

export const sidebarRoutes: SidebarItem[] = [
  {
    path: ROUTES.INHERITORS,
    title: "Inheritors",
    icon: <MdOutlineSpaceDashboard />,
    disabled: false,
    viewPermissions: [USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_EDITOR],
    children: [
      {
        path: ROUTES.INHERITORS_VIEW,
        title: "Inheritor View",
        icon: <FiUser />,
        disabled: false,
        children: [
          {
            path: ROUTES.INHERITORS_SSN_PREVIEW,
            title: "SSN Preview",
            disabled: false,
            icon: <BsFileEarmarkLock />,
          },
        ],
      },
      {
        path: ROUTES.INHERITORS_DECEASEDS_VIEW,
        title: "Deceased View",
        icon: <FiUser />,
        disabled: false,
        children: [
          {
            path: ROUTES.INHERITORS_DECEASED_SSN_PREVIEW,
            title: "SSN Preview",
            disabled: false,
            icon: <BsFileEarmarkLock />,
          },
        ],
      },
      {
        path: ROUTES.INHERITORS_ADD,
        title: "Add Inheritor",
        icon: <FiUserPlus />,
        disabled: false,
      },
    ],
  },
  {
    path: ROUTES.INSIGHTS,
    title: "Insights",
    icon: <MdOutlineAnalytics />,
    disabled: false,
    viewPermissions: [USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_EDITOR, USER_ROLES.MARKETING_SPECIALIST],
    children: [
      {
        path: ROUTES.INSIGHTS_VIEW,
        title: "Client Insights",
        disabled: false,
        icon: <FiUser />,
      },
    ],
  },
  {
    path: ROUTES.CAMPAIGNS,
    title: "Campaigns",
    icon: <MdOutlineCampaign />,
    disabled: false,
    viewPermissions: [USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_EDITOR, USER_ROLES.MARKETING_SPECIALIST],
    children: [
      {
        path: ROUTES.CAMPAIGN_OVERVIEW,
        title: "Campaign overview",
        disabled: false,
        icon: <MdOutlineBarChart />,
      },
    ],
  },
  {
    path: ROUTES.FIND_ACCOUNTS,
    title: "Find accounts",
    icon: <SearchIcon />,
    disabled: true,
    children: [
      {
        path: ROUTES.FIND_ACCOUNTS_USER_VIEW,
        title: "Find account View",
        disabled: true,
        icon: <FiUser />,
      },
    ],
  },
];
