import { Grid, GridItem, Text } from "@chakra-ui/react";
import { DeceasedPersonStore } from "@shared/config/schema.validator";

type DeceasedReviewProps = {
  deceasedInfo: DeceasedPersonStore | null;
  isMobile: boolean;
};

export function DeceasedReview({ deceasedInfo, isMobile }: Readonly<DeceasedReviewProps>) {
  return (
    <Grid
      templateColumns={{
        base: "repeat(auto-fill, minmax(250px, 1fr))",
        xl: "repeat(4, minmax(250px, 1fr))",
      }}
      gap={4}
      width="100%"
    >
      <GridItem>
        <Text fontSize="14px" fontWeight={500} lineHeight="24px" color="#718096">
          First Name:
        </Text>
        <Text
          datatest-id="review-deceased-first-name"
          fontSize="16px"
          fontWeight={600}
          lineHeight="24px"
          color="#171923"
        >
          {deceasedInfo?.firstName}
        </Text>
      </GridItem>
      <GridItem>
        <Text fontSize="14px" fontWeight={500} lineHeight="24px" color="#718096">
          Middle Name:
        </Text>
        <Text
          datatest-id="review-deceased-middle-name"
          fontSize="16px"
          fontWeight={600}
          lineHeight="24px"
          color="#171923"
        >
          {deceasedInfo?.middleName}
        </Text>
      </GridItem>
      <GridItem>
        <Text fontSize="14px" fontWeight={500} lineHeight="24px" color="#718096">
          Last Name:
        </Text>
        <Text
          datatest-id="review-deceased-last-name"
          fontSize="16px"
          fontWeight={600}
          lineHeight="24px"
          color="#171923"
        >
          {deceasedInfo?.lastName}
        </Text>
      </GridItem>
      <GridItem>
        <Text fontSize="14px" fontWeight={500} lineHeight="24px" color="#718096">
          {isMobile ? "SSN" : "Social Security Number"}:
        </Text>
        <Text
          datatest-id="review-deceased-ssn"
          fontSize="16px"
          fontWeight={600}
          lineHeight="24px"
          color="#171923"
          whiteSpace="nowrap"
        >
          {`XXX-XX-${deceasedInfo?.ssn?.slice(0, 4)}`}
        </Text>
      </GridItem>
      <GridItem>
        <Text fontSize="14px" fontWeight={500} lineHeight="24px" color="#718096">
          Date of Birth:
        </Text>
        <Text
          datatest-id="review-deceased-date-of-birth"
          fontSize="16px"
          fontWeight={600}
          lineHeight="24px"
          color="#171923"
        >
          {deceasedInfo?.dateOfBirth}
        </Text>
      </GridItem>
    </Grid>
  );
}
