import { useCallback } from "react";
import { Flex, HStack, Heading, Stack, Text, VStack, Tag, Box, SimpleGrid } from "@chakra-ui/react";
import { CheckCircleIcon, MinusIcon } from "@chakra-ui/icons";
import { CellContext, Row, createColumnHelper } from "@tanstack/react-table";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { Button } from "@shared/components/Button/Button.component";
import { DataTable } from "@shared/components/DataTable/DataTable.component";
import { InheritorDual, FinancialAccountType } from "@trustribbon/ribbon-ec-types";
import { useInheritorStore } from "@src/store/inheritor.store";
import { ACCOUNTS_TYPE_STYLE_MAP, ROUTES } from "@src/config/constants.config";
import { useInheritanceCases } from "@src/hooks/useInheritanceCases";
import { Stat } from "./Stat/Stat.template";
import { TbBuildingBank, TbUsersPlus } from "react-icons/tb";
import { RiMoneyDollarBoxLine } from "react-icons/ri";
import { NavigateOptions, To } from "react-router-dom";
import { useInstitutionStore } from "@src/store/institution.store";

type Insights = {
  id: string;
  clients: string;
  member: boolean;
  inheritance: boolean;
  accountType: FinancialAccountType[];
  condolences: string;
};

const columnHelper = createColumnHelper<Insights>();

const insightsCellComponent = (info: CellContext<Insights, string>) => (
  <Text fontSize={14} fontWeight={500} lineHeight="20px">
    {info.getValue()}
  </Text>
);

const insightsBadgeComponent = (info: CellContext<Insights, boolean>, institutionType: string) => (
  <Tag
    colorScheme={info.getValue() ? "green" : "red"}
    textTransform="none"
    fontWeight={700}
    fontSize={14}
    lineHeight="16px"
  >
    {institutionType === "credit-union" ? (info.getValue() ? "Member" : "Not a member") : undefined}
    {institutionType === "bank" ? (info.getValue() ? "Customer" : "Not a customer") : undefined}
  </Tag>
);

const FinancialAccountComponent = (info: CellContext<Insights, FinancialAccountType[]>) => {
  const accounts = info.getValue() ?? [];
  const uniqueAccountTypes = [
    ...new Set(accounts?.map(account => account.accountType ?? "unknown")),
  ];

  return (
    <HStack spacing={2}>
      {uniqueAccountTypes.length > 0 ? (
        uniqueAccountTypes
          .filter(type => type)
          .map(accountType => {
            const { textColor, bgColor, label } =
              ACCOUNTS_TYPE_STYLE_MAP[accountType as keyof typeof ACCOUNTS_TYPE_STYLE_MAP] ||
              ACCOUNTS_TYPE_STYLE_MAP.default;

            return (
              <Tag
                key={accountType}
                textColor={textColor}
                bgColor={bgColor}
                textTransform="capitalize"
                fontWeight={700}
                fontSize={14}
                lineHeight="16px"
              >
                {label}
              </Tag>
            );
          })
      ) : (
        <Tag
          bgColor={`#E2E8F0`}
          textColor={`#4A5568
          
        `}
          fontWeight={700}
          fontSize={14}
          lineHeight="16px"
        >
          Not Specified
        </Tag>
      )}
    </HStack>
  );
};

const columns = (institutionType: string) => [
  columnHelper.accessor("clients", {
    cell: insightsCellComponent,
    header: "INHERITOR",
  }),
  columnHelper.accessor("member", {
    cell: (info: CellContext<Insights, boolean>) => insightsBadgeComponent(info, institutionType),
    header: "MEMBERSHIP",
  }),
  columnHelper.accessor("inheritance", {
    cell: info => {
      const hasInherited = info.getValue();

      return (
        <Text fontSize={14} fontWeight={500} lineHeight="20px">
          {hasInherited ? (
            <CheckCircleIcon color="green.500" />
          ) : (
            <MinusIcon color="white" borderRadius="100px" bg="#F56565" padding="3px" />
          )}
          {hasInherited ? " Inherited" : " Hasn't inherited"}
        </Text>
      );
    },
    header: "INHERITANCE",
  }),
  columnHelper.accessor("accountType", {
    cell: FinancialAccountComponent,
    header: "ACCOUNTS",
  }),
  columnHelper.accessor("condolences", {
    cell: info => {
      const a = info.getValue() as unknown as {
        id: string;
        name: string;
        navigate: (to: To, options?: NavigateOptions | undefined) => void;
      };
      return ["Patrick"].includes(a.name) ? (
        <Button
          variant="whiteLabelLink"
          px={0}
          textDecoration="underline"
          cursor={"pointer"}
          fontSize={14}
          fontWeight={700}
          lineHeight="20px"
          color="#319795"
          onClick={e => {
            e.stopPropagation();
            a.navigate(`${ROUTES.INSIGHTS_VIEW.replace(":id", a.id)}`);
          }}
        >
          Arriving on 06.16.2024
        </Button>
      ) : ["Christopher"].includes(a.name) ? (
        <Button
          variant="whiteLabelLink"
          px={0}
          textDecoration="underline"
          fontSize={14}
          cursor={"pointer"}
          fontWeight={700}
          lineHeight="20px"
          color="#319795"
          onClick={e => {
            e.stopPropagation();
            a.navigate(`${ROUTES.INSIGHTS_VIEW.replace(":id", a.id)}`);
          }}
        >
          Arriving on 06.17.2024
        </Button>
      ) : ["Victoria"].includes(a.name) ? (
        <Button
          variant="whiteLabelLink"
          px={0}
          textDecoration="underline"
          fontSize={14}
          fontWeight={700}
          cursor={"pointer"}
          lineHeight="20px"
          color="#319795"
          onClick={e => {
            e.stopPropagation();
            a.navigate(`${ROUTES.INSIGHTS_VIEW.replace(":id", a.id)}`);
          }}
        >
          Arriving on 06.15.2024
        </Button>
      ) : (
        <Button
          variant="whiteLabelLink"
          px={0}
          textDecoration="underline"
          fontSize={14}
          fontWeight={700}
          cursor={"pointer"}
          lineHeight="20px"
          color="#319795"
          onClick={e => {
            e.stopPropagation();
            a.navigate(`${ROUTES.INSIGHTS_VIEW.replace(":id", a.id)}`);
          }}
        >
          Condolences Not Sent
        </Button>
      );
    },
    header: "CONDOLENCES GIFT",
  }),
];

export function InsightsTemplate() {
  const { inheritanceCases, isLoading } = useInheritanceCases();
  const { setCurrentInheritor, setIsInheritor } = useInheritorStore();
  const { currentInstitution } = useInstitutionStore();

  const { navigate } = useNavigation();

  const handleRowClick = useCallback(
    (row: Row<Insights>) => {
      const currentRow = inheritanceCases.find(a => a.inheritor.id === row.original.id);

      setIsInheritor(true);
      setCurrentInheritor(currentRow?.inheritor as InheritorDual<"client">);

      navigate(`${ROUTES.INSIGHTS_VIEW.replace(":id", row.original.id)}`);
    },
    [inheritanceCases, navigate, setCurrentInheritor, setIsInheritor]
  );

  const stats = [
    {
      icon: TbBuildingBank,
      label: "DECEASED ACCOUNTS",
      value: "10",
      delta: { value: "9", isUpwardsTrend: true },
    },
    {
      icon: RiMoneyDollarBoxLine,
      label: "DEPOSITS RETAINED",
      value: "$40,000",
      delta: { value: "$160k", isUpwardsTrend: true },
    },
    {
      icon: TbUsersPlus,
      label: "ACCOUNTS OPENED",
      value: "12.87%",
      delta: { value: "4", isUpwardsTrend: true },
    },
  ];
  return (
    <Stack position="relative" h="full" w="full" alignItems="flex-start" gap={6} p={9} pt={10}>
      <VStack w="full" gap={6} px={1}>
        <HStack w="full" justifyContent="space-between" alignItems="flex-start">
          <Heading variant="h1" fontSize="24px" color="gray.700" lineHeight="36px" fontWeight={600}>
            Insights
          </Heading>
          <Flex gap={4} justifyContent="flex-end" flexWrap="wrap"></Flex>
        </HStack>
      </VStack>
      <Stack w="full">
        <Box as="section" py={{ base: "4" }}>
          <SimpleGrid columns={{ base: 1, md: 3 }} gap={{ base: "5", md: "full" }}>
            {stats.map((stat, id) => (
              <Stat key={id} {...stat} />
            ))}
          </SimpleGrid>
        </Box>
        <DataTable
          columns={columns(currentInstitution?.type ?? "credit-union")}
          data={
            inheritanceCases.map(a => ({
              id: a.inheritor.id,
              clients: `${a.inheritor.firstName} ${a.inheritor.lastName}`,
              member: String(a.inheritor.member) === "true",
              inheritance: String(a.inheritor.member) === "true",
              accountType: a.financialAccounts,
              condolences: { id: a.inheritor.id, name: a.inheritor.firstName, navigate: navigate },
            })) as unknown as Insights[]
          }
          handleRowClick={handleRowClick}
          isLoading={isLoading}
          isInsight={true}
        />
      </Stack>
    </Stack>
  );
}
