export const getValuesFromDirtyFieldsIndexes = <T extends object>(
  data?: T,
  dirtyFields?: object
) => {
  if (!data || !dirtyFields) {
    return [];
  }

  const dirtyFieldsIndexes = Object.keys(dirtyFields).map(Number);

  return dirtyFieldsIndexes.map(index => data[index as keyof typeof data]);
};
