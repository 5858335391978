import { useState } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";
import { MaskedInputProps } from "react-text-mask";
import {
  InputProps,
  FormControl,
  Input,
  InputGroup,
  FormControlProps,
  InputGroupProps,
  FormLabelProps,
  FormLabel,
  FormErrorMessage,
  Skeleton,
  InputRightElement,
} from "@chakra-ui/react";
import { FormMaskedInput } from "./FormMaskedInput.component";
import { Button } from "../Button/Button.component";
import { HiEye, HiEyeOff } from "react-icons/hi";

type EditableInputProps = {
  name: string;
  registerOptions: RegisterOptions;
  label?: string;
  mask?: (string | RegExp)[];
  formControlProps?: FormControlProps;
  inputGroupProps?: InputGroupProps;
  labelProps?: FormLabelProps;
  maskOptions?: MaskedInputProps;
  isLoading?: boolean;
  isEditing?: boolean;
} & InputProps;

type RenderInputComponentProps = {
  name: string;
  registerOptions: RegisterOptions;
  mask?: (string | RegExp)[];
  maskOptions?: MaskedInputProps;
  showPassword: boolean;
  isEditing?: boolean;
} & InputProps;

type RenderPasswordToggleButtonProps = {
  showPassword: boolean;
  setShowPassword: (show: boolean) => void;
} & InputProps;

const RenderInputComponent = ({
  name,
  registerOptions,
  type,
  mask,
  maskOptions,
  showPassword,
  isEditing,
  ...rest
}: RenderInputComponentProps) => {
  const { register } = useFormContext();

  const inputType = type === "password" && !showPassword ? "password" : "text";

  return mask ? (
    <FormMaskedInput
      type={inputType}
      name={name}
      mask={mask}
      guide={false}
      placeholder=" "
      placeholderChar={"\u2000"}
      showMask={true}
      maskOptions={maskOptions}
      border="none"
      _disabled={{
        color: "#2D3748",
        cursor: "default",
      }}
      isDisabled={!isEditing}
      boxShadow={isEditing ? "0 0 3px #A0AEC0" : "0 0 3px transparent"}
      transition="all 0.3s"
      transform="translateX(-1rem)"
      height="min-content"
      minHeight="32px"
      {...rest}
    />
  ) : (
    <Input
      type={inputType}
      border="none"
      _disabled={{
        color: "#2D3748",
        cursor: "default",
      }}
      isDisabled={!isEditing}
      boxShadow={isEditing ? "0 0 3px #A0AEC0" : "0 0 3px transparent"}
      transition="all 0.3s"
      transform="translateX(-1rem)"
      height="min-content"
      minHeight="32px"
      placeholder=" "
      {...rest}
      {...register(name, {
        ...registerOptions,
        onChange: rest.onChange
          ? e => {
              rest.onChange?.(e);
            }
          : undefined,
      })}
    />
  );
};

const RenderPasswordToggleButton = ({
  showPassword,
  setShowPassword,
  type,
}: RenderPasswordToggleButtonProps) =>
  type === "password" && (
    <InputRightElement width="3rem" top="50%" transform="translate(-1rem, -50%)">
      <Button
        variant="whiteLabelGhost"
        h="1.75rem"
        size="sm"
        onClick={() => setShowPassword(!showPassword)}
      >
        {showPassword ? <HiEyeOff /> : <HiEye />}
      </Button>
    </InputRightElement>
  );

export function EditableInput({
  name,
  registerOptions = {},
  label,
  type,
  mask,
  formControlProps,
  inputGroupProps,
  labelProps,
  maskOptions,
  isLoading,
  isEditing,
  ...rest
}: EditableInputProps) {
  const [showPassword, setShowPassword] = useState(false);
  const { formState } = useFormContext();

  const { errors } = formState;
  const error = errors[name];

  if (isLoading) {
    return <Skeleton {...{ startColor: "#c0eeee", endColor: "#79d5d5" }} w="full" height="32px" />;
  }

  return (
    <FormControl isInvalid={!!error} {...formControlProps}>
      <InputGroup {...inputGroupProps}>
        <RenderInputComponent
          name={name}
          type={type}
          registerOptions={registerOptions}
          mask={mask}
          maskOptions={maskOptions}
          showPassword={showPassword}
          isEditing={isEditing}
          {...rest}
        />
        {label ? (
          <FormLabel fontSize="16px" fontWeight={400} lineHeight="20px" {...labelProps}>
            {label}
          </FormLabel>
        ) : null}
        <RenderPasswordToggleButton
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          type={type}
        />
      </InputGroup>
      <FormErrorMessage color="red">{error?.message as string}</FormErrorMessage>
    </FormControl>
  );
}
