import { FC, ReactNode } from "react";
import { Grid, GridProps } from "@chakra-ui/react";

type GridWrapperProps = GridProps & {
  children: ReactNode;
};

export const GridWrapper: FC<GridWrapperProps> = ({ children, ...rest }) => {
  return (
    <Grid borderRadius="xl" bg="white" p="10px" boxShadow="md" {...rest}>
      {children}
    </Grid>
  );
};
