import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InheritorStore, inheritorFormSchema } from "@shared/config/schema.validator";
import { Divider, Flex, HStack, Heading, VStack } from "@chakra-ui/react";
import { useAdminNotifyInfoStore } from "@store/adminNotifyInfo.store";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { FormInput } from "@shared/components/FormComponents/FormInput.component";
import { usPhoneMask } from "@shared/config/masks.config";
import { CustomSelect } from "@shared/components/FormComponents/CustomSelect.component";
import { PIIInputs } from "@shared/components/FormComponents/PIIInputs.component";
import { AdminNotifyNavigationBlock } from "../components/AdminNotifyNavigation.component";
import { useInstitutionStore } from "@src/store/institution.store";

type InheritorDetailsProps = {
  onClickNext: () => void;
  onClickBack?: () => void;
};

const defaultValues = {
  firstName: "",
  middleName: null,
  lastName: "",
  email: "",
  ssn: "",
  phoneNumber: "",
  member: undefined,
};

const formInputProps = {
  size: "lg",
  labelProps: { backgroundColor: "#F8F8F8" },
  formControlProps: {
    width: "auto",
    flexGrow: 1,
  },
};

export function InheritorDetailsForm({
  onClickNext,
  onClickBack,
}: Readonly<InheritorDetailsProps>) {
  const { currentInstitution } = useInstitutionStore();
  const isMobile = useIsMobile();
  const { inheritorInfo, setInheritorInfo } = useAdminNotifyInfoStore(state => ({
    inheritorInfo: state.inheritorInfo,
    setInheritorInfo: state.setInheritorInfo,
  }));
  const formMethods = useForm<InheritorStore>({
    defaultValues: inheritorInfo ?? defaultValues,
    resolver: yupResolver(inheritorFormSchema),
  });

  const { handleSubmit } = formMethods;

  const onSubmit: SubmitHandler<InheritorStore> = data => {
    setInheritorInfo(data);

    return onClickNext();
  };

  return (
    <FormProvider {...formMethods}>
      <VStack w="full" gap={7}>
        <HStack w="full" justifyContent="space-between">
          <Heading variant="h1" fontSize="24px" color="gray.700" lineHeight="40px" fontWeight={600}>
            Add Inheritor details
          </Heading>
        </HStack>
      </VStack>
      <form onSubmit={handleSubmit(onSubmit)} id="admin-inheritor-form">
        <Flex direction="column">
          <Flex gap={8} direction="column" pb={16}>
            <PIIInputs />
            <HStack gap={6} align="flex-start" direction="row" flexWrap="wrap">
              <FormInput
                label="Email"
                name="email"
                registerOptions={{ required: true }}
                {...formInputProps}
              />
              <FormInput
                label="Phone"
                name="phoneNumber"
                variant="whiteLabel"
                mask={usPhoneMask}
                registerOptions={{ required: true }}
                maxLength={17}
                {...formInputProps}
              />
            </HStack>
            <Heading
              variant="h1"
              fontSize="24px"
              color="gray.700"
              lineHeight="40px"
              fontWeight={600}
            >
              Is the Inheritor a{" "}
              {currentInstitution && currentInstitution.type === "bank" ? "customer" : "member"}?
            </Heading>
            <HStack
              pr={3}
              align="flex-start"
              direction="row"
              width={isMobile ? "100%" : "50%"}
              flexWrap="wrap"
            >
              <CustomSelect
                isEditing={true}
                name="member"
                placeholder="Is the inheritor a member?"
                registerOptions={{ required: true }}
                closeMenuOnSelect
                options={[
                  {
                    value: true,
                    label: "Yes",
                  },
                  {
                    value: false,
                    label: "No",
                  },
                ]}
                inputGroupProps={{
                  backgroundColor: "#F8F8F8",
                  sx: {
                    "& .react-select": {
                      width: "100%",
                      height: "48px",
                      "& > div": {
                        height: "48px",
                      },
                    },
                  },
                  boxShadow: "0 0 3px #007dba",
                  borderRadius: "6px",
                }}
                chakraStyles={{
                  option: styles => ({
                    ...styles,
                    backgroundColor: "#F8F8F8",
                  }),
                  dropdownIndicator: provided => ({
                    ...provided,
                    px: 4,
                  }),
                }}
              />
            </HStack>
          </Flex>
          <Divider color="#CBD5E0" />
          <AdminNotifyNavigationBlock
            onClickNext={handleSubmit(onSubmit)}
            onClickBack={onClickBack}
          />
        </Flex>
      </form>
    </FormProvider>
  );
}
