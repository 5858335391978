import useSWR from "swr";
import { listInheritanceFinancialAccount } from "@src/services/inheritanceCase/financialAccount.service";

export function useInheritanceAccountsWithAllocation(caseId?: string) {
  const { data, error, isLoading, mutate } = useSWR(
    `/inheritance-cases/${caseId}/financial-accounts`,
    listInheritanceFinancialAccount,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 120000,
      shouldRetryOnError: false,
    }
  );

  return {
    isLoading,
    inheritanceAccountsWithAllocation: data,
    error: error as Error,
    mutate: () => mutate(undefined, { revalidate: true }),
  };
}
