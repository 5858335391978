import * as yup from "yup";
import dayjs from "dayjs";

type DeceasedCardSchema = yup.ObjectSchema<{
  firstName: string;
  middleName: string;
  lastName: string;
  totalAssets: string;
  dateOfBirth: string;
}>;

export const deceasedCardSchema = yup
  .object({
    firstName: yup.string().required("Inform the deceased's First Name"),
    middleName: yup.string().nullable(),
    lastName: yup.string().required("Inform the deceased's Last Name"),
    totalAssets: yup.string().nullable(),
    dateOfBirth: yup
      .string()
      .test("dateOfBirth", "Invalid Date of Birth. Use MM/DD/YYYY", value => {
        return dayjs(value, "MM/DD/YYYY", true).isValid();
      }),
  })
  .required() as DeceasedCardSchema;

type InheritorCardSchema = yup.ObjectSchema<{
  member: boolean;
  totalAssets: string;
  firstName: string;
  middleName: string;
  lastName: string;
  caseStatus: string;
  phoneNumber: string;
  email: string;
}>;

const inheritorCardSchemaObject = {
  firstName: yup.string().required("Inform the Inheritor's First Name"),
  middleName: yup.string().nullable(),
  lastName: yup.string().required("Inform the Inheritor's Last Name"),
  totalAssets: yup.string().nullable(),
  member: yup.boolean().required("Inform if the Inheritor is a Member"),
  caseStatus: yup.string().required("Inform the Transfer Status"),
  phoneNumber: yup.string().nullable(),
};

export const inheritorCardCreditUnionSchema = yup
  .object({
    ...inheritorCardSchemaObject,
    email: yup.string().email("Inform a valid Email Address").required("Email Address is required"),
  })
  .required() as InheritorCardSchema;

export const inheritorCardBankSchema = yup
  .object({
    ...inheritorCardSchemaObject,
    email: yup.string().email("Inform a valid Email Address").required("Email Address is required"),
  })
  .required() as InheritorCardSchema;
