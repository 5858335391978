/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  TableContainer,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  Flex,
} from "@chakra-ui/react";
import {
  TriangleDownIcon,
  TriangleUpIcon,
  ArrowUpDownIcon,
  SearchIcon,
  CloseIcon,
} from "@chakra-ui/icons";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
  Row,
  Cell,
} from "@tanstack/react-table";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { DataTableSkeleton } from "./components/DataTable.skeleton.component";
import { DataTableError } from "./components/DataTable.error.component";
import { DataTableEmpty } from "./components/DataTable.empty.component";

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  emptyStateLabel?: string;
  handleSearchInputChange?: (value: string) => void;
  handleRowClick?: (row: Row<Data>) => void;
  handleCellClick?: (cell: Cell<Data, any>) => void;
  isLoading?: boolean;
  error?: Error;
  isInsight?: boolean;
};

export function DataTable<Data extends object>({
  data,
  columns,
  emptyStateLabel,
  handleSearchInputChange,
  handleRowClick,
  handleCellClick,
  isLoading,
  error,
  isInsight,
}: Readonly<DataTableProps<Data>>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });
  const isMobile = useIsMobile();

  if (isLoading) {
    const skeletonColumns = isMobile ? columns.slice(0, 1) : columns;

    console.log(columns);
    return (
      <DataTableSkeleton
        skeletonColumns={skeletonColumns.length}
        skeletonRows={columns.length}
        isInsight={isInsight}
      />
    );
  }

  if (error) {
    return <DataTableError error={error} />;
  }

  if (!data || data.length === 0) {
    return <DataTableEmpty table={table} emptyStateLabel={emptyStateLabel} />;
  }

  return (
    <TableContainer
      borderRadius="12px"
      border="1px solid #CBD5E0"
      backgroundColor="white"
      overflowY="auto"
      w="full"
    >
      {handleSearchInputChange && (
        <Flex borderBottom="1px solid #CBD5E0" mb={2}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              height={18}
              width={18}
              children={
                <SearchIcon
                  transition="color 0.2s ease"
                  color={globalFilter ? "gray.900" : "gray.500"}
                />
              }
              top="50%"
              left="16px"
              transform="translateY(-50%)"
            />
            <Input
              type="text"
              variant="whiteLabelGhost"
              value={globalFilter || ""}
              color={globalFilter ? "gray.900" : "gray.500"}
              onChange={e => {
                setGlobalFilter(e.target.value);
                handleSearchInputChange(e.target.value);
              }}
              height="64px"
              padding="16px 48px"
              transition="color 0.2s ease"
              placeholder="Search for name"
            />
            {globalFilter && (
              <InputRightElement
                cursor="pointer"
                children={
                  <CloseIcon fontSize={14} _hover={{ color: "gray.600" }} color="gray.300" />
                }
                onClick={() => {
                  table.setGlobalFilter("");
                  setGlobalFilter("");
                }}
              />
            )}
          </InputGroup>
        </Flex>
      )}
      <Table variant="simple">
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const meta: any = header.column.columnDef.meta;
                const sortedDirection = header.column.getIsSorted();
                const SortIcon = sortedDirection === "desc" ? TriangleDownIcon : TriangleUpIcon;

                return (
                  <Th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                    cursor="pointer"
                    paddingTop={6}
                    paddingBottom={3}
                    px={8}
                    borderColor="#CBD5E0"
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    <chakra.span pl={4} verticalAlign="text-bottom">
                      {sortedDirection ? (
                        <SortIcon aria-label={`sorted ${sortedDirection}`} />
                      ) : (
                        <ArrowUpDownIcon
                          aria-label="sorted ascending"
                          visibility="hidden"
                          _groupHover={{ visibility: "visible" }}
                        />
                      )}
                    </chakra.span>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row, idx, rowArray) => {
            const isLastRow = idx === rowArray.length - 1;

            return (
              <Tr
                key={row.id}
                transition="background-color 0.1s ease-in-out"
                _hover={{ bg: handleRowClick ? "#eeededba" : "inherit" }}
                cursor={handleRowClick ? "pointer" : "inherit"}
                onClick={() => handleRowClick?.(row)}
              >
                {row.getVisibleCells().map(cell => {
                  const meta: any = cell.column.columnDef.meta;
                  const isCellClickable = meta?.clickable && handleCellClick;

                  return (
                    <Td
                      key={cell.id}
                      isNumeric={meta?.isNumeric}
                      paddingTop={4}
                      paddingBottom={3}
                      px={8}
                      borderColor={isLastRow ? "transparent" : "#CBD5E0"}
                      transition="background-color 0.1s ease-in-out"
                      _hover={{ bg: isCellClickable ? "#eeededba" : "inherit" }}
                      cursor={isCellClickable ? "pointer" : "inherit"}
                      onClick={() => isCellClickable && handleCellClick?.(cell)}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
