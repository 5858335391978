import { HStack, VStack, Heading, Text, Divider } from "@chakra-ui/react";
import { MdCircle } from "react-icons/md";
import { CampaignCardComponent } from "./CampaignCard.component";
import { Campaign, useCampaignStore } from "@src/store/campaign.store";

export type CampaignStatus = "active" | "draft" | "completed" | "canceled";
export interface CampaignLaneLabelProps {
  status: CampaignStatus;
  label: string;
}

export interface CampaignLaneProps {
  campaigns: Campaign[];
  status: {
    value: CampaignStatus;
    label: string;
  };
}

export function CampaignLaneComponent({ campaigns, status }: CampaignLaneProps) {
  function statusColor(value: CampaignStatus) {
    switch (value) {
      case "active":
        return "#4299E1";
      case "draft":
        return "#A0AEC0";
      case "completed":
        return "#48BB78";
      case "canceled":
        return "#F56565";
    }
  }

  return (
    <VStack gap="8px" align="flex-start" /* w="244px" */ w="full">
      {/* Campaign lane label */}
      <HStack gap="4px" mr="auto">
        <MdCircle color={statusColor(status.value)} size="12px" />

        <Heading variant="h2" fontSize="16px" color="gray.900" lineHeight="27px" fontWeight={600}>
          {status.label}
        </Heading>

        <Text color="gray.500">{campaigns.length}</Text>
      </HStack>

      {/* Campaign lane content */}
      <VStack gap="12px">
        {campaigns.map(campaign => (
          <CampaignCardComponent key={campaign.id} campaign={campaign} />
        ))}
      </VStack>
    </VStack>
  );
}

export function CampaignLanesComponent() {
  const { campaigns } = useCampaignStore();
  const activeCampaigns = campaigns.filter(campaign => campaign.status === "active");
  const draftCampaigns = campaigns.filter(campaign => campaign.status === "draft");
  const completedCampaigns = campaigns.filter(campaign => campaign.status === "completed");
  const canceledCampaigns = campaigns.filter(campaign => campaign.status === "canceled");

  return (
    <HStack
      align="flex-start"
      w="full"
      divider={<Divider orientation="vertical" borderColor="gray.300" />}
      gap="16px"
      pb="20px"
      overflowX="auto"
    >
      {/* Active */}
      <CampaignLaneComponent
        campaigns={activeCampaigns}
        status={{ label: "Active", value: "active" }}
      />

      {/* Draft */}
      <CampaignLaneComponent
        campaigns={draftCampaigns}
        status={{ label: "Draft", value: "draft" }}
      />

      {/* Canceled */}
      <CampaignLaneComponent
        campaigns={canceledCampaigns}
        status={{ label: "Canceled", value: "canceled" }}
      />

      {/* Completed */}
      <CampaignLaneComponent
        campaigns={completedCampaigns}
        status={{ label: "Completed", value: "completed" }}
      />
    </HStack>
  );
}
