import { useAuth0 } from "@auth0/auth0-react";
import { HStack } from "@chakra-ui/react";
import { MdLogout } from "react-icons/md";
import { ROUTES } from "@config/constants.config";
import { Button } from "@shared/components/Button/Button.component";

export function LogoutMenu() {
  const { logout } = useAuth0();

  return (
    <HStack width="100%">
      <Button
        aria-label="Log out"
        width="100%"
        justifyContent="flex-start"
        leftIcon={<MdLogout />}
        onClick={() =>
          logout({
            logoutParams: {
              returnTo: `${window.location.origin}${ROUTES.SIGNIN}`,
            },
          })
        }
        variant="whiteLabelGhost"
      >
        Log out
      </Button>
    </HStack>
  );
}
