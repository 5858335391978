import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import { DocumentType } from "@trustribbon/ribbon-ec-types";
import { EnhancedFile } from "@shared/types";

export type Document = { [fieldName in DocumentType]: EnhancedFile | null };

interface DocumentStore {
  loading: boolean;
  document: Document | null;
  setDocument: (document: Document) => void;
  clearPersistedStore: () => void;
}

export const useDocumentStore = create(
  devtools(
    persist<DocumentStore>(
      set => ({
        loading: false,
        document: null,
        setDocument: document =>
          set(state => {
            return { document: { ...state.document, ...document } };
          }),
        clearPersistedStore: () =>
          set(() => {
            return { document: null };
          }),
      }),
      {
        name: "document-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    {
      enabled: process.env.NODE_ENV === "development",
      anonymousActionType: "set document",
      store: "documentStore",
      name: "Upload Document",
    }
  )
);
