export function formatNumberToCurrency(value: number): string {
  return (+value)?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
}

export function formatNumberToPercentage(value: number): string {
  return (+value)?.toLocaleString("en-US", {
    style: "percent",
  });
}

export function cleanFilename(filename: string): string {
  return filename.replace(/\s/g, "-"); // remove any spaces and replace with a dash
}

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export function cleanNumberString(str: string): string {
  return str.replace(/[^0-9.]/g, "");
}

export function cleanPercentageString(str: string): number {
  return +str.replace(/[^0-9.]/g, "") / 100;
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
