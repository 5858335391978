import { useState } from "react";
import { Link } from "react-router-dom";
import { FiPaperclip } from "react-icons/fi";
import {
  useFormContext,
  useController,
  RegisterOptions,
  FieldValues,
  useWatch,
} from "react-hook-form";
import { useDropzone } from "react-dropzone";
import {
  Box,
  FormControl,
  HStack,
  Icon,
  Image,
  Progress,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CheckIcon, MinusIcon } from "@chakra-ui/icons";
import { motion } from "framer-motion";
import { EnhancedFile } from "@shared/types";
import { DocumentType, PreVerifyDocumentResponse } from "@trustribbon/ribbon-ec-types";
import { compressImage, compressPdfImage } from "@shared/utils/compressFile.utils";
import { useDocumentStore, Document } from "@shared/store/document.store";
import { DocumentUploadLoading } from "../Loading/DocumentUploadLoading.component";
import { Button } from "../Button/Button.component";

const documentTypeMap = {
  "death-certificates": "death certificate",
  "government-ids": "government ID",
  wills: "Will",
};

const verificationStages = {
  VERIFYING: {
    label: () => "Verifying",
    icon: <Progress size="xs" isIndeterminate width="100px" borderRadius="4px" />,
    media: (
      <Image
        src="https://static.trustribbon.com/assets/animations/document_upload_verifying.gif"
        alt="File preview"
        maxW="200px"
        maxH="165px"
      />
    ),
    showRetryButton: false,
  },
  SUCCESS: {
    label: (document: DocumentType) =>
      `The ${documentTypeMap[document]} has been uploaded successfully.`,
    icon: (
      <Icon
        as={CheckIcon}
        w={6}
        h={6}
        color="white"
        bg="green.500"
        borderRadius="50%"
        padding={1}
      />
    ),
    media: (
      <Image
        src="https://static.trustribbon.com/assets/animations/document_verified_success.gif"
        alt="File preview"
        maxW="101px"
        maxH="101px"
      />
    ),
    showRetryButton: false,
  },
  FAILURE: {
    label: (document: DocumentType) => `Please upload a valid ${documentTypeMap[document]}`,
    icon: (
      <Icon as={MinusIcon} w={6} h={6} color="white" bg="red.500" borderRadius="50%" padding={1} />
    ),
    media: undefined,
    showRetryButton: true,
  },
};

interface UploadDocumentProps {
  documentType: DocumentType;
  rules: Omit<
    RegisterOptions<FieldValues, string>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  accept?: { [key: string]: string[] };
  verificationService?: (
    fileBlob: Blob,
    documentType: DocumentType
  ) => Promise<PreVerifyDocumentResponse>;
}

type BeforeDropComponentProps = {
  isDragActive: boolean;
  open: () => void;
};

function BeforeDropComponent({ isDragActive, open }: BeforeDropComponentProps) {
  return isDragActive ? (
    <DocumentUploadLoading
      label="Drop your document here"
      icon={<Progress size="xs" isIndeterminate width="100px" borderRadius="4px" />}
      media={
        <Image
          src="https://static.trustribbon.com/assets/animations/document_upload_started.gif"
          alt="File preview"
          maxW="200px"
          maxH="165px"
        />
      }
    />
  ) : (
    <VStack gap={4}>
      <Button variant="whiteLabel" gap={0} size="md" borderRadius="6px" onClick={open}>
        Browse files
      </Button>
      <Text color="#718096" fontSize="16px" lineHeight="20px" letterSpacing="1px">
        Drag 'n' drop your document here
      </Text>
    </VStack>
  );
}

export function UploadDocument({
  documentType,
  rules,
  accept = { "image/png": [".png"], "image/jpeg": [".jpg", ".jpeg"], "application/pdf": [".pdf"] },
  verificationService,
}: Readonly<UploadDocumentProps>) {
  const { setValue, setError, formState, clearErrors, control } = useFormContext();
  const defaultValue = useWatch({ name: documentType, control });
  const { setDocument } = useDocumentStore(state => ({
    setDocument: state.setDocument,
  }));

  const errors = formState.errors;

  const {
    field: { ref, onChange, value },
  } = useController({ name: documentType, rules });

  const [file, setFile] = useState<EnhancedFile | undefined>(defaultValue || value);
  const [isLoading, setIsLoading] = useState(false);

  const hasError = errors[documentType] && errors[documentType]?.message !== "";

  const [currentStage, setCurrentStage] =
    useState<(typeof verificationStages)[keyof typeof verificationStages]>();

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    disabled: isLoading,
    noClick: true,
    noKeyboard: true,
    accept,
    onDrop: async acceptedFiles => {
      const newFile = acceptedFiles[0];

      setFile(newFile as unknown as EnhancedFile);
      setError(documentType, {
        type: "manual",
        message: "",
      });

      setIsLoading(true);

      let imageBlob: Blob;

      setCurrentStage(verificationStages.VERIFYING);

      try {
        if (newFile.type === "application/pdf") {
          imageBlob = await compressPdfImage(newFile);
        } else {
          imageBlob = await compressImage(newFile);
        }

        if (documentType !== "death-certificates" && documentType !== "government-ids") {
          const enhancedFile = Object.assign(newFile, {
            isDocument: true,
            preview: URL.createObjectURL(imageBlob),
          });

          setCurrentStage(verificationStages.SUCCESS);

          clearErrors(documentType);

          setIsLoading(false);
          setValue(documentType, enhancedFile);
          return setFile(enhancedFile);
        }

        const verificationData = await verificationService?.(imageBlob, documentType);

        const enhancedFile = Object.assign(newFile, {
          preview: URL.createObjectURL(imageBlob),
          ...verificationData,
        }) as EnhancedFile;

        setIsLoading(false);
        setValue(documentType, enhancedFile);
        setFile(enhancedFile);

        if (enhancedFile.isDocument && enhancedFile.isLegible) {
          setCurrentStage(verificationStages.SUCCESS);
          clearErrors(documentType);
        } else if (!enhancedFile.isDocument) {
          setCurrentStage(verificationStages.FAILURE);
          setError(documentType, {
            type: "manual",
            message: "The file may not be a document.",
          });
        } else if (enhancedFile.isDocument && !enhancedFile.isLegible) {
          setCurrentStage(verificationStages.FAILURE);
          setError(documentType, {
            type: "manual",
            message:
              "We couldn't detect text in the image, suggesting that the image may have low quality. Please try again with a different file.",
          });
        } else {
          setCurrentStage(verificationStages.FAILURE);
          setIsLoading(false);
          setError(documentType, {
            type: "manual",
            message: "Please try with a different file or try again.",
          });
        }
      } catch (error) {
        setCurrentStage(verificationStages.FAILURE);
        setError(documentType, {
          type: "manual",
          message: "Error checking image quality.",
        });
        setIsLoading(false);
      }
    },
    onDropRejected: fileRejections => {
      setValue(documentType, undefined);
      setError(documentType, {
        type: "manual",
        message: `The uploaded document ${fileRejections[0].file.name} is not supported.`,
      });
    },
    useFsAccessApi: false,
  });

  return (
    <FormControl isInvalid={hasError}>
      <Stack>
        <Box
          data-testid="dropzone-input"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='black' stroke-width='2' stroke-dasharray='4%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
          }}
          as={motion.div}
          rounded="md"
          borderRadius="6px"
          backgroundColor="#0000000A"
          transition="all 0.2s ease-in-out"
          initial="rest"
          animate="rest"
          whileHover="hover"
          padding="64px 32px"
          height="300px"
          display="flex"
          flexDirection="row"
          position="relative"
          _hover={{
            shadow: "md",
          }}
          {...getRootProps({ className: "dropzone" })}
        >
          <Box position="relative" height="100%" width="100%">
            <Box
              position="absolute"
              top="0"
              left="0"
              height="100%"
              width="100%"
              display="flex"
              flexDirection="column"
            >
              <Stack
                height="100%"
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                spacing="4"
              >
                {!isDragActive && currentStage ? (
                  <VStack gap={2}>
                    {currentStage.showRetryButton && (
                      <Button
                        variant="whiteLabel"
                        gap={0}
                        size="md"
                        borderRadius="6px"
                        onClick={open}
                      >
                        Browse files
                      </Button>
                    )}
                    <DocumentUploadLoading
                      label={currentStage.label(documentType)}
                      documentName={file?.name ?? ""}
                      icon={currentStage.icon}
                      media={currentStage.media}
                    />
                  </VStack>
                ) : (
                  <BeforeDropComponent isDragActive={isDragActive} open={open} />
                )}
              </Stack>
            </Box>
            <input ref={ref} {...getInputProps({ onChange })} disabled={isLoading} />
          </Box>
        </Box>
        {file?.preview && (
          <Box width="max-content">
            <Link to={file.preview} target="_blank">
              <HStack spacing={2}>
                <VStack alignItems="flex-start">
                  <HStack>
                    <FiPaperclip />{" "}
                    <Text fontWeight={"bold"} fontSize={{ base: "sm", md: "md" }}>
                      {file.name}
                    </Text>
                  </HStack>
                </VStack>
              </HStack>
              <Image
                src={file.preview}
                fallback={<></>}
                alt="File preview"
                maxW="200px"
                maxH="200px"
                mt="2"
                onError={() => {
                  setFile(undefined);
                  setDocument({ [documentType]: null } as unknown as Document);
                }}
              />
            </Link>
          </Box>
        )}
      </Stack>
    </FormControl>
  );
}
