import { ReactNode } from "react";
import { RegisterOptions, useFormContext, useWatch, Controller } from "react-hook-form";
import {
  FormControl,
  Flex,
  FormControlProps,
  FormErrorMessage,
  InputGroup,
  Tag,
  InputGroupProps,
  SystemStyleObject,
  Skeleton,
} from "@chakra-ui/react";
import {
  chakraComponents,
  Select,
  GroupBase,
  SelectComponentsConfig,
  OptionBase,
  ChakraStylesConfig,
} from "chakra-react-select";
import { IoChevronDownCircleOutline } from "react-icons/io5";

export interface DefaultOption {
  value: string | number | boolean;
  label: ReactNode;
}

export type FormSelectItem = DefaultOption | string;

export type CustomSelectProps = {
  name: string;
  options: FormSelectItem[];
  placeholder?: string;
  registerOptions?: RegisterOptions;
  isEditing?: boolean;
  formControlProps?: FormControlProps;
  closeMenuOnSelect?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  components?: Partial<SelectComponentsConfig<any, boolean, GroupBase<any>>>;
  variant?: "whiteLabel" | "ghost";
  inputGroupProps?: InputGroupProps;
  chakraStyles?: ChakraStylesConfig;
  isMulti?: boolean;
  isLoading?: boolean;
} & {
  defaultValue?: DefaultOption;
};

export interface StatusSelectOption extends OptionBase {
  value: string | number | boolean;
  label: string;
  textColor: string;
  tagColor: string;
}

export const selectCustomComponents: SelectComponentsConfig<
  StatusSelectOption,
  false,
  GroupBase<StatusSelectOption>
> = {
  Option: ({ children, ...props }) => (
    <chakraComponents.Option {...props}>
      <Tag
        size="md"
        variant="subtle"
        textColor={props.data.textColor}
        bgColor={props.data.tagColor}
        mr={2}
      >
        {children}
      </Tag>
    </chakraComponents.Option>
  ),
  SingleValue: ({ children, ...props }) => (
    <chakraComponents.SingleValue {...props}>
      <Tag
        fontSize={"13px"}
        variant="subtle"
        textColor={props.data.textColor}
        bgColor={props.data.tagColor}
        mr={2}
      >
        {children}
      </Tag>
    </chakraComponents.SingleValue>
  ),
  MultiValue: ({ children, ...props }) => (
    <chakraComponents.MultiValue {...props}>
      <Tag
        fontSize={"13px"}
        variant="subtle"
        textColor={props.data.textColor}
        bgColor={props.data.tagColor}
        mr={2}
      >
        {children}
      </Tag>
    </chakraComponents.MultiValue>
  ),
  DropdownIndicator: props => (
    <chakraComponents.DropdownIndicator {...props}>
      <IoChevronDownCircleOutline size="18px" />
    </chakraComponents.DropdownIndicator>
  ),
};

export function CustomSelect({
  name,
  options,
  placeholder,
  registerOptions = {},
  formControlProps,
  closeMenuOnSelect = true,
  components,
  isEditing,
  inputGroupProps,
  chakraStyles,
  isLoading,
  isMulti = false,
  ...rest
}: Readonly<CustomSelectProps>) {
  const { formState, control } = useFormContext();
  const defaultValue = useWatch({ name, control });
  const defaultOption = options.find(option =>
    typeof option === "string" ? option === defaultValue : option.value === defaultValue
  );

  const { errors } = formState;

  const error = errors[name];

  if (isLoading) {
    return <Skeleton {...{ startColor: "#c0eeee", endColor: "#79d5d5" }} w="full" height="32px" />;
  }

  return (
    <FormControl
      as={Flex}
      flexDirection="column"
      isInvalid={!!error}
      alignItems="flex-start"
      {...formControlProps}
      width="full"
    >
      <InputGroup
        backgroundColor="white"
        width="full"
        zIndex={1}
        {...inputGroupProps}
        sx={{
          ...inputGroupProps?.sx,
          "& .react-select": {
            height: "32px",
            cursor: isEditing ? "pointer" : "default",
            "& [id^='react-select-'], [role='listbox']": {
              zIndex: 9999,
            },
            "& > div": {
              minHeight: "32px",
            },
            ...(inputGroupProps?.sx?.[
              "& .react-select" as keyof typeof inputGroupProps.sx
            ] as SystemStyleObject),
          },
        }}
      >
        <Controller
          control={control}
          name={name}
          rules={registerOptions}
          render={({ field: { onChange, onBlur, ref, value, name: controllerName } }) => {
            return (
              <Select
                isMulti={isMulti}
                isLoading={isLoading}
                className="react-select"
                name={controllerName}
                isReadOnly={!isEditing}
                isSearchable={!isEditing}
                placeholder={placeholder}
                selectedOptionStyle="check"
                chakraStyles={{
                  option: styles => ({
                    ...styles,
                  }),
                  dropdownIndicator: provided => ({
                    ...provided,
                    px: 2,
                  }),
                  ...chakraStyles,
                }}
                closeMenuOnSelect={closeMenuOnSelect}
                options={options}
                components={components ?? selectCustomComponents}
                onBlur={onBlur}
                onChange={e => (isMulti ? onChange(e) : onChange(e.value))}
                ref={ref}
                value={isMulti ? value : defaultOption}
                defaultValue={defaultOption}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuShouldScrollIntoView={false}
                menuPortalTarget={document.body}
                {...rest}
              />
            );
          }}
        />
      </InputGroup>
      <FormErrorMessage color="red">{error?.message as string}</FormErrorMessage>
    </FormControl>
  );
}
