import {
  Divider,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { Button } from "@shared/components/Button/Button.component";
import { UploadedDocument } from "@shared/components/UploadedDocument/UploadedDocument.component";
import { CiImageOn } from "react-icons/ci";

export interface UploadedDocumentsListProps {
  documents: { title: string; subtitle: string; archived?: boolean }[];
  deathCertificateUrl?: string;
  governmentIdUrl?: string;
}

export function UploadedDocumentsList({
  documents,
  deathCertificateUrl,
  governmentIdUrl,
}: UploadedDocumentsListProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const activeDocuments = documents.filter(doc => !doc.archived);
  // const archivedDocuments = documents.filter(doc => doc.archived);

  return (
    <>
      <GridItem colSpan={4}>
        <Divider my={4} />
      </GridItem>

      <GridItem colSpan={4} padding="8px 24px 16px">
        <VStack alignItems="flex-start" gap="16px">
          <Text
            fontSize={12}
            color="#gray.500"
            letterSpacing="0.6px"
            fontWeight={700}
            lineHeight="16px"
            textTransform="uppercase"
          >
            Documents
          </Text>
          <Grid templateColumns={"repeat(4, 1fr)"} gap={3} w="full">
            {activeDocuments.map(({ subtitle, title }) => (
              <UploadedDocument
                key={subtitle}
                title={title}
                subtitle={subtitle}
                thumbnail={<CiImageOn color="#666666" size="16px" />}
                thumbnailSize="small"
                deathCertificateUrl={deathCertificateUrl}
                governmentIdUrl={governmentIdUrl}
                menuItems={[
                  { label: "Request a new document", onClick: onOpen, variant: "default" },
                  { label: "View document", onClick: () => {}, variant: "default" },
                  { label: "Archive document", onClick: () => {}, variant: "danger" },
                ]}
              />
            ))}
          </Grid>
        </VStack>
      </GridItem>

      <GridItem colSpan={4}>
        <Divider my={4} />
      </GridItem>

      {/* <GridItem colSpan={4} padding="8px 24px 16px">
        <VStack alignItems="flex-start" gap="16px">
          <Text
            fontSize={12}
            color="#gray.500"
            letterSpacing="0.6px"
            fontWeight={700}
            lineHeight="16px"
            textTransform="uppercase"
          >
            Archived Documents
          </Text>
          <Grid templateColumns={"repeat(4, 1fr)"} gap={3} w="full">
            { archivedDocuments ? archivedDocuments.map(({ title, subtitle }) => (
              <UploadedDocument key={subtitle} title={title} subtitle={subtitle} archived />
            )) : "No a"}
          </Grid>
        </VStack>
      </GridItem> */}

      <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight={700}>Request a new version of this document</ModalHeader>
          <ModalCloseButton />

          <ModalBody py="16px">
            <VStack align="flex-start">
              <Text fontWeight="600" color="gray.800">
                Instructions:
              </Text>
              <Text color="gray.600" fontSize="14px" lineHeight="20px" fontWeight="400">
                Add any additional instructions or information for the inheritor to know about
              </Text>

              <Textarea size="sm" />
            </VStack>
          </ModalBody>

          <ModalFooter display="flex" gap="16px">
            <Button
              w="100%"
              variant="outline"
              borderRadius="6px"
              _hover={{ bg: "whiteLabel.primary", color: "white" }}
              onClick={onClose}
              aria-label="Cancel"
            >
              Cancel
            </Button>
            <Button
              w="100%"
              variant="whiteLabelOutline"
              aria-label="Request document"
              type="submit"
              onClick={onClose}
            >
              Request document
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
