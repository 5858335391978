import { useState } from "react";
import { Link } from "react-router-dom";
import { Divider, HStack, VStack } from "@chakra-ui/react";
import { BsArrowBarRight } from "react-icons/bs";
import { motion } from "framer-motion";
import { AgentRoles } from "@trustribbon/ribbon-ec-types";
import { usePermission } from "@hooks/usePermission.hook";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { Button } from "@shared/components/Button/Button.component";

export type SidebarItem = {
  path: string;
  title: string;
  icon: JSX.Element;
  children?: SidebarItem[];
  disabled?: boolean;
  viewPermissions?: AgentRoles;
};

type SidebarContentProps = {
  routes: SidebarItem[];
  onItemClick?: () => void;
  FooterItemElementComponent?: () => JSX.Element;
};

type RenderSidebarProps = {
  item: SidebarItem;
  level: number;
  onItemClick?: () => void;
  setActiveRoute: (path: string) => void;
  activeRoute: string;
};

const MotionVStack = motion(VStack);

const RenderSidebarItem = ({
  item,
  level,
  onItemClick,
  setActiveRoute,
  activeRoute,
}: RenderSidebarProps): JSX.Element | null => {
  const { currentPathname, params, customLocation } = useNavigation();
  const { id } = params;
  const { path, title, icon, children } = item;

  const currentChildPath = path.replace(/\/:id/g, id ? `/${id}` : "/:id");
  const isItemActive = currentPathname === currentChildPath;
  const isChildActive =
    children &&
    children.some(child => {
      const childPath = child.path.replace(/\/:id/g, id ? `/${id}` : "");
      return currentPathname === childPath || currentPathname.startsWith(`${childPath}/`);
    });

  const handleItemClick = () => {
    onItemClick?.();
    setActiveRoute(path);
  };

  return (
    <VStack key={path} width="100%" gap={1}>
      <HStack width="100%" justifyContent="flex-start">
        {level > 0 && <BsArrowBarRight />}
        <Link
          to={currentChildPath}
          onClick={handleItemClick}
          style={{ width: "100%" }}
          state={customLocation.state}
        >
          <Button
            aria-label={`Navigate to ${title}`}
            leftIcon={icon}
            justifyContent="flex-start"
            bg="transparent"
            width="100%"
            minHeight="48px"
            fontSize="sm"
            fontWeight="500"
            lineHeight="24px"
            color={isItemActive ? "gray.900" : "gray.500"}
            isActive={isItemActive}
            variant={isItemActive ? "whiteLabelSidebarItem" : "whiteLabelGhost"}
          >
            {title}
          </Button>
        </Link>
      </HStack>
      <MotionVStack
        pl={level + 4}
        width="100%"
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: isChildActive ? "auto" : 0 }}
        exit={{ opacity: 0, height: 0 }}
        transition={{ ease: "easeInOut", duration: 0.3 }}
        overflow="hidden"
      >
        {children?.map(childItem => {
          const childItemPath = childItem.path.replace(/\/:id/g, id ? `/${id}` : "/:id");
          const isThisChildActive =
            currentPathname === childItemPath || currentPathname.startsWith(`${childItemPath}/`);
          return isThisChildActive ? (
            <RenderSidebarItem
              key={childItem.path}
              item={childItem}
              level={level + 1}
              onItemClick={onItemClick}
              setActiveRoute={setActiveRoute}
              activeRoute={activeRoute}
            />
          ) : null;
        })}
      </MotionVStack>
    </VStack>
  );
};

export function SidebarContent({
  routes,
  onItemClick,
  FooterItemElementComponent,
}: Readonly<SidebarContentProps>) {
  const [activeRoute, setActiveRoute] = useState("");
  const { isPermitted } = usePermission();

  return (
    <VStack width="100%" height="100%" gap={1} alignItems="flex-start">
      {routes.map(item => {
        const isDisabledOrNoPermission = item.disabled || !isPermitted(item.viewPermissions ?? []);

        return isDisabledOrNoPermission ? null : (
          <RenderSidebarItem
            key={item.path}
            item={item}
            level={0}
            onItemClick={onItemClick}
            setActiveRoute={setActiveRoute}
            activeRoute={activeRoute}
          />
        );
      })}
      <Divider width="100%" mt="auto" />
      {FooterItemElementComponent ? <FooterItemElementComponent /> : null}
    </VStack>
  );
}
