import { useCallback, useMemo, useState } from "react";
import { Flex, Stack } from "@chakra-ui/react";
import { Stepper } from "@shared/components/Stepper/Stepper.component";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { InheritorDetailsForm } from "./forms/InheritorDetails.form";
import { DeceasedDetailsForm } from "./forms/DeceasedDetails.form";
import { UploadDocumentForm } from "./forms/UploadDocument.form";
import { InformationReviewForm } from "./forms/InformationReview.form";

const adminNotificationSteps = [
  { label: "Inheritor details" },
  { label: "Deceased details" },
  { label: "Government ID" },
  { label: "Death Certificate" },
  { label: "Additional documentation" },
];

export function AddInheritorTemplate() {
  const { handleGoBack } = useNavigation();
  const [step, setStep] = useState(0);

  const handleNextStep = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  const handleBackStep = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  const handleLastStep = useCallback(() => {
    setStep(-1);
  }, []);

  const stepElements = useMemo(
    () => ({
      "-1": () => (
        <InformationReviewForm onClickBack={() => setStep(Object.keys(stepElements).length - 2)} />
      ),
      0: () => (
        <InheritorDetailsForm onClickBack={() => handleGoBack()} onClickNext={handleNextStep} />
      ),
      1: () => <DeceasedDetailsForm onClickBack={handleBackStep} onClickNext={handleNextStep} />,
      2: () => (
        <UploadDocumentForm
          fieldName="government-ids"
          uploadFormLabel="Upload Government ID"
          onClickBack={handleBackStep}
          onClickNext={handleNextStep}
          isRequired={true}
        />
      ),
      3: () => (
        <UploadDocumentForm
          fieldName="death-certificates"
          uploadFormLabel="Upload Death Certificate"
          onClickBack={handleBackStep}
          onClickNext={handleNextStep}
          isRequired={true}
        />
      ),
      4: () => (
        <UploadDocumentForm
          fieldName="wills"
          uploadFormLabel="Upload additional documents"
          onClickBack={handleBackStep}
          onClickNext={handleLastStep}
        />
      ),
    }),
    [handleBackStep, handleGoBack, handleLastStep, handleNextStep]
  );

  const CurrentElement = stepElements[+step as keyof typeof stepElements];

  return (
    <Stack position="relative" w="full" alignItems="flex-start" gap={12} p={9} pt={12}>
      <Stepper
        steps={adminNotificationSteps}
        currentStepIndex={step}
        stepperWrapperProps={{ p: "0 0 12px" }}
      />
      <Flex id="add-inheritor-flow" w="full" gap={7} flexDirection="column">
        <CurrentElement />
      </Flex>
    </Stack>
  );
}
