import { HStack, VStack, Heading, Text, Tag, TagLabel, CardBody } from "@chakra-ui/react";
import { Button } from "@shared/components/Button/Button.component";
import { ACCOUNT_TYPES_SELECT_OPTIONS } from "@shared/config/constants.config";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { CardWrapper } from "@src/components/Layout/Card/Card.component";
import { Campaign } from "@src/store/campaign.store";
import { useInstitutionStore } from "@src/store/institution.store";
import {
  MdDateRange,
  MdOutlineBadge,
  MdOutlineGroup,
  MdOutlineMonetizationOn,
  MdRemove,
} from "react-icons/md";

type DivProps = JSX.IntrinsicElements["div"];
export interface CampaignCardProps extends DivProps {
  campaign: Campaign;
}

export function CampaignCardComponent({ campaign, ...rest }: CampaignCardProps) {
  const { navigate } = useNavigation();
  const { currentInstitution } = useInstitutionStore();

  function stringDateRange(startDate: Date, endDate: Date | null) {
    return `${new Date(startDate).toLocaleDateString()} - ${endDate ? new Date(endDate).toLocaleDateString() : "Indefinitely"}`;
  }

  function formatAmount(amount: number) {
    // from 1000000 to 1M
    if (amount >= 1000000) {
      return `${amount / 1000000}M`;
    }
    // from 1000 to 1k
    if (amount >= 1000) {
      return `${amount / 1000}k`;
    }
    return amount;
  }

  function onClickCard() {
    navigate(`/campaigns/${campaign.id}`);
  }

  return (
    <CardWrapper
      role="group"
      p="16px"
      w="244px"
      cursor="pointer"
      _hover={{ boxShadow: "xs" }}
      boxShadow="base"
      onClick={onClickCard}
      {...rest}
    >
      <CardBody p="0px" gap="16px" display="flex" flexDir="column" alignItems="flex-start">
        <Heading size="sm" color="black" fontSize="14px" fontWeight="600" lineHeight="18px">
          {campaign.title}
        </Heading>

        {/* Campaign filters */}
        <VStack gap="12px" alignItems="flex-start">
          {/* Campaign filter: members */}
          <HStack
            color="gray.500"
            fontSize="12px"
            fontWeight="400"
            lineHeight="16px"
            gap="6px"
            align="flex-start"
          >
            {/* Campaign filter label */}
            <HStack gap="4px" pt="3px">
              <MdOutlineGroup size="16px" />
              <Text color="gray.500">
                {currentInstitution && currentInstitution.type === "bank" ? "customers" : "members"}
              </Text>
            </HStack>

            {/* Campaign filter values */}
            {campaign.members && (
              <HStack gap="2px">
                <Tag
                  border="1px solid var(--chakra-colors-gray-300)"
                  boxShadow="none"
                  variant="outline"
                  px="8px"
                  py="0"
                >
                  <TagLabel fontSize="12px">{campaign.members}</TagLabel>
                </Tag>
              </HStack>
            )}
          </HStack>

          {/* Campaign filter: amount */}
          <HStack
            color="gray.500"
            fontSize="12px"
            fontWeight="400"
            lineHeight="16px"
            gap="6px"
            align="flex-start"
          >
            {/* Campaign filter label */}
            <HStack gap="4px" pt="3px">
              <MdOutlineMonetizationOn size="16px" />
              <Text color="gray.500">amount</Text>
            </HStack>

            {/* Campaign filter values */}
            <HStack gap="2px" divider={<MdRemove size="12px" color="gray.300" />}>
              <Tag
                border="1px solid var(--chakra-colors-gray-300)"
                boxShadow="none"
                variant="outline"
                px="8px"
                py="0"
              >
                <TagLabel fontSize="12px">
                  {campaign.minAmount ? formatAmount(campaign.minAmount) : 0}
                </TagLabel>
              </Tag>

              <Tag
                border="1px solid var(--chakra-colors-gray-300)"
                boxShadow="none"
                variant="outline"
                px="8px"
                py="0"
              >
                <TagLabel fontSize="12px">
                  {campaign.maxAmount ? formatAmount(campaign.maxAmount) : "∞"}
                </TagLabel>
              </Tag>
            </HStack>
          </HStack>

          {/* Campaign filter: acc.type */}
          <HStack
            color="gray.500"
            fontSize="12px"
            fontWeight="400"
            lineHeight="16px"
            gap="6px"
            align="flex-start"
          >
            {/* Campaign filter label */}
            <HStack gap="4px" pt="3px">
              <MdOutlineBadge size="16px" />
              <Text color="gray.500">acc.type</Text>
            </HStack>

            {/* Campaign filter values */}
            <HStack gap="2px" wrap="wrap">
              {campaign.accountTypes.map(accountType => {
                const label =
                  accountType === "all"
                    ? "all"
                    : ACCOUNT_TYPES_SELECT_OPTIONS.find(option => option.value === accountType)
                        ?.label;
                if (!label) return null;
                return (
                  <Tag
                    border="1px solid var(--chakra-colors-gray-300)"
                    boxShadow="none"
                    variant="outline"
                    px="8px"
                    py="0"
                  >
                    <TagLabel fontSize="12px">{label}</TagLabel>
                  </Tag>
                );
              })}
            </HStack>
          </HStack>

          {/* Campaign filter: date */}
          <HStack
            color="gray.500"
            fontSize="12px"
            fontWeight="400"
            lineHeight="16px"
            gap="6px"
            align="center"
          >
            {/* Campaign filter label */}
            <HStack gap="4px">
              <MdDateRange size="16px" />
            </HStack>

            {/* Campaign filter values */}
            <HStack gap="2px">
              <Text as="span" fontSize="12px" lineHeight="16px" color="gray.500">
                {stringDateRange(campaign.startDate, campaign.endDate)}
              </Text>
            </HStack>
          </HStack>
        </VStack>

        <Button
          size="xs"
          variant="whiteLabelOutline"
          _groupHover={{ bg: "whiteLabel.primary", color: "white" }}
        >
          View more
        </Button>
      </CardBody>
    </CardWrapper>
  );
}
