import { HStack, Heading, Stack } from "@chakra-ui/react";
import { Embed } from "@components/Embed/Embed.component";

export function EmbedTemplate() {
  return (
    <Stack position="relative" h="full" w="full" alignItems="flex-start" spacing={8} p={8}>
      <HStack w="full" justifyContent="space-between">
        <Heading variant="h1" size={{ base: "lg", md: "lg" }}>
          Embeddables
        </Heading>
      </HStack>
      <Embed />
    </Stack>
  );
}
