import { Grid, GridItem, Text } from "@chakra-ui/react";
import { InheritorStore } from "@shared/config/schema.validator";

type InheritorReviewProps = {
  inheritorInfo: InheritorStore | null;
  isMobile: boolean;
};

export function InheritorReview({ inheritorInfo, isMobile }: Readonly<InheritorReviewProps>) {
  return (
    <Grid
      templateColumns={{
        base: "repeat(auto-fill, minmax(250px, 1fr))",
        xl: "repeat(4, minmax(250px, 1fr))",
      }}
      gap={4}
      width="100%"
    >
      <GridItem>
        <Text fontSize="14px" fontWeight={500} lineHeight="24px" color="#718096">
          First Name:
        </Text>
        <Text
          datatest-id="review-inheritor-first-name"
          fontSize="16px"
          fontWeight={600}
          lineHeight="24px"
          color="#171923"
        >
          {inheritorInfo?.firstName}
        </Text>
      </GridItem>
      <GridItem>
        <Text fontSize="14px" fontWeight={500} lineHeight="24px" color="#718096">
          Middle Name:
        </Text>
        <Text
          datatest-id="review-inheritor-middle-name"
          fontSize="16px"
          fontWeight={600}
          lineHeight="24px"
          color="#171923"
        >
          {inheritorInfo?.middleName}
        </Text>
      </GridItem>
      <GridItem>
        <Text fontSize="14px" fontWeight={500} lineHeight="24px" color="#718096">
          Last Name:
        </Text>
        <Text
          datatest-id="review-inheritor-last-name"
          fontSize="16px"
          fontWeight={600}
          lineHeight="24px"
          color="#171923"
        >
          {inheritorInfo?.lastName}
        </Text>
      </GridItem>
      <GridItem>
        <Text fontSize="14px" fontWeight={500} lineHeight="24px" color="#718096">
          {isMobile ? "SSN" : "Social Security Number"}:
        </Text>
        <Text
          datatest-id="review-inheritor-ssn"
          fontSize="16px"
          fontWeight={600}
          lineHeight="24px"
          color="#171923"
          whiteSpace="nowrap"
        >
          {`XXX-XX-${inheritorInfo?.ssn?.slice(0, 4)}`}
        </Text>
      </GridItem>
      <GridItem>
        <Text fontSize="14px" fontWeight={500} lineHeight="24px" color="#718096">
          Email:
        </Text>
        <Text
          datatest-id="review-inheritor-email"
          fontSize="16px"
          fontWeight={600}
          lineHeight="24px"
          color="#171923"
        >
          {inheritorInfo?.email}
        </Text>
      </GridItem>
      <GridItem>
        <Text fontSize="14px" fontWeight={500} lineHeight="24px" color="#718096">
          Phone Number:
        </Text>
        <Text
          datatest-id="review-inheritor-phone-number"
          fontSize="16px"
          fontWeight={600}
          lineHeight="24px"
          color="#171923"
        >
          {inheritorInfo?.phoneNumber}
        </Text>
      </GridItem>
    </Grid>
  );
}
