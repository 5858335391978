import * as S from "@sentry/react";

export const Sentry = {
  init: () => {
    return S.init({
      dsn: import.meta.env.VITE_SENTRY_DSN_ADMIN,
      environment: import.meta.env.VITE_ENV,
      integrations: [
        S.browserTracingIntegration(),
        S.replayIntegration({
          maskAllText: true,
          blockAllMedia: false,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0,
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        import.meta.env.VITE_APP_ADMIN_API_URL,
        import.meta.env.VITE_APP_API_URL,
      ],
      // Session Replay
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
    });
  },
};
