import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import {
  DeceasedPersonDual,
  InheritanceCaseStatus,
  InheritorWithoutSsn,
} from "@trustribbon/ribbon-ec-types";

export type DeceasedPersonWithCaseId = DeceasedPersonDual<"client"> & {
  caseId: string;
  caseStatus: InheritanceCaseStatus;
};

interface InheritorStore {
  currentInheritor: InheritorWithoutSsn | null;
  currentDeceased: DeceasedPersonWithCaseId | null;
  isInheritor: boolean;
  setCurrentInheritor: (inheritor: InheritorWithoutSsn) => void;
  setCurrentDeceased: (deceased: DeceasedPersonWithCaseId) => void;
  setIsInheritor: (isInheritor: boolean) => void;
  clearPersistedStore: () => void;
}

export const useInheritorStore = create(
  devtools(
    persist<InheritorStore>(
      set => ({
        currentInheritor: null,
        currentDeceased: null,
        isInheritor: false,
        setCurrentInheritor: inheritor =>
          set(state => ({ currentInheritor: { ...state.currentInheritor, ...inheritor } })),
        setCurrentDeceased: deceased =>
          set(state => ({ currentDeceased: { ...state.currentDeceased, ...deceased } })),
        setIsInheritor: isInheritor => set({ isInheritor }),
        clearPersistedStore: () => {
          set({ currentInheritor: null, currentDeceased: null, isInheritor: false });
          useInheritorStore.persist.clearStorage();
        },
      }),
      {
        name: "inheritorStore-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    {
      enabled: process.env.NODE_ENV === "development",
      anonymousActionType: "set inheritors",
      store: "inheritorStore",
      name: "Inheritors",
    }
  )
);
