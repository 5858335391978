import { ReactNode } from "react";
import { ButtonProps, HStack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { MdDeleteOutline, MdOutlineCancel, MdOutlineEditNote, MdOutlineSave } from "react-icons/md";
import { USER_ROLES } from "@config/constants.config";
import { usePermission } from "@hooks/usePermission.hook";
import { Button } from "@shared/components/Button/Button.component";

type EditButtonsProps = {
  defaultValues: Record<string, unknown>;
  isEditing: boolean;
  onEdit: () => void;
  onSave: () => void;
  onCancel: () => void;
  onDelete?: () => void;
  isLoading?: boolean;
  extraButton?: ReactNode;
  onEditButtonProps?: ButtonProps;
  onAcceptButtonProps?: ButtonProps;
  onDeleteButtonProps?: ButtonProps;
  onCancelButtonProps?: ButtonProps;
  onEditButtonText?: string;
  onAcceptButtonText?: string;
  onDeleteButtonText?: string;
  onCancelButtonText?: string;
};

export function EditButtons({
  defaultValues,
  isEditing,
  onEdit,
  onSave,
  onCancel,
  onDelete,
  isLoading,
  extraButton,
  onEditButtonProps,
  onDeleteButtonProps,
  onAcceptButtonProps,
  onCancelButtonProps,
  onEditButtonText,
  onAcceptButtonText,
  onDeleteButtonText,
  onCancelButtonText,
}: Readonly<EditButtonsProps>) {
  const { reset } = useFormContext();
  const { isPermitted } = usePermission();

  const handleCancel = () => {
    reset(defaultValues);

    onCancel();
  };

  const hasEditPermission = isPermitted([USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_EDITOR]);
  const hasDeletePermission = isPermitted([USER_ROLES.ORG_ADMIN]);
  const hasSavePermission = isPermitted([USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_EDITOR]);

  return isEditing ? (
    <HStack flexWrap="wrap">
      <Button
        leftIcon={<MdOutlineCancel />}
        variant="outline"
        borderRadius="6px"
        colorScheme="gray.400"
        isLoading={isLoading}
        onClick={handleCancel}
        _hover={{
          bg: "gray.200",
          color: "black",
          border: "1px solid black",
        }}
        {...onCancelButtonProps}
      >
        {onCancelButtonText ?? "Cancel"}
      </Button>
      {onDelete ? (
        <Button
          bg="#E53E3E"
          color="#ffffff"
          borderColor="transparent"
          _hover={{ filter: "brightness(0.9)" }}
          leftIcon={<MdDeleteOutline />}
          variant="whiteLabelOutline"
          onClick={onDelete}
          isLoading={isLoading}
          {...(onDeleteButtonProps?.isDisabled && {
            _hover: {},
          })}
          {...onDeleteButtonProps}
          display={hasDeletePermission ? "flex" : "none"}
        >
          {onDeleteButtonText ?? "Delete"}
        </Button>
      ) : null}
      <Button
        leftIcon={<MdOutlineSave />}
        variant="whiteLabel"
        onClick={onSave}
        isLoading={isLoading}
        {...onAcceptButtonProps}
        display={hasSavePermission ? "flex" : "none"}
        _hover={{
          "&:not(:disabled)": {
            color: "#ffffff",
            bg: "whiteLabel.secondary",
          },
        }}
      >
        {onAcceptButtonText ?? "Save"}
      </Button>
      {extraButton}
    </HStack>
  ) : (
    <Button
      leftIcon={<MdOutlineEditNote />}
      variant="whiteLabelOutline"
      onClick={onEdit}
      isLoading={isLoading}
      _loading={{
        "&:hover": {
          backgroundColor: "whiteLabel.primary",
        },
      }}
      {...onEditButtonProps}
      display={hasEditPermission ? "flex" : "none"}
    >
      {onEditButtonText ?? "Edit"}
    </Button>
  );
}
