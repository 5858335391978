import { Badge, Flex, HStack, Heading, Stack, StackDivider, Text, VStack } from "@chakra-ui/react";
import { FiUpload } from "react-icons/fi";
import { BsBank } from "react-icons/bs";
import { Button } from "@shared/components/Button/Button.component";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { CardWrapper } from "@components/Layout/Card/Card.component";

export function FindAccountsUserViewTemplate() {
  const isMobile = useIsMobile();

  const findAccountsData = [
    {
      id: "0",
      bankName: "Bank of America",
      accountOwner: "Mark Geller",
      amount: "$100,000",
    },
    {
      id: "1",
      bankName: "Chase",
      accountOwner: "Mark Geller",
      amount: "$200,000",
    },
    {
      id: "2",
      bankName: "Wells Fargo",
      accountOwner: "Mark Geller",
      amount: "$300,000",
    },
  ];

  return (
    <Stack position="relative" h="full" w="full" alignItems="flex-start" gap={12} p={9} pt={12}>
      <VStack w="full" gap={7}>
        <HStack w="full" justifyContent="space-between" alignItems="flex-start">
          <Heading variant="h1" fontSize="24px" color="gray.700" lineHeight="36px" fontWeight={700}>
            Inheritors
          </Heading>
          <Flex gap={4} justifyContent="flex-end" flexWrap="wrap">
            <Button leftIcon={<FiUpload />} variant="whiteLabel" width={isMobile ? "100%" : "auto"}>
              Upload
            </Button>
          </Flex>
        </HStack>

        <CardWrapper>
          <Stack
            w="full"
            justifyContent="space-between"
            gap={4}
            flexDirection={isMobile ? "column" : "row"}
            padding="14px 24px 0"
          >
            <VStack alignItems="flex-start" width={isMobile ? "100%" : "25%"}>
              <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                NAME
              </Text>
              <Text fontSize={14} color="#171923" fontWeight={500}>
                Mark Geller
              </Text>
            </VStack>
            <VStack alignItems="flex-start" width={isMobile ? "100%" : "25%"}>
              <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                MEMBERSHIP
              </Text>
              <Badge colorScheme="green" textTransform="none" fontWeight={700} fontSize={14}>
                Member
              </Badge>
            </VStack>
            <VStack alignItems="flex-start" width={isMobile ? "100%" : "25%"}>
              <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                EMAIL
              </Text>
              <Text fontSize={14} color="#171923" fontWeight={400} lineHeight="20px">
                mark.geller@gmail.com
              </Text>
            </VStack>
            <VStack alignItems="flex-start" width={isMobile ? "100%" : "25%"}>
              <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                PHONE NUMBER
              </Text>
              <Text fontSize={14} color="#171923" fontWeight={500} lineHeight="20px">
                +15094285366
              </Text>
            </VStack>
          </Stack>

          <Stack
            w="full"
            justifyContent="space-between"
            gap={4}
            flexDirection={isMobile ? "column" : "row"}
            padding="0 24px 16px"
          >
            <VStack alignItems="flex-start" width={isMobile ? "100%" : "25%"}>
              <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                STREET ADDRESS
              </Text>
              <Text fontSize={14} color="#171923" fontWeight={400} lineHeight="20px">
                650 Whitney Ranch DR
              </Text>
            </VStack>
            <VStack alignItems="flex-start" width={isMobile ? "100%" : "25%"}>
              <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                CITY
              </Text>
              <Text fontSize={14} color="#171923" fontWeight={400} lineHeight="20px">
                Henderson
              </Text>
            </VStack>
            <VStack alignItems="flex-start" width={isMobile ? "100%" : "25%"}>
              <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                STATE
              </Text>
              <Text fontSize={14} color="#171923" fontWeight={400} lineHeight="20px">
                NV
              </Text>
            </VStack>
            <VStack alignItems="flex-start" width={isMobile ? "100%" : "25%"}>
              <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                ZIP CODE
              </Text>
              <Text fontSize={14} color="#171923" fontWeight={500} lineHeight="20px">
                89014
              </Text>
            </VStack>
          </Stack>
        </CardWrapper>
      </VStack>

      <VStack w="full" gap={7}>
        <HStack w="full" justifyContent="space-between" alignItems="flex-start">
          <Heading variant="h1" fontSize="24px" color="gray.700" lineHeight="40px" fontWeight={700}>
            Potential assets we found for Mark Geller
          </Heading>
        </HStack>

        <CardWrapper>
          {isMobile ? (
            <Stack
              w="full"
              justifyContent="space-around"
              gap={4}
              flexDirection="column"
              padding="14px 24px 0"
              divider={<StackDivider />}
            >
              {findAccountsData.map((account, index, accountsArray) => {
                const isLastItem = index === accountsArray.length - 1;

                return (
                  <Stack
                    key={account.id}
                    w="full"
                    justifyContent="flex-start"
                    gap={4}
                    flexDirection="column"
                    pb={isLastItem ? "12px" : 0}
                  >
                    <VStack width="100%" alignItems="flex-start" gap={2}>
                      <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                        INSTITUTION
                      </Text>
                      <HStack alignItems="center" gap={2}>
                        <BsBank size={20} color="#171923" />
                        <Text fontSize={14} color="#171923" fontWeight={500}>
                          {account.bankName}
                        </Text>
                      </HStack>
                    </VStack>
                    <VStack width="100%" alignItems="flex-start" gap={2}>
                      <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                        ACCOUNT OWNER
                      </Text>
                      <Text fontSize={14} color="#171923" fontWeight={500}>
                        {account.accountOwner}
                      </Text>
                    </VStack>
                    <VStack width="100%" alignItems="flex-start" gap={2}>
                      <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                        AMOUNT
                      </Text>
                      <Text fontSize={14} color="#171923" fontWeight={500}>
                        {account.amount}
                      </Text>
                    </VStack>
                  </Stack>
                );
              })}
            </Stack>
          ) : (
            <Stack
              w="full"
              justifyContent="space-around"
              gap={4}
              flexDirection="column"
              padding="14px 10px 0"
              divider={<StackDivider m="0 !important" />}
            >
              <HStack w="full" justifyContent="space-between" alignItems="flex-start" px="24px">
                <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px" width="45%">
                  INSTITUTION
                </Text>
                <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px" width="45%">
                  ACCOUNT OWNER
                </Text>
                <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px" width="10%">
                  AMOUNT
                </Text>
              </HStack>
              {findAccountsData.map((account, index, accountsArray) => {
                const isLastItem = index === accountsArray.length - 1;

                return (
                  <HStack
                    key={index}
                    w="full"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    px="24px"
                    pb={isLastItem ? "12px" : 0}
                  >
                    <HStack alignItems="center" gap={2} width="45%">
                      <BsBank size={20} color="#171923" />
                      <Text fontSize={14} color="#171923" fontWeight={500}>
                        {account.bankName}
                      </Text>
                    </HStack>
                    <Text fontSize={14} color="#171923" fontWeight={500} width="45%">
                      {account.accountOwner}
                    </Text>
                    <Text fontSize={14} color="#171923" fontWeight={500} width="10%">
                      {account.amount}
                    </Text>
                  </HStack>
                );
              })}
            </Stack>
          )}
        </CardWrapper>
      </VStack>
    </Stack>
  );
}
