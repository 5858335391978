import { captureException } from "@sentry/react";
import { UploadDocumentRequest, UploadDocumentResponse } from "@trustribbon/ribbon-ec-types";
import { CONSTANTS } from "@config/constants.config";
import { cleanFilename } from "@shared/utils/string.utils";
import { getAccessToken } from "./authenticate.service";
import { postMethod, putMethod } from "./base.service";

export const getUploadUrl = async ({
  filename,
  ...requestData
}: UploadDocumentRequest): Promise<string> => {
  const cleanedName = cleanFilename(filename);

  try {
    const accessToken = await getAccessToken();

    const data = await postMethod<UploadDocumentRequest, UploadDocumentResponse>(
      `${CONSTANTS.VITE_APP_API_URL}/documents/upload-document`,
      { ...requestData, filename: cleanedName },
      {
        headers: accessToken
          ? {
              Authorization: accessToken,
            }
          : undefined,
      }
    );

    return data.url;
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const uploadDocument = async (uploadUrl: string, file: File): Promise<void> => {
  try {
    await putMethod<File, null>(uploadUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
  } catch (error) {
    captureException(error);
    throw error;
  }
};
