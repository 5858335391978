import { captureException } from "@sentry/react";
import {
  CreateInheritanceCaseRequest,
  CreateInheritanceCaseResponse,
} from "@trustribbon/ribbon-ec-types";
import { CONSTANTS } from "@config/constants.config";
import { cleanNumberString } from "@shared/utils/string.utils";
import { getAccessToken } from "./authenticate.service";
import { postMethod } from "./base.service";

export const adminNotifyInfo = async (
  notificationInfo: CreateInheritanceCaseRequest
): Promise<CreateInheritanceCaseResponse> => {
  try {
    const notifyInfoData: CreateInheritanceCaseRequest = {
      inheritor: {
        phoneNumber: cleanNumberString(notificationInfo.inheritor.phoneNumber),
        ssn: cleanNumberString(notificationInfo.inheritor.ssn),
        member: notificationInfo.inheritor.member,
        firstName: notificationInfo.inheritor.firstName,
        middleName: notificationInfo.inheritor.middleName,
        lastName: notificationInfo.inheritor.lastName,
        email: notificationInfo.inheritor.email,
      },
      deceasedPerson: {
        lastName: notificationInfo.deceasedPerson.lastName,
        ssn: cleanNumberString(notificationInfo.deceasedPerson.ssn),
        dateOfBirth: notificationInfo.deceasedPerson.dateOfBirth,
        firstName: notificationInfo.deceasedPerson.firstName,
        middleName: notificationInfo.deceasedPerson.middleName,
      },
    };

    const accessToken = await getAccessToken();

    const data = await postMethod<CreateInheritanceCaseRequest, CreateInheritanceCaseResponse>(
      `${CONSTANTS.VITE_APP_ADMIN_API_URL}/inheritance-cases`,
      notifyInfoData,
      {
        headers: accessToken
          ? {
              Authorization: accessToken,
            }
          : undefined,
      }
    );

    return data;
  } catch (error) {
    captureException(error);
    throw error;
  }
};
