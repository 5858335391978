import { useEffect, useState } from "react";
import { Divider, Flex, HStack, Heading, Stack, Text, VStack } from "@chakra-ui/react";
import { IoShieldCheckmarkSharp } from "react-icons/io5";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useInheritorStore } from "@store/inheritor.store";
import { useRetrieveSSN } from "@hooks/useRetrieveSSN.hook";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { EditableInput } from "@shared/components/FormComponents/EditableInput.component";
import { cleanNumberString } from "@shared/utils/string.utils";
import { TextWithLoading } from "@shared/components/TextWithLoading/TextWithLoading.component";
import { patchSSN } from "@services/inheritor.service";
import { ssnMask } from "@shared/config/masks.config";
import { CardWrapper } from "@components/Layout/Card/Card.component";
import { EditButtons } from "../InheritorsView/components/EditButtons/EditButtons.component";

const getDefaultValues = (ssn?: string | null) => {
  return {
    ssn: ssn ?? "",
  };
};

export function InheritorsSSNViewTemplate() {
  const isMobile = useIsMobile();
  const { currentInheritor, currentDeceased, isInheritor } = useInheritorStore();
  const [internalLoading, setInternalLoading] = useState(false);
  const [error, setError] = useState({ message: "" });
  const [isEditing, setIsEditing] = useState(false);

  const personId = isInheritor ? currentInheritor?.id : currentDeceased?.id;

  const { ssn, isLoading, mutate } = useRetrieveSSN(personId ?? "", isInheritor as boolean);

  const defaultValues = getDefaultValues(ssn);

  const formMethods = useForm<typeof defaultValues>({
    defaultValues,
  });

  const { control, reset, formState, handleSubmit } = formMethods;
  const isDirty = formState.isDirty;

  useEffect(() => {
    reset({
      ssn: ssn ?? "",
    });
  }, [ssn, reset]);

  const handleEditing = () => {
    setIsEditing(!isEditing);
  };

  const onSubmit: SubmitHandler<typeof defaultValues> = async data => {
    setIsEditing(false);

    if (isDirty) {
      setInternalLoading(true);

      try {
        await patchSSN(
          isInheritor ? `/inheritors/${personId}/ssn` : `/deceased-persons/${personId}/ssn`,
          { ssn: cleanNumberString(data.ssn) }
        );
      } catch {
        setError({ message: "An error occurred while updating the Social Security Number." });
      }

      mutate();

      setInternalLoading(false);
    }
  };

  const isComponentLoading = internalLoading || isLoading;

  return (
    <FormProvider {...formMethods} control={control}>
      <Stack
        as="form"
        id="ssn-view-form"
        position="relative"
        h="full"
        w="full"
        alignItems="flex-start"
        gap={7}
        p={9}
        pt={12}
      >
        <Flex
          w="100%"
          bg="#BEE3F8"
          borderRadius="xl"
          height="72px"
          alignItems="center"
          padding="12px 16px"
          gap={3}
          marginBottom="20px"
        >
          <IoShieldCheckmarkSharp size={24} fill="#3182CE" />
          <VStack alignItems="start" justifyContent="center" gap={0}>
            <Text fontSize={16} color="#2D3748" fontWeight={700} lineHeight="24px">
              Your are now in safe mode
            </Text>
            <Text fontSize={16} color="#2D3748" fontWeight={400} lineHeight="24px">
              When preview Social Security Number (SSN), you account is automatically set to safety
              mode, to prevent any suspicious activity.
            </Text>
          </VStack>
        </Flex>
        <VStack w="full" gap={7}>
          <HStack w="full" justifyContent="space-between">
            <Heading variant="h1" fontSize={24} color="gray.700" lineHeight="36px" fontWeight={600}>
              Social Security Number Preview
            </Heading>
            <EditButtons
              defaultValues={defaultValues}
              onEdit={handleEditing}
              onSave={handleSubmit(onSubmit)}
              onCancel={() => setIsEditing(false)}
              isEditing={isEditing}
              isLoading={isComponentLoading}
            />
          </HStack>
        </VStack>

        <CardWrapper>
          <Stack flexDirection={isMobile ? "column" : "row"} padding="14px 24px 0">
            <VStack alignItems="flex-start" width={isMobile ? "100%" : "25%"} gap={2}>
              <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                {isInheritor ? "INHERITOR NAME" : "DECEASED NAME"}
              </Text>
              <TextWithLoading
                fontSize={18}
                fontWeight={700}
                lineHeight="28px"
                color="#2D3748"
                isLoading={isComponentLoading}
              >
                {isInheritor
                  ? `${currentInheritor?.firstName} ${currentInheritor?.lastName}`
                  : `${currentDeceased?.firstName} ${currentDeceased?.lastName}`}
              </TextWithLoading>
            </VStack>
          </Stack>

          <Stack flexDirection={isMobile ? "column" : "row"} padding="0 24px 16px">
            <VStack alignItems="flex-start" width={isMobile ? "100%" : "25%"} gap={2}>
              <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                SOCIAL SECURITY NUMBER - SSN
              </Text>
              <HStack w="100%">
                <EditableInput
                  aria-label="SSN"
                  name="ssn"
                  type={isEditing ? "password" : "text"}
                  fontSize={18}
                  fontWeight={700}
                  lineHeight="28px"
                  color="#2D3748"
                  w="full"
                  isEditing={isEditing}
                  isLoading={isComponentLoading}
                  mask={ssnMask}
                  transition="filter 0.1s ease-in-out"
                  registerOptions={{ required: "SSN is required." }}
                />
              </HStack>
            </VStack>
          </Stack>
        </CardWrapper>
        <Divider color="#CBD5E0" />
        {error?.message && (
          <Flex gap={4} direction="column">
            <HStack gap={6} align="flex-start" direction="row" width="100%" flexWrap="wrap">
              <Text fontSize="16px" fontWeight={600} lineHeight="24px" color="red">
                {error.message || "An error occurred while updating the Social Security Number."}
              </Text>
            </HStack>
          </Flex>
        )}
      </Stack>
    </FormProvider>
  );
}
