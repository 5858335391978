import { defineStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const Text = defineStyleConfig({
  // Styles for the base style
  baseStyle: {
    fontFamily: `'Clear Sans', sans-serif`,
    color: mode("blackAlpha.900", "white"),
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    body: {
      fontSize: "lg",
      fontWeight: 400,
    },
  },
  // The default `size` or `variant` values
  defaultProps: {},
});

export const textStyles = {
  components: {
    Text,
  },
};
