import { Stack } from "@chakra-ui/react";
import { InheritorOverview } from "./InheritorOverview.component";

export function InheritorsViewTemplate() {
  return (
    <Stack position="relative" w="full" alignItems="flex-start" gap={3} p={9} pt={12}>
      <InheritorOverview />
    </Stack>
  );
}
