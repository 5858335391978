import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Image } from "@chakra-ui/react";
import { ROUTES } from "@config/constants.config";

export function Logo() {
  const { user } = useAuth0();

  return (
    <Link aria-label="Navigate to Inheritors" to={ROUTES.INHERITORS} title="RIBBON INHERITORS">
      <Image
        src={
          user?.org_metadata?.logo || "https://static.trustribbon.com/assets/ribbon-financial.png"
        }
        alt="Ribbon EC"
        width={{ base: 80, md: 150 }}
      />
    </Link>
  );
}
