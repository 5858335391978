import { defineStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const Heading = defineStyleConfig({
  // Styles for the base style
  baseStyle: {
    fontFamily: `'Work Sans Variable', sans-serif`,
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    h1: {
      fontWeight: 500,
      color: mode("#171923", "white"),
    },
    balance: {
      fontWeight: 700,
    },
  },
  // The default `size` or `variant` values
  defaultProps: {},
});

export const headingStyles = {
  components: {
    Heading,
  },
};
