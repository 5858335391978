/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStyleConfig } from "@chakra-ui/react";

const Form = defineStyleConfig({
  // Styles for the base style
  baseStyle: {},
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    floating: {
      container: {
        _focusWithin: {
          label: {
            top: 0,
            fontSize: "12px",
            color: "#000",
          },
        },
        "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
          {
            top: 0,
            fontSize: "12px",
          },
        label: {
          transition: "top 0.2s ease, transform 0.2s ease, color 0.2s ease, font-size 0.2s ease",
          zIndex: 2,
          position: "absolute",
          height: "min-content",
          border: "none",
          boxShadow: "none",
          color: "#718096",
          left: "1rem",
          top: "50%",
          fontSize: "16px",
          transform: "translateY(-50%)",
          margin: 0,
        },
      } as any,
    },
  },
  // The default `size` or `variant` values
  defaultProps: {},
});

const FormError = defineStyleConfig({
  baseStyle: () => ({
    text: {
      fontFamily: `'Poppins', sans-serif`,
    },
  }),
});

export const formStyles = {
  components: {
    Form,
    FormError,
  },
};
