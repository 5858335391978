import { captureException } from "@sentry/react";
import { CONSTANTS } from "@config/constants.config";
import { GetAgentRolesResponse } from "@trustribbon/ribbon-ec-types";
import { AuthenticationModule } from "./authenticate.service";
import { getMethod } from "./base.service";

export const fetchAgentRoles = async (endpoint: string): Promise<GetAgentRolesResponse> => {
  try {
    const accessToken = await AuthenticationModule.getAccessTokenSilently();

    const data = await getMethod<GetAgentRolesResponse>(
      `${CONSTANTS.VITE_APP_ADMIN_API_URL}/${endpoint}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  } catch (error) {
    captureException(error);
    throw error;
  }
};
