/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Badge,
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { CellContext, Row, createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "@shared/components/DataTable/DataTable.component";
import { Button } from "@shared/components/Button/Button.component";
import { FiUpload } from "react-icons/fi";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { useCallback } from "react";
import { ROUTES } from "@src/config/constants.config";

const columnHelper = createColumnHelper<any>();

const findAccountsCellComponent = (info: CellContext<any, string>) => (
  <Text fontSize={14} fontWeight={500} lineHeight="20px">
    {info.getValue()}
  </Text>
);

const findAccountsComponent = (info: CellContext<any, boolean>) => (
  <Badge
    colorScheme={info.getValue() ? "green" : "red"}
    textTransform="none"
    fontWeight={700}
    fontSize={14}
    lineHeight="16px"
  >
    {info.getValue() ? "Member" : "Not a member"}
  </Badge>
);

const columns = [
  columnHelper.accessor("name", {
    cell: findAccountsCellComponent,
    header: "NAME",
  }),
  columnHelper.accessor("member", {
    cell: findAccountsComponent,
    header: "MEMBERSHIP",
  }),
  columnHelper.accessor("source", {
    cell: info => (
      <Badge
        colorScheme={info.getValue() === "source" ? "gray" : "yellow"}
        textTransform="none"
        fontWeight={500}
        fontSize={12}
        lineHeight="16px"
        borderRadius="6px"
      >
        .{info.getValue()}
      </Badge>
    ),
    header: "source",
  }),
  columnHelper.accessor("email", {
    cell: info => (
      <Text fontSize={14} fontWeight={400} lineHeight="20px">
        {info.getValue()}
      </Text>
    ),
    header: "EMAIL",
  }),
  columnHelper.accessor("amount", {
    cell: info => {
      const [from, to] = info.getValue();
      return (
        <Text fontSize={14} fontWeight={600} lineHeight="20px">
          ${from}
          <Text as="span" fontSize={12} fontWeight={400} lineHeight="16px">
            {" "}
            up to{" "}
          </Text>
          ${to}
        </Text>
      );
    },
    header: "AMOUNT",
  }),
];

const foundAccountFakeData = [
  {
    id: "1287281721-sasokj12981-x2198s9as-12817281",
    name: "John Doe",
    member: true,
    source: "source",
    email: "johndoe@gmail.com",
    amount: ["1000", "2000"],
  },
  {
    id: "1287281721-sasokj12981-x2198s9as-12817282",
    name: "Rita Doe",
    member: true,
    source: "csv",
    email: "rita.doe@gmail.com",
    amount: ["2000", "3000"],
  },
  {
    id: "1287281721-sasokj12981-x2198s9as-12817283",
    name: "Mike Tyson",
    member: true,
    source: "csv",
    email: "mike@hotmail.com",
    amount: ["1000", "5000"],
  },
];

export function FindAccountsTemplate() {
  const { navigate } = useNavigation();
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const handleRowClick = useCallback(
    (row: Row<any>) => {
      console.log("row", row);

      navigate(`${ROUTES.FIND_ACCOUNTS}/${row.original.id}`);
    },
    [navigate]
  );

  const handleSearchInputChange = (value: string) => {
    console.log("value search", value);
  };

  return (
    <Stack position="relative" h="full" w="full" alignItems="flex-start" gap={12} p={9} pt={12}>
      <VStack w="full" gap={7}>
        <HStack w="full" justifyContent="space-between" alignItems="flex-start">
          <Heading variant="h1" fontSize="24px" color="gray.700" lineHeight="36px" fontWeight={700}>
            Find accounts
          </Heading>
          <Flex gap={4} justifyContent="flex-end" flexWrap="wrap">
            <Button
              variant="whiteLabel"
              width={isDesktop ? "auto" : "100%"}
              leftIcon={<FiUpload />}
            >
              Upload
            </Button>
          </Flex>
        </HStack>
      </VStack>
      <DataTable
        columns={columns}
        data={foundAccountFakeData}
        handleRowClick={handleRowClick}
        handleSearchInputChange={handleSearchInputChange}
      />
    </Stack>
  );
}
