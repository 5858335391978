export const ROUTES = {
  ROOT: "/",
  ONBOARDING: "/onboarding",
  INHERITORS: "/inheritors",
  INHERITORS_ADD: "/inheritors/add-inheritor",
  INHERITORS_VIEW: "/inheritors/detailed-view/:id",
  INHERITORS_SSN_PREVIEW: "/inheritors/detailed-view/:id/ssn",
  INHERITORS_DECEASED_SSN_PREVIEW: "/inheritors/deceased-view/:id/ssn",
  INHERITORS_DECEASEDS_VIEW: "/inheritors/deceased-view/:id",
  INSIGHTS: "/insights",
  INSIGHTS_VIEW: "/insights/detailed-view/:id",
  CAMPAIGNS: "/campaigns",
  CAMPAIGN_OVERVIEW: "/campaigns/:id",
  FIND_ACCOUNTS: "/find-accounts",
  FIND_ACCOUNTS_USER_VIEW: "/find-accounts/:id",
  SIGNIN: "/sign-in",
  SIGNUP_INVITATION: "/signup-invitation",
  EMBED: "/embed",
  UNAUTHORIZED: "/unauthorized",
};

export const CONSTANTS = Object.freeze({
  VITE_APP_API_URL: import.meta.env.VITE_APP_API_URL,
  VITE_APP_ADMIN_API_URL: import.meta.env.VITE_APP_ADMIN_API_URL,
  VITE_APP_BASE_DOMAIN: import.meta.env.VITE_APP_BASE_DOMAIN || "localhost:3000",
  VITE_APP_DEFAULT_FALLBACK_URL:
    import.meta.env.VITE_APP_DEFAULT_FALLBACK_URL || "https://www.trustribbon.com",
  VITE_APP_AUTH0_DOMAIN: import.meta.env.VITE_APP_AUTH0_DOMAIN,
  VITE_APP_AUTH0_AUDIENCE: import.meta.env.VITE_APP_AUTH0_AUDIENCE,
  VITE_APP_AUTH0_CLIENT_ID: import.meta.env.VITE_APP_AUTH0_CLIENT_ID,
  VITE_CREATE_EC_ADMIN_AUTH_TOKEN: import.meta.env.VITE_CREATE_EC_ADMIN_AUTH_TOKEN,
});

export const ROUTE_LOADER_IDS = Object.freeze({
  ROOT_EC: "root-ec-admin",
  PUBLIC_EC: "public-ec-admin",
});

export const ORG_MAP = {
  ACME: "org_y5gSUJ00ESXy3cel",
  ACME_QA: "org_vN7Pt3LXq6hdnb89",
  RIBBON: "org_sITrtSYGjzMpfZm3",
};

export const ACCOUNTS_TYPE_STYLE_MAP: Record<
  string,
  { textColor: string; bgColor: string; label: string }
> = {
  checking: { textColor: "#22543D", bgColor: "#C6F6D5", label: "Checking" },
  savings: { textColor: "#234E52", bgColor: "#B2F5EA", label: "Savings" },
  traditional_ira: { textColor: "#2A4365", bgColor: "#BEE3F8", label: "Traditional IRA" },
  roth_ira: { textColor: "#086F83", bgColor: "#C4F1F9", label: "Roth IRA" },
  cd: { textColor: "#44337A", bgColor: "#E9D8FD", label: "Certificate of Deposit" },
  default: { textColor: "#086F83", bgColor: "#C4F1F9", label: "Unknown Account" },
};

export const CASE_STATUS_STYLE_MAP: Record<
  string,
  { textColor: string; bgColor: string; label: string }
> = {
  submitted: { textColor: "#744210", bgColor: "#FEEBCB", label: "Submitted" },
  needs_info: { textColor: "#822727", bgColor: "#FED7D7", label: "Needs Info" },
  under_review: { textColor: "#2A4365", bgColor: "#BEE3F8", label: "Under Review" },
  confirmed: {
    textColor: "#22543D",
    bgColor: "#C6F6D5",
    label: "Confirmed Information",
  },
  transfer_completed: { textColor: "#22543D", bgColor: "#C6F6D5", label: "Transfer Completed" },
  default: { textColor: "#22543D", bgColor: "#E6E6E6", label: "Unknown Status" },
};

export const USER_ROLES = {
  ORG_ADMIN: "ORG_ADMIN",
  ORG_EDITOR: "ORG_EDITOR",
  CUSTOMER_SUPPORT_EDITOR: "CUSTOMER_SUPPORT_EDITOR",
  CUSTOMER_SUPPORT_VIEWER: "CUSTOMER_SUPPORT_VIEWER",
  MARKETING_SPECIALIST: "MARKETING_SPECIALIST",
};
