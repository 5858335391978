import { Box, Divider, HStack, Heading, Stack, Text, VStack } from "@chakra-ui/react";
import { CardWrapper } from "@src/components/Layout/Card/Card.component";
import { RxTarget } from "react-icons/rx";
import { FiArrowDownLeft, FiArrowUpRight } from "react-icons/fi";
import { HiCursorClick } from "react-icons/hi";
import { BsFillPersonPlusFill, BsFillPersonXFill, BsFillPersonVcardFill } from "react-icons/bs";
import { MdMoneyOff, MdOutlineAttachMoney, MdPercent } from "react-icons/md";
import { IconType } from "react-icons";
import { CiImageOn } from "react-icons/ci";
import { useParams } from "react-router-dom";
import { CampaignStatData, useCampaignStore } from "@src/store/campaign.store";
import { useEffect, useState } from "react";
import { capitalizeFirstLetter, formatNumberToCurrency } from "@shared/utils/string.utils";
import {
  UploadedDocument,
  UploadedDocumentProps,
} from "@shared/components/UploadedDocument/UploadedDocument.component";
import { useInstitutionStore } from "@src/store/institution.store";

interface CampaignStatInput {
  icon: IconType;
  label: string;
  key: CampaignStatData["key"];
}

type CampaignStat = Omit<CampaignStatInput, "key"> & CampaignStatData;

const statsInput: CampaignStatInput[] = [
  {
    icon: RxTarget,
    label: "TARGETED MEMBERS",
    key: "targetedMembers",
  },
  {
    icon: HiCursorClick,
    label: "ACCOUNT OPENING CLICKS",
    key: "accountOpeningClicks",
  },
  {
    icon: BsFillPersonPlusFill,
    label: "ACCOUNTS OPENED",
    key: "accountsOpened",
  },
  {
    icon: BsFillPersonXFill,
    label: "ACCOUNTS CHURNED",
    key: "accountsChurned",
  },
  {
    icon: BsFillPersonVcardFill,
    label: "ACCOUNTS RETAINED",
    key: "accountsRetained",
  },
  {
    icon: MdOutlineAttachMoney,
    label: "INHERITANCE RETAINED",
    key: "inheritanceRetainedAmount",
  },
  {
    icon: MdMoneyOff,
    label: "INHERITANCE CHURNED",
    key: "inheritanceChurnedAmount",
  },
  {
    icon: MdPercent,
    label: "PERCENTAGE OF INHERITANCE RETAINED",
    key: "inheritanceRetainedPercentage",
  },
];

const banners: UploadedDocumentProps[] = [
  {
    title: "Desktop banner",
    subtitle: "size 900x200",
    size: "medium",
    thumbnail: <CiImageOn color="#666666" size="24px" />,
  },
  {
    title: "Tablet banner",
    subtitle: "size 600x100",
    size: "medium",
    thumbnail: <CiImageOn color="#666666" size="24px" />,
  },
  {
    title: "Mobile banner",
    subtitle: "size 335x300",
    size: "medium",
    thumbnail: <CiImageOn color="#666666" size="24px" />,
  },
];

const allAccountTypes = [
  "Checking",
  "Savings",
  "Individual Retirement Accounts (IRA)",
  "Brokerage Accounts",
  "Certificates of Deposit (CD)",
  "Business Accounts",
  "Custodial Accounts",
  "Specialty Accounts",
];

const zeroedStats: CampaignStatData[] = [
  { key: "targetedMembers", value: "0" },
  { key: "accountOpeningClicks", value: "0" },
  { key: "accountsOpened", value: "0" },
  { key: "accountsChurned", value: "0" },
  { key: "accountsRetained", value: "0" },
  { key: "inheritanceRetainedAmount", value: "$0" },
  { key: "inheritanceRetainedPercentage", value: "0" },
  { key: "inheritanceChurnedAmount", value: "$0" },
];

export function CampaignOverviewTemplate() {
  const { id } = useParams<{ id: string }>();
  const { campaign, getCampaign } = useCampaignStore();
  const [statsGrouped, setStatsGrouped] = useState<CampaignStat[][]>([]);
  const { currentInstitution } = useInstitutionStore();

  useEffect(() => {
    getCampaign(Number(id));
  }, [getCampaign, id]);

  useEffect(() => {
    if (campaign) {
      let campaignStats = campaign.stats;
      if (!campaignStats) {
        campaignStats = zeroedStats;
      }

      const statCombinedWithInput: CampaignStat[] = [];
      for (const stat of campaignStats) {
        const statInput = statsInput.find(input => input.key === stat.key);
        if (!statInput) continue;

        statCombinedWithInput.push({
          ...stat,
          ...statInput,
        });
      }

      const statsGrouped = statCombinedWithInput.reduce((acc: CampaignStat[][], stat, index) => {
        const groupIndex = Math.floor(index / 4);
        acc[groupIndex] = acc[groupIndex] || [];
        acc[groupIndex].push(stat);
        return acc;
      }, []);

      setStatsGrouped(statsGrouped);
    }
  }, [campaign]);

  if (!campaign) {
    return <Heading>Loading...</Heading>;
  }

  const campaignAmounts = `${formatNumberToCurrency(campaign.minAmount || 0)} - ${campaign.maxAmount ? formatNumberToCurrency(campaign.maxAmount) : "$ ∞"}`;
  const campaignMembers = capitalizeFirstLetter(campaign.members);
  const campaignDates = `${new Date(campaign.startDate).toLocaleDateString("en-US")} - ${campaign.endDate ? new Date(campaign.endDate).toLocaleDateString("en-US") : "Indefinite"}`;
  const accountTypes =
    campaign.accountTypes[0] === "all"
      ? allAccountTypes
      : campaign.accountTypes.map(type => capitalizeFirstLetter(type));

  return (
    <Stack
      position="relative"
      h="full"
      w="full"
      alignItems="flex-start"
      gap={6}
      p={9}
      pt={10}
      maxW="calc(100vw - 280px)"
    >
      <VStack w="100%" gap="48px">
        <VStack align="flex-start" w="full" gap={7} px={1}>
          <Heading variant="h1" fontSize="24px" color="gray.700" lineHeight="36px" fontWeight={600}>
            Campaign performance metrics
          </Heading>

          <CardWrapper>
            <VStack gap="12px" divider={<Divider borderColor="gray.300" />}>
              {
                // divide stats into 2 groups of arrays to display in 2 rows: 1st row has 4 stats, 2nd row has 4 stats
                statsGrouped.map(group => (
                  <HStack
                    gap="16px"
                    align="flex-start"
                    justify="space-between"
                    w="full"
                    divider={<Divider orientation="vertical" borderColor="gray.300" h="104px" />}
                  >
                    {group.map(stat => (
                      <CampaignStat {...stat} />
                    ))}
                  </HStack>
                ))
              }
            </VStack>
          </CardWrapper>
        </VStack>

        <VStack align="flex-start" w="full" gap={7} px={1}>
          <Heading variant="h1" fontSize="24px" color="gray.700" lineHeight="36px" fontWeight={600}>
            Campaign details
          </Heading>

          <CardWrapper p="10px">
            <VStack
              align="flex-start"
              gap="0"
              divider={<Divider borderColor="gray.300" style={{ marginTop: 0, marginBottom: 0 }} />}
            >
              <CampaignDetailField label="Campaign name">
                <Text fontSize="18px" fontWeight="700" color="gray.900" mt="-8px">
                  {campaign.title}
                </Text>
              </CampaignDetailField>

              <HStack w="full" align="stretch">
                <VStack
                  w="full"
                  h="full"
                  borderRight="1px solid var(--chakra-colors-gray-300)"
                  divider={<Divider borderColor="gray.300" />}
                >
                  <CampaignDetailField label="Amount Inheriting">
                    <Text color="gray.700">{campaignAmounts}</Text>
                  </CampaignDetailField>

                  <CampaignDetailField
                    label={
                      currentInstitution && currentInstitution.type === "bank"
                        ? "Customers"
                        : "Members"
                    }
                  >
                    <Text color="gray.700">{campaignMembers}</Text>
                  </CampaignDetailField>

                  <CampaignDetailField label="Dates Running">
                    <Text color="gray.700">{campaignDates}</Text>
                  </CampaignDetailField>
                </VStack>

                <Box w="full" borderRight="1px solid var(--chakra-colors-gray-300)">
                  <CampaignDetailField label="Targeted Account Types">
                    <VStack gap="6px" align="flex-start">
                      {accountTypes.map(accountType => (
                        <Text key={accountType} color="gray.700">
                          {accountType}
                        </Text>
                      ))}
                    </VStack>
                  </CampaignDetailField>
                </Box>

                <VStack w="full" divider={<Divider borderColor="gray.300" />} justify="flex-start">
                  <CampaignDetailField label="Banners">
                    <VStack gap="6px" align="flex-start" w="full">
                      {banners.map(banner => (
                        <UploadedDocument key={banner.title} {...banner} />
                      ))}
                    </VStack>
                  </CampaignDetailField>
                </VStack>
              </HStack>
            </VStack>
          </CardWrapper>
        </VStack>
      </VStack>
    </Stack>
  );
}

export function CampaignStat(stat: CampaignStat) {
  return (
    <VStack align="flex-start" gap="0" p="4px 16px" w="full">
      <HStack justify="flex-start" color="gray.500" gap="4px" py="4px">
        <stat.icon size="20px" />
        <Heading fontSize="12px" letterSpacing="0.6px" fontWeight="700">
          {stat.label}
        </Heading>
      </HStack>
      <VStack gap="4px" align="flex-start" justify="flex-end">
        <Text fontSize="20px" fontWeight="700" color="gray.900" lineHeight="28px">
          {stat.value}
        </Text>

        {stat.delta && (
          <HStack py="4px" gap="2px">
            <HStack gap="2px">
              {stat.delta.isUpwardsTrend ? (
                <HStack color="green.500" gap="0">
                  <FiArrowUpRight size="16px" />
                  <Text fontSize="12px" fontWeight="600" color="green.500">
                    {stat.delta.value}
                  </Text>
                </HStack>
              ) : (
                <HStack color="red.500" gap="0">
                  <FiArrowDownLeft size="16px" />
                  <Text fontSize="12px" fontWeight="600" color="red.500">
                    {stat.delta.value}
                  </Text>
                </HStack>
              )}
              <Text fontSize="12px" color="gray.500">
                vs last week
              </Text>
            </HStack>
          </HStack>
        )}
      </VStack>
    </VStack>
  );
}

interface CampaignDetailFieldProps {
  label: string;
  children: JSX.Element;
}

export function CampaignDetailField({ label, children }: CampaignDetailFieldProps) {
  return (
    <VStack align="flex-start" w="full" gap="0">
      <Text
        color="gray.500"
        fontWeight={700}
        fontSize="12px"
        letterSpacing="0.6px"
        p="24px 24px 4px 24px"
      >
        {label.toUpperCase()}
      </Text>
      <Box p="12px 24px 16px 24px" w="full">
        {children}
      </Box>
    </VStack>
  );
}
