import MaskedInput, { MaskedInputProps } from "react-text-mask";
import { useController } from "react-hook-form";
import { Input, InputProps } from "@chakra-ui/react";

interface FormMaskedInput extends InputProps {
  name: string;
  mask: (string | RegExp)[];
  guide?: boolean;
  showMask?: boolean;
  placeholderChar?: string;
  maskOptions?: MaskedInputProps;
}

export function FormMaskedInput({
  name,
  mask,
  guide,
  showMask,
  placeholderChar,
  maskOptions,
  ...rest
}: Readonly<FormMaskedInput>) {
  const {
    field: { onChange, onBlur, value },
  } = useController({ name });

  return (
    <MaskedInput
      onChange={onChange}
      {...maskOptions}
      mask={mask}
      guide={guide}
      showMask={showMask}
      placeholderChar={placeholderChar}
      render={(ref, props) => {
        return <Input ref={ref} {...rest} value={value} {...props} onBlur={onBlur} />;
      }}
    />
  );
}
