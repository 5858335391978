import "@fontsource/lato/700.css";
import "@fontsource/clear-sans";
import "@fontsource-variable/work-sans";
import { theme } from "@chakra-ui/react";

export const globalStyles = {
  shadows: {
    ribbon: "0 0 0 3px #79d5d5",
  },
  radii: {
    ...theme.radii,
    whiteLabel: {
      buttonBorderRadius: "6px",
    },
  },
  colors: {
    ...theme.colors,
    brand: {
      100: "#c0eeee",
      200: "#9de1e1",
      300: "#79d5d5",
      400: "#56c8c8",
      500: "#3fafaf",
      600: "#2e8989",
      700: "#1e6262",
      800: "#0b3b3c",
      900: "#001616",
    },
    brandScheme: {
      400: "#2e8989",
      500: "#1e6262",
    },
    ghost: {
      50: "#f4f8fa",
      100: "#FAFCFE",
    },
    whiteLabel: {
      primary: "#2e8989",
      secondary: "#1e6262",
      tertiary: "#ffffff",
      borderColor: "#2e8989",
      fontColor: "#ffffff",
    },
    secondaryGray: {
      100: "#E0E5F2",
      200: "#E1E9F8",
      300: "#F4F7FE",
      400: "#E9EDF7",
      500: "#8F9BBA",
      600: "#A3AED0",
      700: "#707EAE",
      800: "#707EAE",
      900: "#1B2559",
    },
  },
  styles: {
    global: () => ({
      html: {},
      body: {
        overflowX: "hidden",
        letterSpacing: "-0.5px",
        backgroundColor: "#F8F8F8",
      },
      "#root": {},
    }),
  },
};
