import { createBrowserRouter, Outlet, redirect, RouterProvider } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { HelmetProvider } from "react-helmet-async";
import { CONSTANTS, ROUTE_LOADER_IDS, ROUTES } from "@config/constants.config";
import { AuthenticationModule } from "@services/authenticate.service";
import { EstateAdminRoutes } from "@routes/admin.routes";
import { Loading } from "@shared/components/Loading/Loading.component";
import { MainLayout } from "@components/Layout/Main/Main.component";
import { NotFoundPage } from "@pages/public/NotFound";

const router = createBrowserRouter([
  {
    id: ROUTE_LOADER_IDS.ROOT_EC,
    path: ROUTES.ROOT,
    element: (
      <MainLayout>
        <Outlet />
      </MainLayout>
    ),
    errorElement: <NotFoundPage />,
    children: EstateAdminRoutes,
    loader: async ({ request }) => {
      const currentURL = new URL(request.url);
      const pathname = currentURL.pathname;
      const isUserInvited =
        currentURL.searchParams.has("invitation") && currentURL.searchParams.has("organization");

      if (isUserInvited && pathname !== ROUTES.SIGNUP_INVITATION) {
        return redirect(`${ROUTES.SIGNUP_INVITATION}${currentURL.search}`);
      }

      if (pathname === ROUTES.ROOT) {
        return redirect(ROUTES.INHERITORS);
      }

      return {
        pathname,
      };
    },
  },
]);

export function App() {
  const { getAccessTokenSilently, logout } = useAuth0();

  AuthenticationModule.setLogout(() =>
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}${ROUTES.SIGNIN}`,
      },
    })
  );
  AuthenticationModule.setAccessTokenSilently(() =>
    getAccessTokenSilently({
      authorizationParams: {
        audience: CONSTANTS.VITE_APP_AUTH0_AUDIENCE,
        appTokenRequest: true,
      },
    })
  );

  return (
    <HelmetProvider>
      <RouterProvider router={router} fallbackElement={<Loading />} />
    </HelmetProvider>
  );
}
