import * as yup from "yup";
import dayjs from "dayjs";
import { DeceasedPersonDual, InheritorDual } from "@trustribbon/ribbon-ec-types";

export type DeceasedPersonStore = Omit<
  DeceasedPersonDual<"client">,
  "id" | "institutionId" | "createdAt" | "updatedAt"
>;
export type InheritorStore = Omit<
  InheritorDual<"client">,
  "id" | "institutionId" | "transferStatus"
>;

export type CheckInheritanceStatusStore = {
  inheritorSsn: string;
  deceasedSsn: string;
  email: string;
};

type InheritorFormSchema = yup.ObjectSchema<InheritorStore>;

const ssnValidationYup = yup
  .string()
  .transform(originalValue => {
    // Remove non-numeric characters before validation
    return originalValue ? originalValue.replace(/\D/g, "") : originalValue;
  })
  .length(9, "SSN must be 9 digits")
  .matches(/^\d{9}$/, "Invalid SSN format")
  .required("SSN is required");

export const inheritorFormSchema = yup
  .object({
    firstName: yup.string().required("Inform your First Name"),
    middleName: yup.string().nullable(),
    lastName: yup.string().required("Inform your Last Name"),
    ssn: ssnValidationYup,
    phoneNumber: yup.string().required("Inform your Phone Number"),
    email: yup.string().email().required("Inform your Email Address"),
    member: yup.boolean().required().typeError("Do you have an account with us already?"),
  })
  .required() as InheritorFormSchema;

type DeceasedPersonFormSchema = yup.ObjectSchema<DeceasedPersonStore>;

export const deceasedPersonFormSchema = yup
  .object({
    firstName: yup.string().required("Inform the Deceased's First Name"),
    middleName: yup.string().nullable(),
    lastName: yup.string().required("Inform the Deceased's Last Name"),
    ssn: ssnValidationYup,
    dateOfBirth: yup
      .string()
      .test("dateOfBirth", "Invalid Date of Birth. Use MM/DD/YYYY", value => {
        return dayjs(value, "MM/DD/YYYY", true).isValid();
      }),
  })
  .required() as DeceasedPersonFormSchema;

type CheckInheritanceStatusSchema = yup.ObjectSchema<CheckInheritanceStatusStore>;

export const checkInheritanceStatusFormSchema = yup
  .object({
    inheritorSsn: ssnValidationYup,
    deceasedSsn: ssnValidationYup,
    email: yup.string().email().required("Inform your email"),
  })
  .required() as CheckInheritanceStatusSchema;
