import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Divider, Flex, HStack, Heading, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAdminNotifyInfoStore } from "@store/adminNotifyInfo.store";
import { dateOfBirthMask } from "@shared/config/masks.config";
import { FormInput } from "@shared/components/FormComponents/FormInput.component";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { DeceasedPersonStore, deceasedPersonFormSchema } from "@shared/config/schema.validator";
import { PIIInputs } from "@shared/components/FormComponents/PIIInputs.component";
import { AdminNotifyNavigationBlock } from "../components/AdminNotifyNavigation.component";

type DeceasedDetailsProps = {
  onClickNext: () => void;
  onClickBack?: () => void;
};

const defaultValues = {
  firstName: "",
  middleName: null,
  lastName: "",
  dateOfBirth: "",
  ssn: "",
};

export function DeceasedDetailsForm({ onClickNext, onClickBack }: Readonly<DeceasedDetailsProps>) {
  const isMobile = useIsMobile();
  const { deceasedInfo, setDeceasedInfo } = useAdminNotifyInfoStore(state => ({
    deceasedInfo: state.deceasedInfo,
    setDeceasedInfo: state.setDeceasedInfo,
  }));
  const formMethods = useForm<DeceasedPersonStore>({
    defaultValues: deceasedInfo ?? defaultValues,
    resolver: yupResolver(deceasedPersonFormSchema),
  });

  const { handleSubmit, formState } = formMethods;
  const { errors } = formState;
  const hasErrors = !!errors.firstName || !!errors.lastName || !!errors.ssn;

  const onSubmit: SubmitHandler<DeceasedPersonStore> = data => {
    setDeceasedInfo(data);

    return onClickNext();
  };

  return (
    <FormProvider {...formMethods}>
      <VStack w="full" gap={7}>
        <HStack w="full" justifyContent="space-between">
          <Heading variant="h1" fontSize="24px" color="gray.700" lineHeight="40px" fontWeight={600}>
            Please tell us about the person who passed away
          </Heading>
        </HStack>
      </VStack>
      <form onSubmit={handleSubmit(onSubmit)} id="admin-deceased-form">
        <Flex direction="column">
          <Flex gap={8} direction="column" pb={16}>
            <PIIInputs />
            <HStack
              pr={3}
              align="flex-start"
              direction="row"
              width={isMobile ? "100%" : "50%"}
              flexWrap="wrap"
            >
              <FormInput
                label="Date of Birth"
                name="dateOfBirth"
                variant="whiteLabel"
                mask={dateOfBirthMask}
                maxLength={12}
                registerOptions={{ required: true }}
                size="lg"
                labelProps={{ backgroundColor: "#fff" }}
              />
            </HStack>
          </Flex>
          <Divider color="#CBD5E0" />
          <AdminNotifyNavigationBlock
            onClickNext={handleSubmit(onSubmit)}
            onClickBack={onClickBack}
            isDisabled={hasErrors}
          />
        </Flex>
      </form>
    </FormProvider>
  );
}
