import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { FormProvider, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { Divider, GridItem, HStack, Heading, Skeleton, Text, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  GetInheritorWithDeceasedPersonDetailsResponse,
  InheritanceCaseStatus,
} from "@trustribbon/ribbon-ec-types";
import { usePermission } from "@hooks/usePermission.hook";
import { useInheritorStore } from "@store/inheritor.store";
import { patchInheritanceCase, patchInheritor } from "@services/inheritor.service";
import { inheritorCardBankSchema, inheritorCardCreditUnionSchema } from "@config/schema.validator";
import { ROUTES, USER_ROLES } from "@config/constants.config";
import { Button } from "@shared/components/Button/Button.component";
import { EditableInput } from "@shared/components/FormComponents/EditableInput.component";
import { CustomSelect } from "@shared/components/FormComponents/CustomSelect.component";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { cleanNumberString, formatNumberToCurrency } from "@shared/utils/string.utils";
import { usPhoneMask } from "@shared/config/masks.config";
import { useAlert } from "@shared/hooks/useAlert.hook";
import { TextWithLoading } from "@shared/components/TextWithLoading/TextWithLoading.component";
import { STATUS_SELECT_OPTIONS, YES_NO_SELECT_OPTIONS } from "@shared/config/constants.config";
import { GridWrapper } from "@components/Layout/Grid/GridWrapper/GridWrapper.component";
import { EditButtons } from "../EditButtons/EditButtons.component";
import { UploadedDocumentsList } from "../UploadedDocumentList/UploadedDocumentList.component";
import { useInstitutionStore } from "@src/store/institution.store";

export type InheritorCardProps = {
  isLoading: boolean;
  mutate: () => void;
  inheritorWithDetails?: GetInheritorWithDeceasedPersonDetailsResponse;
  openMissingDocumentsModal: () => void;
};

export interface InheritorCardRef {
  cancelEditing: () => void;
  submitForm: () => Promise<void>;
}

const getDefaultValues = (inheritorWithDetails?: GetInheritorWithDeceasedPersonDetailsResponse) => {
  const rawTotalAssets = +(inheritorWithDetails?.totalAssets ?? "0");

  return {
    member: inheritorWithDetails?.member ?? false,
    totalAssets: formatNumberToCurrency(rawTotalAssets),
    firstName: inheritorWithDetails?.firstName ?? "",
    middleName: inheritorWithDetails?.middleName ?? "",
    lastName: inheritorWithDetails?.lastName ?? "",
    caseStatus: inheritorWithDetails?.deceasedPersons[0]?.caseStatus ?? "submitted",
    phoneNumber: inheritorWithDetails?.phoneNumber ?? "",
    email: inheritorWithDetails?.email ?? "",
  };
};

const documents = [
  { title: "Government ID", subtitle: "IMG_23455.jpg" },
  // { title: "Letter of instruction", subtitle: "letter_of_instruction.pdf" },
  // { title: "Letter of administration", subtitle: "letter-of-admin.pdf", archived: true },
  // { title: "Letter of testamentary", subtitle: "letter-of-testamentary.pdf", archived: true },
];

export const InheritorCard = forwardRef<InheritorCardRef, Readonly<InheritorCardProps>>(
  ({ isLoading, mutate, inheritorWithDetails, openMissingDocumentsModal }, ref) => {
    const { currentInstitution } = useInstitutionStore();
    const { currentDeceased, setIsInheritor } = useInheritorStore();
    const isMobile = useIsMobile();
    const { navigate } = useNavigation();
    const [isEditing, setIsEditing] = useState(false);
    const [internalLoading, setInternalLoading] = useState(false);
    const { setShowAlert, AlertComponent } = useAlert();
    const { isPermitted } = usePermission();

    const hasPermission = isPermitted([USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_EDITOR]);

    const defaultValues = getDefaultValues(inheritorWithDetails);

    const formMethods = useForm<typeof defaultValues>({
      defaultValues,
      resolver: yupResolver(
        currentInstitution && currentInstitution.type === "bank"
          ? inheritorCardBankSchema
          : inheritorCardCreditUnionSchema
      ),
    });

    const { handleSubmit, control, reset, formState, watch } = formMethods;
    const isDirty = formState.isDirty;

    useEffect(() => {
      const rawTotalAssets = +(inheritorWithDetails?.totalAssets ?? "0");

      reset(
        {
          member: inheritorWithDetails?.member ?? false,
          totalAssets: formatNumberToCurrency(rawTotalAssets),
          firstName: inheritorWithDetails?.firstName ?? "",
          middleName: inheritorWithDetails?.middleName ?? "",
          lastName: inheritorWithDetails?.lastName ?? "",
          caseStatus: inheritorWithDetails?.deceasedPersons[0]?.caseStatus ?? "submitted",
          phoneNumber: inheritorWithDetails?.phoneNumber ?? "",
          email: inheritorWithDetails?.email ?? "",
        },
        { keepValues: false, keepDefaultValues: false }
      );
    }, [currentDeceased?.caseStatus, inheritorWithDetails, reset]);

    const totalAssetsValue = useWatch({ name: "totalAssets", control });

    const handleEditing = () => {
      setIsEditing(!isEditing);
    };

    const handleCancelEditing = () => {
      setIsEditing(false);
      reset(defaultValues);
    };

    const onSubmit: SubmitHandler<typeof defaultValues> = async data => {
      setIsEditing(false);

      if (isDirty) {
        setInternalLoading(true);

        try {
          await patchInheritor(`/inheritors/${inheritorWithDetails?.id}`, {
            firstName: data.firstName,
            middleName: data.middleName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: cleanNumberString(data.phoneNumber),
            member: data.member,
          });

          if (data?.caseStatus !== currentDeceased?.caseStatus) {
            await patchInheritanceCase(`/inheritance-cases/${currentDeceased?.caseId}`, {
              status: data.caseStatus as InheritanceCaseStatus,
              notes: "",
            });
          }
        } catch {
          setShowAlert({
            title: "An error occurred while submitting your information.",
            description: "Please try again later.",
            status: "error",
          });
        }

        setInternalLoading(false);

        mutate();
      }
    };

    const isComponentLoading = internalLoading || isLoading;

    const caseStatusInputValue = watch("caseStatus");

    const cancelEditing = () => {
      handleCancelEditing();
    };
    const submitForm = async () => {
      handleSubmit(onSubmit)();
    };

    useImperativeHandle(ref, () => ({
      cancelEditing,
      submitForm,
    }));

    const isCaseStatusDirty = formState.dirtyFields.caseStatus;
    // when user changes case status to "needs_info", open missing documents modal
    useEffect(() => {
      if (caseStatusInputValue === "needs_info" && isCaseStatusDirty) {
        openMissingDocumentsModal();
      }
    }, [openMissingDocumentsModal, caseStatusInputValue, isCaseStatusDirty]);

    return (
      <FormProvider {...formMethods} control={control}>
        <AlertComponent />
        <HStack w="full" justifyContent="space-between" flexWrap="wrap">
          <Heading variant="h1" fontSize="24px" color="gray.700" lineHeight="36px" fontWeight={600}>
            Inheritor
          </Heading>
          <HStack>
            {/* <Button variant="whiteLabelOutline" leftIcon={<MdAdd size="16px" />}>
              Upload document
            </Button> */}

            <EditButtons
              defaultValues={defaultValues}
              onEdit={handleEditing}
              onSave={handleSubmit(onSubmit)}
              onCancel={handleCancelEditing}
              isEditing={isEditing}
              isLoading={isComponentLoading}
            />
          </HStack>
        </HStack>

        <GridWrapper
          as="form"
          id="inheritor-form"
          w="full"
          templateRows="auto auto auto"
          templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(4, 1fr)"}
          gap={isMobile ? 2 : 0}
          mb={9}
        >
          <GridItem colSpan={isMobile ? 4 : 1} padding="14px 24px 0">
            <VStack alignItems="flex-start" gap={2}>
              <Text
                fontSize={12}
                color="#gray.500"
                letterSpacing="0.6px"
                fontWeight={700}
                lineHeight="16px"
                textTransform="uppercase"
              >
                FIRST NAME
              </Text>
              <EditableInput
                registerOptions={{ required: "Inheritor First Name is required." }}
                isEditing={isEditing}
                isLoading={isComponentLoading}
                aria-label="Inheritor First Name"
                name="firstName"
                fontSize={18}
                fontWeight={700}
                lineHeight="28px"
                color="#2D3748"
                w="full"
              />
            </VStack>
          </GridItem>
          <GridItem colSpan={isMobile ? 4 : 1} padding="14px 24px 0">
            <VStack alignItems="flex-start" gap={2}>
              <Text
                fontSize={12}
                color="#gray.500"
                letterSpacing="0.6px"
                fontWeight={700}
                lineHeight="16px"
                textTransform="uppercase"
              >
                MIDDLE NAME
              </Text>
              <EditableInput
                registerOptions={{ required: false }}
                isEditing={isEditing}
                isLoading={isComponentLoading}
                aria-label="Inheritor Middle Name"
                name="middleName"
                fontSize={18}
                fontWeight={700}
                lineHeight="28px"
                color="#2D3748"
                w="full"
              />
            </VStack>
          </GridItem>
          <GridItem colSpan={isMobile ? 4 : 1} padding="14px 24px 0">
            <VStack alignItems="flex-start" gap={2}>
              <Text
                fontSize={12}
                color="#gray.500"
                letterSpacing="0.6px"
                fontWeight={700}
                lineHeight="16px"
                textTransform="uppercase"
              >
                LAST NAME
              </Text>
              <EditableInput
                registerOptions={{ required: "Inheritor Last Name is required." }}
                isEditing={isEditing}
                isLoading={isComponentLoading}
                aria-label="Inheritor Last Name"
                name="lastName"
                fontSize={18}
                fontWeight={700}
                lineHeight="28px"
                color="#2D3748"
                w="full"
              />
            </VStack>
          </GridItem>
          <GridItem colSpan={isMobile ? 4 : 1} padding="14px 24px 0">
            <VStack alignItems="flex-start">
              <Text
                fontSize={12}
                color="#gray.500"
                letterSpacing="0.6px"
                fontWeight={700}
                lineHeight="16px"
                textTransform="uppercase"
              >
                TOTAL INHERITANCE
              </Text>
              <TextWithLoading
                isLoading={isComponentLoading}
                fontWeight={700}
                color="#2D3748"
                lineHeight="28px"
                fontSize={18}
              >
                {totalAssetsValue}
              </TextWithLoading>
            </VStack>
          </GridItem>

          <GridItem colSpan={4}>
            <Divider my={4} />
          </GridItem>

          <GridItem colSpan={isMobile ? 4 : 1} padding="8px 24px 0">
            <VStack alignItems="flex-start" gap={2}>
              <Text
                fontSize={12}
                color="#gray.500"
                letterSpacing="0.6px"
                fontWeight={700}
                lineHeight="16px"
                textTransform="uppercase"
              >
                Case ID
              </Text>
              <TextWithLoading
                isLoading={isComponentLoading}
                fontWeight={400}
                color="#2D3748"
                lineHeight="20px"
                fontSize={14}
              >
                {inheritorWithDetails?.deceasedPersons[0].caseId.toString().padStart(6, "0")}
              </TextWithLoading>
            </VStack>
          </GridItem>
          <GridItem colSpan={isMobile ? 4 : 1} padding="8px 24px 0">
            <VStack alignItems="flex-start" gap={2}>
              <Text
                fontSize={12}
                color="#gray.500"
                letterSpacing="0.6px"
                fontWeight={700}
                lineHeight="16px"
                textTransform="uppercase"
              >
                SSN
              </Text>
              <Button
                textAlign="left"
                variant="whiteLabelLink"
                fontSize={14}
                color="#319795"
                fontWeight={700}
                lineHeight="20px"
                textDecoration="underline"
                px={0}
                isLoading={isComponentLoading}
                isDisabled={!hasPermission}
                CustomLoadingComponent={
                  <Skeleton
                    {...{ startColor: "#c0eeee", endColor: "#79d5d5" }}
                    w="full"
                    height="32px"
                  />
                }
                onClick={() => {
                  setIsInheritor(true);
                  navigate(
                    `${ROUTES.INHERITORS_SSN_PREVIEW.replace(":id", inheritorWithDetails?.id as string)}`
                  );
                }}
              >
                View SSN
              </Button>
            </VStack>
          </GridItem>
          <GridItem colSpan={isMobile ? 4 : 1} padding="8px 24px 0">
            <VStack alignItems="flex-start" gap={2}>
              <Text
                fontSize={12}
                color="#gray.500"
                letterSpacing="0.6px"
                fontWeight={700}
                lineHeight="16px"
                textTransform="uppercase"
              >
                CURRENT{" "}
                {currentInstitution && currentInstitution.type === "bank" ? "CUSTOMER" : "MEMBER"}
              </Text>
              <CustomSelect
                isEditing={isEditing}
                isLoading={isComponentLoading}
                name="member"
                placeholder={`Is the inheritor a ${currentInstitution && currentInstitution.type === "bank" ? "customer" : "member"}?`}
                registerOptions={{ required: true }}
                closeMenuOnSelect
                options={YES_NO_SELECT_OPTIONS}
                inputGroupProps={{
                  sx: {
                    "& .react-select": {
                      width: "100%",
                      transform: "translateX(-1.3rem)",
                      "& [data-readonly=true]": {
                        borderColor: "transparent",
                        "& div:nth-of-type(2)": {
                          display: "none",
                        },
                      },
                      "div span": {
                        fontWeight: 700,
                      },
                    },
                  },
                }}
              />
            </VStack>
          </GridItem>
          <GridItem colSpan={isMobile ? 4 : 1} padding="8px 24px 0">
            <VStack alignItems="flex-start" gap={2}>
              <Text
                fontSize={12}
                color="#gray.500"
                letterSpacing="0.6px"
                fontWeight={700}
                lineHeight="16px"
                textTransform="uppercase"
              >
                INHERITANCE STATUS
              </Text>
              <CustomSelect
                isEditing={isEditing}
                isLoading={isComponentLoading}
                name="caseStatus"
                placeholder="Select status"
                closeMenuOnSelect
                options={STATUS_SELECT_OPTIONS}
                inputGroupProps={{
                  sx: {
                    "& .react-select": {
                      width: "100%",
                      transform: "translateX(-1.3rem)",
                      "& [data-readonly=true]": {
                        borderColor: "transparent",
                        "& div:nth-of-type(2)": {
                          display: "none",
                        },
                      },
                      "div span": {
                        fontWeight: 700,
                      },
                    },
                  },
                }}
              />
            </VStack>
          </GridItem>

          <GridItem colSpan={4}>
            <Divider my={4} />
          </GridItem>

          <GridItem colSpan={isMobile ? 4 : 1} padding="8px 24px 16px">
            <VStack alignItems="flex-start" gap={2}>
              <Text
                fontSize={12}
                color="#gray.500"
                letterSpacing="0.6px"
                fontWeight={700}
                lineHeight="16px"
                textTransform="uppercase"
              >
                PHONE NUMBER
              </Text>
              <EditableInput
                aria-label="Phone Number"
                name="phoneNumber"
                fontSize={14}
                fontWeight={400}
                mask={usPhoneMask}
                lineHeight="20px"
                color="#2D3748"
                isEditing={isEditing}
                isLoading={isComponentLoading}
                registerOptions={{ required: "Phone Number is required." }}
              />
            </VStack>
          </GridItem>
          <GridItem colSpan={isMobile ? 4 : 1} padding="8px 24px 16px">
            <VStack alignItems="flex-start" gap={2}>
              <Text
                fontSize={12}
                color="#gray.500"
                letterSpacing="0.6px"
                fontWeight={700}
                lineHeight="16px"
                textTransform="uppercase"
              >
                EMAIL
              </Text>
              <EditableInput
                aria-label="Inheritor Email Address"
                name="email"
                fontSize={14}
                fontWeight={400}
                lineHeight="20px"
                color="#2D3748"
                isEditing={isEditing}
                isLoading={isComponentLoading}
                registerOptions={{ required: "Email Address is required." }}
              />
            </VStack>
          </GridItem>

          <UploadedDocumentsList
            documents={documents}
            governmentIdUrl={inheritorWithDetails?.deceasedPersons[0].latestGovernmentId?.url}
          />
        </GridWrapper>
      </FormProvider>
    );
  }
);
