import { Flex, HStack, Heading, Skeleton, Stack, Text, VStack } from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";
import { HiOutlineExternalLink } from "react-icons/hi";
import { InheritorDual } from "@trustribbon/ribbon-ec-types";
import { ROUTES, USER_ROLES } from "@config/constants.config";
import { usePermission } from "@hooks/usePermission.hook";
import { useInheritorStore } from "@store/inheritor.store";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { Button } from "@shared/components/Button/Button.component";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { CardWrapper } from "@components/Layout/Card/Card.component";

export type OtherInheritor = Omit<InheritorDual<"client">, "ssn" | "member"> & {
  member: boolean | null;
};

type OtherInheritorsProps = {
  isLoading: boolean;
  otherInheritors?: Pick<OtherInheritor, "id" | "firstName" | "lastName" | "middleName">[];
};

export function OtherInheritors({ isLoading, otherInheritors }: Readonly<OtherInheritorsProps>) {
  const { setIsInheritor } = useInheritorStore();
  const isMobile = useIsMobile();
  const { navigate } = useNavigation();
  const { isPermitted } = usePermission();

  const handleInheritorClick = (inheritorId: string) => {
    setIsInheritor(true);

    return navigate(ROUTES.INHERITORS_VIEW.replace(":id", inheritorId));
  };

  const hasPermission = isPermitted([USER_ROLES.ORG_ADMIN]);

  return (
    <Flex as="form" id="inheritor-form" w="full" gap={3} flexDirection="column" mb={9}>
      <VStack w="full" gap={7}>
        <HStack w="full" justifyContent="space-between" flexWrap="wrap">
          <Heading variant="h1" fontSize="24px" color="gray.700" lineHeight="36px" fontWeight={600}>
            Inheritors from the deceased
          </Heading>
          <Button
            variant="whiteLabel"
            leftIcon={<MdAdd />}
            isLoading={isLoading}
            _loading={{
              "&:hover": {
                backgroundColor: "whiteLabel.primary",
              },
            }}
            display={hasPermission ? "flex" : "none"}
            onClick={() => navigate(ROUTES.INHERITORS_ADD)}
          >
            {isLoading ? "Edit" : "Add Inheritor"}
          </Button>
        </HStack>
      </VStack>

      <CardWrapper>
        <Stack
          w="full"
          justifyContent="space-between"
          gap={4}
          flexDirection={isMobile ? "column" : "row"}
          padding="14px 24px 16px"
        >
          <VStack alignItems="flex-start" w="full" gap={2}>
            <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
              OTHER INHERITORS
            </Text>
            <Stack w="full" flexDirection={isMobile ? "column" : "row"} gap={isMobile ? 4 : 2}>
              {isLoading && (
                <Skeleton {...{ startColor: "#c0eeee", endColor: "#79d5d5" }} h="32px" w="full" />
              )}
              {!isLoading && otherInheritors?.length === 0 && (
                <Text fontSize={14} color="#1A202C" lineHeight="20px">
                  No other inheritors
                </Text>
              )}
              {!isLoading &&
                otherInheritors?.map(inheritor => {
                  return (
                    <VStack key={inheritor.id} alignItems="flex-start" gap={2}>
                      <Button
                        variant="whiteLabelOutline"
                        color="#1A202C"
                        rightIcon={<HiOutlineExternalLink color="#319795" />}
                        onClick={() => handleInheritorClick(inheritor.id)}
                      >{`${inheritor.firstName} ${inheritor.lastName ?? ""}`}</Button>
                    </VStack>
                  );
                })}
            </Stack>
          </VStack>
        </Stack>
      </CardWrapper>
    </Flex>
  );
}
