import { Link } from "react-router-dom";
import { HStack, Text, VStack } from "@chakra-ui/react";
import { HiOutlineExternalLink } from "react-icons/hi";
import { IoDocumentTextOutline } from "react-icons/io5";
import { DocumentType } from "@trustribbon/ribbon-ec-types";
import { EnhancedFile } from "@shared/types";
import { Button } from "@shared/components/Button/Button.component";
import { GetInstitutionBySubdomainResponse } from "@trustribbon/ribbon-ec-types";

type UploadedReviewProps = {
  isDisabled: boolean;
  documentEntries: [DocumentType, EnhancedFile | null][];
  institution?: GetInstitutionBySubdomainResponse;
};

export function UploadedReview({
  isDisabled,
  documentEntries,
  institution,
}: Readonly<UploadedReviewProps>) {
  const isHapo = institution?.subdomain === "hapo";

  return (
    <VStack width="100%" gap={6}>
      <HStack gap={6} align="flex-start" direction="row" width="100%" flexWrap="wrap">
        {!isDisabled && documentEntries && documentEntries.length > 0 ? (
          documentEntries.map(([fieldName, file]) => {
            const formatString = (label: string) =>
              label
                .replace(/-/g, " ")
                .replace(/\b\w/g, char => char.toUpperCase())
                .replace(/\b\w{1,3}\b/g, word => word.toUpperCase());
            const fileTypeLabel = formatString(fieldName);

            return file ? (
              <Link key={fieldName} to={file.preview} target="_blank">
                <Button
                  leftIcon={<IoDocumentTextOutline />}
                  rightIcon={<HiOutlineExternalLink />}
                  backgroundColor={isHapo ? "rgba(255, 205, 0, .2)" : "#fff"}
                  color={isHapo ? "whiteLabel.fontColor" : undefined}
                  _hover={{
                    color: isHapo ? "whiteLabel.fontColor" : "white",
                    bg: isHapo ? "rgba(255, 205, 0, .4)" : "whiteLabel.secondary",
                    border: "1.9px solid",
                    borderColor: "whiteLabel.secondary",
                  }}
                  variant="whiteLabelOutline"
                  gap={0}
                  size="md"
                >
                  {fileTypeLabel}
                </Button>
              </Link>
            ) : null;
          })
        ) : (
          <Text fontSize="16px" lineHeight="24px" color="#718096">
            No files uploaded. Please go back in the flow.
          </Text>
        )}
      </HStack>
    </VStack>
  );
}
