import Cookies, { CookieSetOptions } from "universal-cookie";

const cookies = new Cookies();
const getCurrentDomain = () => window.location.hostname;

export const getExpirationDateFromToken = (token: string) => {
  if (process.env.NODE_ENV === "development" && token === "no-token-needed")
    return new Date(Date.now() + 60 * 1 * 1000);

  const [, payloadBase64] = token.split(".");
  const payloadJson = atob(payloadBase64);
  const payload = JSON.parse(payloadJson);

  return new Date(payload.exp * 1000);
};

export function setCookie(name: string, value: string, options: CookieSetOptions = {}) {
  if (typeof window !== "undefined") {
    const date = new Date();

    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

    return cookies.set(name, value, {
      domain: getCurrentDomain(),
      expires: date,
      path: "/",
      ...options,
    });
  }
}

export function getCookie(name: string) {
  if (typeof window !== "undefined") {
    return cookies.get(name);
  }
}

export function deleteCookie(name: string) {
  if (typeof window !== "undefined") {
    const date = new Date();

    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

    return cookies.set(name, "", {
      domain: getCurrentDomain(),
      expires: date,
      path: "/",
    });
  }
}

export function setStorage(name: string, value: string) {
  if (typeof window !== "undefined") {
    localStorage.setItem(name, value);
  }
}

export function getStorage(name: string) {
  if (typeof window !== "undefined") {
    return localStorage.getItem(name);
  }
}

export function removeStorage(name: string) {
  if (typeof window !== "undefined") {
    localStorage.removeItem(name);
  }
}
