import { HStack, Heading, Stack, Text, VStack, Tag, Skeleton, Box } from "@chakra-ui/react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { Button } from "@shared/components/Button/Button.component";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import {
  CustomSelect,
  StatusSelectOption,
} from "@shared/components/FormComponents/CustomSelect.component";
import { formatNumberToCurrency, formatPhoneNumber } from "@shared/utils/string.utils";
import { EditableInput } from "@shared/components/FormComponents/EditableInput.component";
import { YES_NO_SELECT_OPTIONS } from "@shared/config/constants.config";
import { ACCOUNTS_TYPE_STYLE_MAP, CASE_STATUS_STYLE_MAP } from "@config/constants.config";
import { useInheritorDetails } from "@hooks/useInheritorDetails.hook";
import { CardWrapper } from "@components/Layout/Card/Card.component";
import { Flowers, Chocolate, Note, NoteChocolateFlowers } from "./Icons/Condolences.Icons";
import { ConfirmationModal, useConfirmationModal } from "@shared/hooks/useConfirmationModal.hook";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useInstitutionStore } from "@src/store/institution.store";

export function UserInsightsTemplate() {
  const { currentInstitution } = useInstitutionStore();
  const { id } = useParams();
  const { isLoading, inheritorWithDetails } = useInheritorDetails(id ?? "");
  const [modalOptions, openModal] = useConfirmationModal();

  const isPat =
    inheritorWithDetails?.firstName && ["Patrick"].includes(inheritorWithDetails?.firstName);
  const isChris =
    inheritorWithDetails?.firstName && ["Christopher"].includes(inheritorWithDetails?.firstName);
  const isVik =
    inheritorWithDetails?.firstName && ["Victoria"].includes(inheritorWithDetails?.firstName);

  const isMobile = useIsMobile();

  const defaultValues = {
    status: "not_started",
  };

  const formMethods = useForm<typeof defaultValues>({
    defaultValues,
  });
  const { control } = formMethods;

  const condolencesDefaultValues = {
    condolences: isPat ? "all" : isChris ? "all" : isVik ? "flowers" : "",
  };

  const condolencesFormMethods = useForm<typeof condolencesDefaultValues>({
    defaultValues: condolencesDefaultValues,
  });

  const condolencesWatch = useWatch({
    control: condolencesFormMethods.control,
    name: "condolences",
  });

  const FinancialAccountComponent = () => {
    const accounts = inheritorWithDetails?.financialAccounts ?? [];
    const uniqueAccountTypes = [
      ...new Set(accounts?.map(account => account?.accountType?.toLowerCase() ?? "unknown_type")),
    ];

    return (
      <HStack spacing={2} flexWrap="wrap">
        {uniqueAccountTypes.length > 0 ? (
          uniqueAccountTypes
            .filter(type => type)
            .map(accountType => {
              const { textColor, bgColor, label } =
                ACCOUNTS_TYPE_STYLE_MAP[accountType as keyof typeof ACCOUNTS_TYPE_STYLE_MAP] ||
                ACCOUNTS_TYPE_STYLE_MAP.default;

              return (
                <Tag
                  key={accountType}
                  fontWeight={700}
                  textColor={textColor}
                  bgColor={bgColor}
                  textTransform="capitalize"
                  lineHeight="16px"
                  fontSize={14}
                >
                  {label}
                </Tag>
              );
            })
        ) : (
          <Tag
            bgColor="#E2E8F0"
            textColor="#4A5568"
            fontWeight={700}
            fontSize={14}
            lineHeight="16px"
          >
            Not Specified
          </Tag>
        )}
      </HStack>
    );
  };

  return (
    <FormProvider {...formMethods} control={control}>
      <form id="client-insight-form">
        <Stack position="relative" h="full" w="full" alignItems="flex-start" gap={6} p={9} pt={12}>
          <VStack w="full" gap={7}>
            <HStack w="full" justifyContent="space-between">
              <Heading
                variant="h1"
                fontSize="24px"
                color="gray.700"
                lineHeight="36px"
                fontWeight={600}
              >
                {inheritorWithDetails?.firstName} insights
              </Heading>
            </HStack>
          </VStack>
          <CardWrapper>
            <Stack
              w="full"
              justifyContent="space-between"
              gap={4}
              flexDirection={isMobile ? "column" : "row"}
              padding="14px 24px 0"
            >
              <VStack alignItems="flex-start" width={isMobile ? "100%" : "25%"}>
                <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                  NAME
                </Text>
                <EditableInput
                  aria-label="First Name"
                  name="firstName"
                  defaultValue={inheritorWithDetails?.firstName}
                  fontSize={18}
                  color="#2D3748"
                  fontWeight={700}
                  lineHeight="20px"
                  w="full"
                  isLoading={isLoading}
                  registerOptions={{}}
                />
              </VStack>
              <VStack alignItems="flex-start" width={isMobile ? "100%" : "25%"}>
                <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                  Case ID
                </Text>
                <EditableInput
                  aria-label="Middle Name"
                  name="middleName"
                  defaultValue={inheritorWithDetails?.deceasedPersons[0]?.caseId ?? "N/A"}
                  fontSize={18}
                  color="#2D3748"
                  fontWeight={700}
                  lineHeight="20px"
                  w="full"
                  isLoading={isLoading}
                  registerOptions={{}}
                />
              </VStack>
              <VStack alignItems="flex-start" width={isMobile ? "100%" : "25%"}>
                <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                  Accounts
                </Text>
                {isLoading ? (
                  <Skeleton
                    {...{ startColor: "#c0eeee", endColor: "#79d5d5" }}
                    w="full"
                    height="32px"
                  />
                ) : (
                  <FinancialAccountComponent />
                )}
              </VStack>
              <VStack alignItems="flex-start" width={isMobile ? "100%" : "25%"}>
                <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                  TOTAL INHERITANCE
                </Text>
                <EditableInput
                  aria-label="Total Inheritance"
                  name="totalInheritance"
                  defaultValue={formatNumberToCurrency(
                    inheritorWithDetails?.totalAssets
                      ? Number(inheritorWithDetails?.totalAssets)
                      : 0
                  )}
                  fontSize={18}
                  color="#2D3748"
                  fontWeight={700}
                  lineHeight="20px"
                  w="full"
                  isLoading={isLoading}
                  registerOptions={{}}
                />
              </VStack>
            </Stack>

            <Stack
              w="full"
              justifyContent="space-between"
              gap={4}
              flexDirection={isMobile ? "column" : "row"}
              padding="0 24px 0"
            >
              <VStack alignItems="flex-start" width={isMobile ? "100%" : "25%"}>
                <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                  EMAIL
                </Text>
                <EditableInput
                  aria-label="Email"
                  name="email"
                  defaultValue={inheritorWithDetails?.email}
                  fontSize={14}
                  color="#2D3748"
                  fontWeight={400}
                  lineHeight="20px"
                  w="full"
                  isLoading={isLoading}
                  registerOptions={{}}
                />
              </VStack>
              <VStack alignItems="flex-start" width={isMobile ? "100%" : "25%"}>
                <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                  PHONE NUMBER
                </Text>
                <EditableInput
                  aria-label="Phone Number"
                  name="phoneNumber"
                  defaultValue={
                    inheritorWithDetails?.phoneNumber
                      ? (formatPhoneNumber(inheritorWithDetails.phoneNumber) as string)
                      : inheritorWithDetails?.phoneNumber
                  }
                  fontSize={14}
                  color="#2D3748"
                  fontWeight={400}
                  lineHeight="20px"
                  w="full"
                  isLoading={isLoading}
                  registerOptions={{}}
                />
              </VStack>

              <VStack alignItems="flex-start" width={isMobile ? "100%" : "25%"}>
                <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                  CURRENT{" "}
                  {currentInstitution && currentInstitution.type === "bank" ? "CUSTOMER" : "MEMBER"}
                </Text>
                <CustomSelect
                  isLoading={isLoading}
                  name="member"
                  placeholder={`Is the inheritor a ${currentInstitution && currentInstitution.type === "bank" ? "customer" : "member"}?`}
                  defaultValue={
                    YES_NO_SELECT_OPTIONS.find(
                      option =>
                        option.value.toLocaleString() ===
                        (inheritorWithDetails?.member as unknown as string)
                    ) ?? YES_NO_SELECT_OPTIONS[0]
                  }
                  registerOptions={{}}
                  closeMenuOnSelect
                  options={YES_NO_SELECT_OPTIONS}
                  inputGroupProps={{
                    sx: {
                      "& .react-select": {
                        width: "100%",
                        transform: "translateX(-1.3rem)",
                        "& [data-readonly=true]": {
                          borderColor: "transparent",
                          "& div:nth-of-type(2)": {
                            display: "none",
                          },
                        },
                      },
                    },
                  }}
                />
              </VStack>

              <VStack alignItems="flex-start" width={isMobile ? "100%" : "25%"}>
                <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                  INHERITANCE STATUS
                </Text>
                {isLoading ? (
                  <Skeleton
                    {...{ startColor: "#c0eeee", endColor: "#79d5d5" }}
                    w="full"
                    height="32px"
                  />
                ) : (
                  <Tag
                    textColor={
                      CASE_STATUS_STYLE_MAP[
                        inheritorWithDetails?.deceasedPersons[0]?.caseStatus ?? "default"
                      ]?.textColor
                    }
                    bgColor={
                      CASE_STATUS_STYLE_MAP[
                        inheritorWithDetails?.deceasedPersons[0]?.caseStatus ?? "default"
                      ]?.bgColor
                    }
                    textTransform="capitalize"
                    fontWeight={700}
                    fontSize={14}
                    lineHeight="16px"
                  >
                    {
                      CASE_STATUS_STYLE_MAP[
                        inheritorWithDetails?.deceasedPersons[0]?.caseStatus ?? "default"
                      ]?.label
                    }
                  </Tag>
                )}
              </VStack>
            </Stack>

            {/* <Stack
              w="full"
              justifyContent="space-between"
              gap={4}
              flexDirection={isMobile ? "column" : "row"}
              padding="0 24px 0"
            >
              <VStack alignItems="flex-start" gap={1} width={isMobile ? "100%" : "25%"}>
                <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                  STREET ADDRESS
                </Text>
                <Text fontSize={14} color="#2D3748" fontWeight={400} lineHeight="20px">
                  4682 Cityview Drive
                </Text>
              </VStack>
              <VStack alignItems="flex-start" gap={1} width={isMobile ? "100%" : "25%"}>
                <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                  CITY
                </Text>
                <Text fontSize={14} color="#2D3748" fontWeight={400} lineHeight="20px">
                  Springfield
                </Text>
              </VStack>

              <VStack alignItems="flex-start" gap={1} width={isMobile ? "100%" : "25%"}>
                <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                  STATE
                </Text>
                <Text fontSize={14} color="#2D3748" fontWeight={400} lineHeight="20px">
                  Pennsylvania
                </Text>
              </VStack>

              <VStack alignItems="flex-start" gap={1} width={isMobile ? "100%" : "25%"}>
                <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                  ZIP CODE
                </Text>
                <Text fontSize={14} color="#2D3748" fontWeight={400} lineHeight="20px">
                  19064
                </Text>
              </VStack>
            </Stack> */}
            {inheritorWithDetails?.deceasedPersons[0]?.caseStatus &&
            ["confirmed", "transfer_completed"].includes(
              inheritorWithDetails?.deceasedPersons[0]?.caseStatus
            ) ? (
              <Stack
                w="full"
                justifyContent="space-between"
                gap={10}
                flexDirection={isMobile ? "column" : "row"}
                padding="0 24px 16px"
              >
                <VStack alignItems="flex-start" gap={1} width="100%">
                  <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                    SEND CONDOLENCES
                  </Text>
                  {isLoading ? (
                    <Skeleton
                      {...{ startColor: "#c0eeee", endColor: "#79d5d5" }}
                      w="full"
                      height="58px"
                    />
                  ) : (
                    <>
                      <FormProvider {...condolencesFormMethods}>
                        <ConfirmationModal
                          {...modalOptions}
                          isLoading={isLoading}
                          modalProps={{
                            closeOnEsc: false,
                            closeOnOverlayClick: false,
                          }}
                          modalHeader={
                            ["flowers"].includes(condolencesWatch) ? (
                              <Heading
                                fontSize={"xl"}
                                color="#2D3748"
                                fontWeight={700}
                                lineHeight="28px"
                              >
                                Do you want to send flowers?
                              </Heading>
                            ) : ["chocolate"].includes(condolencesWatch) ? (
                              <Heading
                                fontSize={"xl"}
                                color="#2D3748"
                                fontWeight={700}
                                lineHeight="28px"
                              >
                                Do you want to send chocolate?
                              </Heading>
                            ) : ["note"].includes(condolencesWatch) ? (
                              <Heading
                                fontSize={"xl"}
                                color="#2D3748"
                                fontWeight={700}
                                lineHeight="28px"
                              >
                                Do you want to send note?
                              </Heading>
                            ) : ["all"].includes(condolencesWatch) ? (
                              <Heading
                                fontSize={"xl"}
                                color="#2D3748"
                                fontWeight={700}
                                lineHeight="28px"
                              >
                                Do you want to send a note, flowers and chocolates?
                              </Heading>
                            ) : (
                              <Text
                                fontSize={18}
                                color="#2D3748"
                                fontWeight={700}
                                lineHeight="28px"
                              >
                                Confirm withdrawal request
                              </Text>
                            )
                          }
                          modalBody={
                            ["flowers"].includes(condolencesWatch) ? (
                              <VStack alignItems="flex-start">
                                <Text
                                  fontSize={{ base: "md", sm: "xl" }}
                                  color="#2D3748"
                                  fontWeight={400}
                                  lineHeight="20px"
                                  pb={5}
                                >
                                  You will send comforting flowers to
                                  <Text as={"span"} fontWeight={600} fontSize={"lg"}>
                                    {` ${inheritorWithDetails?.firstName}`}.
                                  </Text>
                                </Text>
                                <Flowers h={"full"} w={"full"} />
                              </VStack>
                            ) : ["chocolate"].includes(condolencesWatch) ? (
                              <VStack alignItems="flex-start">
                                <Text
                                  fontSize={{ base: "md", sm: "xl" }}
                                  color="#2D3748"
                                  fontWeight={400}
                                  lineHeight="20px"
                                  pb={5}
                                >
                                  You will send comforting chocolate to
                                  <Text as={"span"} fontWeight={600} fontSize={"lg"}>
                                    {` ${inheritorWithDetails?.firstName}`}.
                                  </Text>
                                </Text>
                                <Chocolate h={"full"} w={"full"} />
                              </VStack>
                            ) : ["note"].includes(condolencesWatch) ? (
                              <VStack alignItems="flex-start">
                                <Text
                                  fontSize={{ base: "md", sm: "xl" }}
                                  color="#2D3748"
                                  fontWeight={400}
                                  lineHeight="20px"
                                  pb={5}
                                >
                                  You will send comforting note to
                                  <Text as={"span"} fontWeight={600} fontSize={"lg"}>
                                    {` ${inheritorWithDetails?.firstName}`}.
                                  </Text>
                                </Text>
                                <Note h={"full"} w={"full"} />
                              </VStack>
                            ) : ["all"].includes(condolencesWatch) ? (
                              <VStack alignItems="flex-start">
                                <Text
                                  fontSize={{ base: "md", sm: "xl" }}
                                  color="#2D3748"
                                  fontWeight={400}
                                  lineHeight="20px"
                                  pb={5}
                                >
                                  You will send comforting flowers, note, and chocolates to
                                  <Text as={"span"} fontWeight={600} fontSize={"lg"}>
                                    {` ${inheritorWithDetails?.firstName}`}.
                                  </Text>
                                </Text>
                                <NoteChocolateFlowers h={"full"} w={"full"} />
                              </VStack>
                            ) : (
                              <Text
                                fontSize={18}
                                color="#2D3748"
                                fontWeight={700}
                                lineHeight="28px"
                              >
                                Confirm withdrawal request
                              </Text>
                            )
                          }
                          modalConfirmButton="Confirm"
                        />
                        <Box pl={4} w={"lg"} display={"flex"}>
                          <CustomSelect
                            isEditing={isPat || isChris || isVik ? false : true}
                            registerOptions={{ required: true }}
                            isLoading={isLoading}
                            name="condolences"
                            placeholder="Choose a comforting gift"
                            closeMenuOnSelect
                            defaultValue={[
                              {
                                value: "flowers",
                                label: (
                                  <HStack w={"full"}>
                                    <Flowers h={{ base: "50px", sm: "40px" }} w="50px" />
                                    <Text w={"full"} fontWeight={600} fontSize={"md"}>
                                      Flowers
                                    </Text>
                                  </HStack>
                                ),
                                tagColor: "transparent",
                                textColor: "transparent",
                              },
                              {
                                value: "chocolate",
                                label: (
                                  <HStack w={"full"}>
                                    <Chocolate h={{ base: "50px", sm: "40px" }} w="50px" />
                                    <Text w={"full"} fontWeight={600} fontSize={"md"}>
                                      Chocolate
                                    </Text>
                                  </HStack>
                                ),
                                tagColor: "transparent",
                                textColor: "transparent",
                              },
                              {
                                value: "note",
                                label: (
                                  <HStack w={"full"}>
                                    <Note h={{ base: "50px", sm: "40px" }} w="50px" />
                                    <Text w={"full"} fontWeight={600} fontSize={"md"}>
                                      Note
                                    </Text>
                                  </HStack>
                                ),
                                tagColor: "transparent",
                                textColor: "transparent",
                              },
                              {
                                value: "all",
                                label: (
                                  <HStack w={"full"}>
                                    <NoteChocolateFlowers
                                      h={{ base: "50px", sm: "40px" }}
                                      w="50px"
                                    />
                                    <Text w={"full"} fontWeight={600} fontSize={"md"} pt={0}>
                                      Chocolate, flowers & note
                                    </Text>
                                  </HStack>
                                ),
                                tagColor: "transparent",
                                textColor: "transparent",
                              },
                            ].find(a => a.value === condolencesDefaultValues.condolences)}
                            options={
                              [
                                {
                                  value: "flowers",
                                  label: (
                                    <HStack w={"full"}>
                                      <Flowers h={{ base: "50px", sm: "40px" }} w="50px" />
                                      <Text w={"full"} fontWeight={600} fontSize={"md"}>
                                        Flowers
                                      </Text>
                                    </HStack>
                                  ) as unknown,
                                  tagColor: "transparent",
                                  textColor: "transparent",
                                },
                                {
                                  value: "chocolate",
                                  label: (
                                    <HStack w={"full"}>
                                      <Chocolate h={{ base: "50px", sm: "40px" }} w="50px" />
                                      <Text w={"full"} fontWeight={600} fontSize={"md"}>
                                        Chocolate
                                      </Text>
                                    </HStack>
                                  ) as unknown,
                                  tagColor: "transparent",
                                  textColor: "transparent",
                                },
                                {
                                  value: "note",
                                  label: (
                                    <HStack w={"full"}>
                                      <Note h={{ base: "50px", sm: "40px" }} w="50px" />
                                      <Text w={"full"} fontWeight={600} fontSize={"md"}>
                                        Note
                                      </Text>
                                    </HStack>
                                  ) as unknown,
                                  tagColor: "transparent",
                                  textColor: "transparent",
                                },
                                {
                                  value: "all",
                                  label: (
                                    <HStack w={"full"}>
                                      <NoteChocolateFlowers
                                        h={{ base: "50px", sm: "40px" }}
                                        w="50px"
                                      />
                                      <Text w={"full"} fontWeight={600} fontSize={"md"} pt={0}>
                                        Chocolate, flowers & note
                                      </Text>
                                    </HStack>
                                  ) as unknown,
                                  tagColor: "transparent",
                                  textColor: "transparent",
                                },
                              ] as StatusSelectOption[]
                            }
                            inputGroupProps={{
                              sx: {
                                "& .react-select": {
                                  width: "100%",
                                  transform: "translateX(-1.3rem)",
                                  "& [data-readonly=true]": {
                                    "& div:nth-of-type(2)": {
                                      display: "none",
                                    },
                                  },
                                  "& > div": {
                                    minHeight: "",
                                  },
                                },
                              },
                            }}
                          />
                          <Box h={30}>
                            <Button
                              isDisabled={isPat || isChris || isVik || false}
                              onClick={() => openModal(() => {})}
                              size={"lg"}
                              variant="whiteLabelOutline"
                              _loading={{
                                "&:hover": {
                                  backgroundColor: "whiteLabel.primary",
                                },
                              }}
                            >
                              Send
                            </Button>{" "}
                          </Box>
                        </Box>
                        {isPat ? (
                          <Text
                            fontSize={14}
                            color="green.500"
                            fontWeight={600}
                            lineHeight="16px"
                            pt={5}
                          >
                            A condolence gift is on its way to {inheritorWithDetails.firstName}!
                          </Text>
                        ) : isChris ? (
                          <Text
                            fontSize={14}
                            color="green.500"
                            fontWeight={600}
                            lineHeight="16px"
                            pt={5}
                          >
                            A condolence gift is on its way to {inheritorWithDetails.firstName}!
                          </Text>
                        ) : isVik ? (
                          <Text
                            fontSize={14}
                            color="green.500"
                            fontWeight={600}
                            lineHeight="16px"
                            pt={5}
                          >
                            A condolence gift is on its way to {inheritorWithDetails.firstName}!
                          </Text>
                        ) : (
                          <Text fontSize={14} color="#4A5568" lineHeight="16px" pt={5}>
                            Once a case is confirmed, send a thoughtful gift to express condolences
                            and provide comfort during their time of loss.
                          </Text>
                        )}
                      </FormProvider>
                      {isPat ? (
                        <HStack gap={10} flexDirection={isMobile ? "column" : "row"} pt={4}>
                          <VStack>
                            <HStack w={"full"}>
                              <Chocolate h={{ base: "50px", sm: "40px" }} w="50px" />
                              <Text w={"full"} fontWeight={600} fontSize={"md"}>
                                Chocolate
                              </Text>
                            </HStack>
                            <Link to={"https://www.fedex.com/en-us/home.html"}>
                              <Text color="green.500" textDecoration={"underline"}>
                                Arriving on 06.16.2024
                              </Text>
                            </Link>
                          </VStack>
                          <VStack>
                            <HStack w={"full"}>
                              <Flowers h={{ base: "50px", sm: "40px" }} w="50px" />
                              <Text w={"full"} fontWeight={600} fontSize={"md"}>
                                Flowers
                              </Text>
                            </HStack>
                            <Link to={"https://www.fedex.com/en-us/home.html"}>
                              <Text color="green.500" textDecoration={"underline"}>
                                Arriving on 06.17.2024
                              </Text>
                            </Link>
                          </VStack>
                          <VStack>
                            <HStack w={"full"}>
                              <Note h={{ base: "50px", sm: "40px" }} w="50px" />
                              <Text w={"full"} fontWeight={600} fontSize={"md"}>
                                Note
                              </Text>
                            </HStack>
                            <Link to={"https://www.fedex.com/en-us/home.html"}>
                              <Text color="green.500" textDecoration={"underline"}>
                                Arriving on 06.17.2024
                              </Text>
                            </Link>
                          </VStack>
                        </HStack>
                      ) : isChris ? (
                        <HStack gap={10} flexDirection={isMobile ? "column" : "row"} pt={4}>
                          <VStack>
                            <HStack w={"full"}>
                              <Chocolate h={{ base: "50px", sm: "40px" }} w="50px" />
                              <Text w={"full"} fontWeight={600} fontSize={"md"}>
                                Chocolate
                              </Text>
                            </HStack>
                            <Link to={"https://www.fedex.com/en-us/home.html"}>
                              <Text color="green.500" textDecoration={"underline"}>
                                Arriving on 06.18.2024
                              </Text>
                            </Link>
                          </VStack>
                          <VStack>
                            <HStack w={"full"}>
                              <Flowers h={{ base: "50px", sm: "40px" }} w="50px" />
                              <Text w={"full"} fontWeight={600} fontSize={"md"}>
                                Flowers
                              </Text>
                            </HStack>
                            <Link to={"https://www.fedex.com/en-us/home.html"}>
                              <Text color="green.500" textDecoration={"underline"}>
                                Arriving on 06.17.2024
                              </Text>
                            </Link>
                          </VStack>
                          <VStack>
                            <HStack w={"full"}>
                              <Note h={{ base: "50px", sm: "40px" }} w="50px" />
                              <Text w={"full"} fontWeight={600} fontSize={"md"}>
                                Note
                              </Text>
                            </HStack>
                            <Link to={"https://www.fedex.com/en-us/home.html"}>
                              <Text color="green.500" textDecoration={"underline"}>
                                Arriving on 06.17.2024
                              </Text>
                            </Link>
                          </VStack>
                        </HStack>
                      ) : isVik ? (
                        <HStack gap={10} flexDirection={isMobile ? "column" : "row"} pt={4}>
                          <VStack>
                            <HStack w={"full"}>
                              <Flowers h={{ base: "50px", sm: "40px" }} w="50px" />
                              <Text w={"full"} fontWeight={600} fontSize={"md"}>
                                Flowers
                              </Text>
                            </HStack>
                            <Link to={"https://www.fedex.com/en-us/home.html"}>
                              <Text color="green.500" textDecoration={"underline"}>
                                Arriving on 06.15.2024
                              </Text>
                            </Link>
                          </VStack>
                        </HStack>
                      ) : null}
                    </>
                  )}
                </VStack>
              </Stack>
            ) : null}
          </CardWrapper>
        </Stack>
      </form>
    </FormProvider>
  );
}
