import { useCallback } from "react";
import { useDocumentStore } from "@shared/store/document.store";
import { useAdminNotifyInfoStore } from "@store/adminNotifyInfo.store";
import { useInheritorStore } from "@store/inheritor.store";
import { useInstitutionStore } from "@store/institution.store";

export function useClearAllStores() {
  const { clearPersistedStore: clearAdminNotifyInfoStore } = useAdminNotifyInfoStore(state => ({
    clearPersistedStore: state.clearPersistedStore,
  }));
  const { clearPersistedStore: clearInheritorStore } = useInheritorStore(state => ({
    clearPersistedStore: state.clearPersistedStore,
  }));
  const { clearPersistedStore: clearInstitutionStore } = useInstitutionStore(state => ({
    clearPersistedStore: state.clearPersistedStore,
  }));
  const { clearPersistedStore: clearDocumentStore } = useDocumentStore(state => ({
    clearPersistedStore: state.clearPersistedStore,
  }));

  const clearAllStores = useCallback(() => {
    clearAdminNotifyInfoStore();
    clearInheritorStore();
    clearInstitutionStore();
    clearDocumentStore();
  }, [clearAdminNotifyInfoStore, clearInheritorStore, clearInstitutionStore, clearDocumentStore]);

  return { clearAllStores };
}
