import { Skeleton, VStack } from "@chakra-ui/react";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";

export function DeceasedAccountLoading() {
  const isMobile = useIsMobile();

  return (
    <>
      {Array.from({ length: 4 }).map((_, idx) => (
        <VStack
          key={`loading-card-${idx}`}
          alignItems="flex-start"
          gap={2}
          width={isMobile ? "100%" : "25%"}
          justifyContent="space-evenly"
        >
          <Skeleton {...{ startColor: "#c0eeee", endColor: "#79d5d5" }} w="full" height="32px" />
        </VStack>
      ))}
    </>
  );
}
