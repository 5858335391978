import useSWR from "swr";
import { fetchSSN } from "@services/inheritor.service";

export function useRetrieveSSN(id: string, isInheritor: boolean) {
  const { data, error, isLoading, mutate } = useSWR(
    isInheritor ? `/inheritors/${id}/ssn` : `/deceased-persons/${id}/ssn`,
    fetchSSN,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 120000,
      shouldRetryOnError: false,
    }
  );

  return {
    isLoading,
    ssn: data,
    error: error as Error,
    mutate: () => mutate(undefined, { revalidate: true }),
  };
}
