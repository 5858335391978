import useSWR from "swr";
import { useAuth0 } from "@auth0/auth0-react";
import { AgentRoles, GetAgentRolesResponse } from "@trustribbon/ribbon-ec-types";
import { fetchAgentRoles } from "@services/agent.service";

export function usePermission() {
  const { isAuthenticated } = useAuth0();
  const { data, isLoading } = useSWR<GetAgentRolesResponse>(
    isAuthenticated ? "/me/roles" : null,
    fetchAgentRoles,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const isPermitted = (permittedRoles: AgentRoles) => {
    return permittedRoles.some(role => data?.agentRoles.includes(role));
  };

  return { agentRoles: data, isLoading, isPermitted };
}
