import useSWR from "swr";
import { fetchDeceased } from "@services/inheritor.service";

export function useDeceasedDetails(deceasedId: string) {
  const { data, error, isLoading, mutate } = useSWR(
    `/deceased-persons/${deceasedId}/details`,
    fetchDeceased,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 120000,
      shouldRetryOnError: false,
    }
  );

  return {
    isLoading,
    deceasedDetails: data,
    error: error as Error,
    mutate: () => mutate(undefined, { revalidate: true }),
  };
}
