import {
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MdMoreVert, MdOutlineRestorePage } from "react-icons/md";
import { Link } from "react-router-dom";

interface UploadedDocumentMenuItem {
  label: string;
  onClick: () => void;
  variant?: "default" | "danger";
}

export interface UploadedDocumentProps {
  title: string;
  subtitle?: string;
  size?: "small" | "medium" /*  | 'large' */;
  thumbnail?: JSX.Element;
  thumbnailSize?: "small" | "medium" | "large";
  archived?: boolean;
  menuItems?: UploadedDocumentMenuItem[];
  deathCertificateUrl?: string;
  governmentIdUrl?: string;
}

export function UploadedDocument({
  title,
  size = "small",
  thumbnail,
  thumbnailSize = "large",
  archived = false,
  menuItems = [],
  deathCertificateUrl,
  governmentIdUrl,
}: UploadedDocumentProps) {
  const container = {
    padding: {
      small: "6px 12px",
      medium: "4px 12px",
    },
  };

  const titleStyles = {
    small: {
      fontSize: "12px",
      lineHeight: "16px",
      color: archived ? "gray.500" : "gray.700",
    },
    medium: {
      fontSize: "14px",
      lineHeight: "20px",
      color: "gray.800",
    },
  };

  const thumbnailSizeStyle = {
    small: { width: "32px", height: "32px" },
    medium: { width: "56px", height: "56px" },
    large: { width: "64px", height: "64px" },
  };

  return (
    <HStack
      p={container.padding[size]}
      gap="8px"
      flex="1 0 auto"
      borderRadius="8px"
      border="1px solid var(--chakra-colors-gray-300, #CBD5E0)"
      background="var(--chakra-colors-white, #FFF)"
      justify="space-between"
      w="full"
    >
      <HStack gap="8px">
        {thumbnail && (
          <Stack
            w={thumbnailSizeStyle[thumbnailSize].width}
            h={thumbnailSizeStyle[thumbnailSize].height}
            align="center"
            justify="center"
            bgColor="blackAlpha.50"
          >
            {thumbnail}
          </Stack>
        )}

        <VStack align="flex-start" gap="0">
          <Text
            color={titleStyles[size].color}
            textOverflow="ellipsis"
            fontSize={titleStyles[size].fontSize}
            lineHeight={titleStyles[size].lineHeight}
            fontWeight="600"
            flex="1 0 auto"
          >
            {title}
          </Text>
        </VStack>
      </HStack>

      {!archived && (
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<MdMoreVert size="24px" color="gray.800" />}
            variant="ghost"
          />

          {menuItems.length > 0 && (
            <MenuList>
              {menuItems.map(({ label, onClick, variant }) => {
                const menuItem = (
                  <MenuItem
                    key={label}
                    fontWeight="600"
                    fontSize="14px"
                    color={variant === "danger" ? "red.500" : "gray.800"}
                    onClick={onClick}
                  >
                    {label}
                  </MenuItem>
                );

                // TODO: review this implementation later
                if (
                  deathCertificateUrl &&
                  label === "View document" &&
                  title === "Death Certificate"
                ) {
                  return (
                    <Link key={deathCertificateUrl} to={deathCertificateUrl} target="_blank">
                      {menuItem}
                    </Link>
                  );
                }
                if (governmentIdUrl && label === "View document" && title === "Government ID") {
                  return (
                    <Link key={governmentIdUrl} to={governmentIdUrl} target="_blank">
                      {menuItem}
                    </Link>
                  );
                }
                return menuItem;
              })}
            </MenuList>
          )}
        </Menu>
      )}
      {archived && (
        <IconButton
          icon={<MdOutlineRestorePage size="24px" color="gray.800" />}
          variant="ghost"
          aria-label="restore"
        />
      )}
    </HStack>
  );
}
