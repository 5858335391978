import useSWR from "swr";
import { fetchInheritor } from "@services/inheritor.service";

export function useInheritorDetails(inheritorId: string) {
  const { data, error, isLoading, mutate } = useSWR(
    `/inheritors/${inheritorId}/deceased-persons-details`,
    fetchInheritor,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 120000,
      shouldRetryOnError: false,
    }
  );

  return {
    isLoading,
    inheritorWithDetails: data,
    error: error as Error,
    mutate: () => mutate(undefined, { revalidate: true }),
  };
}
