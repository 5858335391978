import { PreVerifyDocumentResponse } from "@trustribbon/ribbon-ec-types";

export type EnhancedFile = File & {
  preview: string;
} & PreVerifyDocumentResponse;

export const AccountTransferStatus = {
  UnderReview: "Under Review",
  Confirmed: "Confirmed information",
  Submitted: "Submitted - Not started",
  NeedsInfo: "Needs more information",
  TransferCompleted: "Transfer Completed",
};

export const enum FinancialAccounts {
  Checking = "Checking",
  Savings = "Savings",
  TraditionalIra = "Traditional IRA",
  RothIra = "Roth IRA",
  CD = "Certificate of Deposit",
}
