import { useState } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";
import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputProps,
  InputRightElement,
  InputGroup,
  FormControlProps,
  InputGroupProps,
  FormLabelProps,
} from "@chakra-ui/react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { Button } from "@shared/components/Button/Button.component";
import { FormMaskedInput } from "./FormMaskedInput.component";
import { MaskedInputProps } from "react-text-mask";

export type FormInputProps = {
  label: string;
  name: string;
  registerOptions: RegisterOptions;
  mask?: (string | RegExp)[];
  formControlProps?: FormControlProps;
  inputGroupProps?: InputGroupProps;
  labelProps?: FormLabelProps;
  maskOptions?: MaskedInputProps;
} & InputProps;

export function FormInput({
  label,
  name,
  registerOptions = {},
  mask,
  type,
  formControlProps,
  inputGroupProps,
  labelProps,
  maskOptions,
  ...rest
}: FormInputProps) {
  const [showPassword, setShowPassword] = useState(false);
  const { register, formState } = useFormContext();

  const { errors } = formState;
  const error = errors[name];

  return (
    <FormControl isInvalid={!!error} variant="floating" {...formControlProps}>
      <InputGroup {...inputGroupProps}>
        {mask ? (
          <FormMaskedInput
            name={name}
            mask={mask}
            guide={false}
            placeholder=" "
            placeholderChar={"\u2000"}
            showMask={true}
            type={type === "password" && !showPassword ? "password" : "text"}
            maskOptions={maskOptions}
            {...rest}
          />
        ) : (
          <Input
            colorScheme="whiteLabel"
            variant="whiteLabel"
            placeholder=" "
            type={type === "password" && !showPassword ? "password" : "text"}
            {...rest}
            {...register(name, { ...registerOptions })}
          />
        )}
        <FormLabel fontSize="16px" fontWeight={400} lineHeight="20px" {...labelProps}>
          {label}
        </FormLabel>

        {type === "password" && (
          <InputRightElement width="3rem" top="50%" transform="translateY(-50%)">
            <Button
              variant="whiteLabelGhost"
              h="1.75rem"
              size="sm"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <HiEyeOff /> : <HiEye />}
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage color="red">{error?.message as string}</FormErrorMessage>
    </FormControl>
  );
}
