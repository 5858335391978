import { useInheritorStore } from "@store/inheritor.store";
import { useDeceasedDetails } from "@hooks/useDeceasedDetails.hook";
import {
  DeceasedAccountsCard,
  FinancialAccountWithAllocationAndSelect,
} from "./components/DeceasedAccountsCard/DeceasedAccounts.component";
import {
  OtherInheritor,
  OtherInheritors,
} from "./components/OtherInheritors/OtherInheritors.component";
import { DeceasedCard } from "./components/DeceasedCard/DeceasedCard.component";

export function DeceasedOverview() {
  const { currentDeceased } = useInheritorStore();
  const { deceasedDetails, isLoading, mutate } = useDeceasedDetails(currentDeceased?.id ?? "");

  return (
    <>
      <DeceasedCard deceasedDetails={deceasedDetails} isLoading={isLoading} mutate={mutate} />
      <DeceasedAccountsCard
        financialAccounts={
          deceasedDetails?.financialAccounts as FinancialAccountWithAllocationAndSelect[]
        }
        isLoading={isLoading}
        mutate={mutate}
      />
      <OtherInheritors
        isLoading={isLoading}
        otherInheritors={deceasedDetails?.inheritors as unknown as OtherInheritor[]}
      />
    </>
  );
}
