import { useCallback } from "react";
import {
  Tag,
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Cell, CellContext, createColumnHelper } from "@tanstack/react-table";
import {
  AgentDual,
  DeceasedPersonDual,
  FinancialAccountDual,
  InheritorDual,
  ListInheritanceCasesResponse,
} from "@trustribbon/ribbon-ec-types";
import { DeceasedPersonWithCaseId, useInheritorStore } from "@store/inheritor.store";
import {
  ACCOUNTS_TYPE_STYLE_MAP,
  CASE_STATUS_STYLE_MAP,
  ROUTES,
  USER_ROLES,
} from "@config/constants.config";
import { useInheritanceCases } from "@hooks/useInheritanceCases";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { Button } from "@shared/components/Button/Button.component";
import { DataTable } from "@shared/components/DataTable/DataTable.component";
import { useAdminNotifyInfoStore } from "@store/adminNotifyInfo.store";
import { usePermission } from "@hooks/usePermission.hook";
import { AccountTransferStatus } from "@src/types";

const columnHelper = createColumnHelper<ListInheritanceCasesResponse>();

const inheritorComponent = (info: CellContext<ListInheritanceCasesResponse, string>) => {
  const value = info.getValue();
  const { textColor, bgColor } = CASE_STATUS_STYLE_MAP[value] || CASE_STATUS_STYLE_MAP.default;

  return (
    <Tag
      textColor={textColor}
      bgColor={bgColor}
      textTransform="capitalize"
      fontWeight={700}
      fontSize={14}
      lineHeight="16px"
    >
      {["submitted"].includes(value)
        ? AccountTransferStatus.Submitted
        : ["needs_info"].includes(value)
          ? AccountTransferStatus.NeedsInfo
          : ["under_review"].includes(value)
            ? AccountTransferStatus.UnderReview
            : ["transfer_completed"].includes(value)
              ? AccountTransferStatus.TransferCompleted
              : ["confirmed"].includes(value)
                ? AccountTransferStatus.Confirmed
                : "Unknown"}{" "}
    </Tag>
  );
};

const FinancialAccountComponent = (
  info: CellContext<ListInheritanceCasesResponse, FinancialAccountDual<"client">[]>
) => {
  const accounts = info.getValue() ?? [];
  const uniqueAccountTypes = [
    ...new Set(accounts?.map(account => account?.accountType?.toLowerCase() ?? "unknown")),
  ];

  return (
    <HStack spacing={2}>
      {uniqueAccountTypes.length > 0 ? (
        uniqueAccountTypes
          .filter(type => type)
          .map(accountType => {
            const { textColor, bgColor, label } =
              ACCOUNTS_TYPE_STYLE_MAP[accountType as keyof typeof ACCOUNTS_TYPE_STYLE_MAP] ||
              ACCOUNTS_TYPE_STYLE_MAP.default;

            return (
              <Tag
                key={accountType}
                textColor={textColor}
                bgColor={bgColor}
                textTransform="capitalize"
                fontWeight={700}
                fontSize={14}
                lineHeight="16px"
              >
                {label}
              </Tag>
            );
          })
      ) : (
        <Tag
          bgColor={`#E2E8F0`}
          textColor={`#4A5568
          
        `}
          fontWeight={700}
          fontSize={14}
          lineHeight="16px"
        >
          Not Specified
        </Tag>
      )}
    </HStack>
  );
};

const columns = [
  columnHelper.accessor("inheritor", {
    cell: (info: CellContext<ListInheritanceCasesResponse, InheritorDual<"client">>) => {
      const inheritorName = info.getValue()
        ? `${info.getValue().firstName} ${info.getValue().lastName}`
        : "N/A";

      return (
        <Button
          variant="whiteLabelLink"
          px={0}
          textDecoration="underline"
          fontSize={14}
          fontWeight={700}
          lineHeight="20px"
          color="#319795"
          whiteSpace="nowrap"
        >
          {inheritorName}
        </Button>
      );
    },
    header: "INHERITOR",
    meta: { clickable: true },
  }),
  columnHelper.accessor("deceasedPerson", {
    cell: (info: CellContext<ListInheritanceCasesResponse, DeceasedPersonDual<"client">>) => {
      const deceasedName = info.getValue()
        ? `${info.getValue().firstName} ${info.getValue().lastName}`
        : "N/A";

      return (
        <Button
          variant="whiteLabelLink"
          px={0}
          textDecoration="underline"
          fontSize={14}
          fontWeight={700}
          lineHeight="20px"
          color="#319795"
        >
          {deceasedName}
        </Button>
      );
    },
    header: "DECEASED",
    meta: { clickable: true },
  }),
  columnHelper.accessor("agent", {
    cell: (info: CellContext<ListInheritanceCasesResponse, AgentDual<"client">>) => {
      const agentName = info.getValue()
        ? `${info.getValue().firstName} ${info.getValue().lastName}`
        : "Not assigned";

      return (
        <Text fontSize={14} fontWeight={400} lineHeight="20px">
          {agentName}
        </Text>
      );
    },
    header: "ASSIGNED AGENT",
  }),
  columnHelper.accessor("financialAccounts", {
    cell: FinancialAccountComponent,
    header: "ACCOUNTS",
  }),
  columnHelper.accessor("status", {
    cell: inheritorComponent,
    header: "INHERITANCE STATUS",
  }),
];

export function InheritorsTemplate() {
  const { inheritanceCases, isLoading, error } = useInheritanceCases();
  const { setCurrentInheritor, setCurrentDeceased, setIsInheritor } = useInheritorStore();
  const { clearPersistedStore } = useAdminNotifyInfoStore();
  const { navigate } = useNavigation();
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { isPermitted } = usePermission();

  const handleCellClick = useCallback(
    (
      cell: Cell<
        ListInheritanceCasesResponse,
        DeceasedPersonDual<"client"> | InheritorDual<"client">
      >
    ) => {
      const currentCell = cell as unknown as Cell<
        ListInheritanceCasesResponse[0],
        DeceasedPersonDual<"client"> | InheritorDual<"client">
      >;
      const currentRow = currentCell.row.original;
      const isInheritor = currentCell.column.id === "inheritor";

      setCurrentInheritor(currentRow.inheritor as InheritorDual<"client">);
      setCurrentDeceased(currentRow.deceasedPerson as DeceasedPersonWithCaseId);

      setIsInheritor(isInheritor);

      const rowId = currentCell.row.original.inheritor?.id ?? "";

      if (isInheritor) {
        return navigate(ROUTES.INHERITORS_VIEW.replace(":id", rowId));
      }

      return navigate(ROUTES.INHERITORS_DECEASEDS_VIEW.replace(":id", rowId));
    },
    [navigate, setCurrentDeceased, setCurrentInheritor, setIsInheritor]
  );

  const handleAddCase = () => {
    clearPersistedStore();

    return navigate(ROUTES.INHERITORS_ADD);
  };

  const hasAddCasePermission = isPermitted([USER_ROLES.ORG_ADMIN]);

  return (
    <Stack position="relative" h="full" w="full" alignItems="flex-start" gap={6} p={9} pt={10}>
      <VStack w="full" gap={7} px={1}>
        <HStack w="full" justifyContent="space-between" alignItems="flex-start">
          <Heading variant="h1" fontSize="24px" color="gray.700" lineHeight="36px" fontWeight={600}>
            Inheritors
          </Heading>
          <Flex gap={4} justifyContent="flex-end" flexWrap="wrap">
            <Button
              isDisabled={true}
              variant="whiteLabelOutline"
              _hover={{}}
              width={isDesktop ? "auto" : "100%"}
            >
              Export to CRM
            </Button>
            <Button
              variant="whiteLabel"
              width={isDesktop ? "auto" : "100%"}
              onClick={handleAddCase}
              display={hasAddCasePermission ? "flex" : "none"}
            >
              + Add Case
            </Button>
          </Flex>
        </HStack>
      </VStack>
      <DataTable
        columns={columns}
        data={inheritanceCases as unknown as ListInheritanceCasesResponse[]}
        handleCellClick={handleCellClick}
        isLoading={isLoading}
        error={error}
      />
    </Stack>
  );
}
