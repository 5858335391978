import { As, Box, HStack, Icon, Square, Stack, Text } from "@chakra-ui/react";
import { FiArrowDownRight, FiArrowUpRight } from "react-icons/fi";

interface Props {
  icon: As;
  label: string;
  value: string;
  delta: {
    value: string;
    isUpwardsTrend: boolean;
  };
}
export const Stat = (props: Props) => {
  const { label, icon, delta, ...boxProps } = props;
  return (
    <Box
      px={{ base: "4", md: "6" }}
      py={{ base: "5", md: "6" }}
      borderRadius="lg"
      boxShadow="sm"
      bg={"white"}
      {...boxProps}
    >
      <Stack>
        <Stack direction="row">
          <HStack>
            <Square size="12" bg="bg.accent.subtle" borderRadius="md">
              <Icon as={icon} boxSize="6" color="fg.accent.default" />
            </Square>
            <Text fontWeight={600} color="gray.500">
              {label}
            </Text>
          </HStack>
        </Stack>
        <Stack pl={4}>
          <HStack spacing="1" fontWeight="medium">
            <Icon
              color={delta.isUpwardsTrend ? "green.500" : "red.500"}
              as={delta.isUpwardsTrend ? FiArrowUpRight : FiArrowDownRight}
              boxSize="5"
            />
            <Text color={delta.isUpwardsTrend ? "green.500" : "red.500"} fontWeight={600}>
              {delta.value}
            </Text>
            <Text color="fg.muted">this month</Text>
          </HStack>
        </Stack>
      </Stack>
    </Box>
  );
};
