import useSWR from "swr";
import { useAuth0 } from "@auth0/auth0-react";
import { GetInstitutionBySubdomainResponse } from "@trustribbon/ribbon-ec-types";
import { fetchInstitutionConfig } from "@services/institutionConfig.service";

export function useInstitutionData() {
  const { isAuthenticated } = useAuth0();
  const { data, isLoading } = useSWR<GetInstitutionBySubdomainResponse>(
    isAuthenticated ? "/me/institution" : null,
    fetchInstitutionConfig,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return { institution: data, isLoading };
}
