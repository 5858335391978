import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import {
  DocumentType,
  CreateInheritanceCaseRequest,
  CreateInheritanceCaseResponse,
} from "@trustribbon/ribbon-ec-types";
import { EnhancedFile } from "@shared/types";
import { DeceasedPersonStore, InheritorStore } from "@shared/config/schema.validator";
import { adminNotifyInfo } from "@services/adminNotifyInfo.service";
import { getUploadUrl, uploadDocument } from "@services/document.service";

interface AdminNotifyInfoStore {
  loading: boolean;
  deceasedInfo: DeceasedPersonStore | null;
  inheritorInfo: InheritorStore | null;
  notifyResponse: CreateInheritanceCaseResponse | null;
  setDeceasedInfo: (deceasedInfo: DeceasedPersonStore) => void;
  setInheritorInfo: (inheritorInfo: InheritorStore) => void;
  createInheritanceCase: (
    notifyInfo: CreateInheritanceCaseRequest,
    documents: [DocumentType, EnhancedFile | null][],
    institutionSubdomain: string
  ) => Promise<CreateInheritanceCaseResponse>;
  clearPersistedStore: () => void;
}

const uploadAdminDocumentsRecursively = async (
  documents: [DocumentType, EnhancedFile | null][],
  notifiedInfo: CreateInheritanceCaseResponse,
  institutionSubdomain: string
) => {
  if (documents.length === 0) {
    return;
  }

  const [currentDocumentEntry, ...restOfDocuments] = documents || [];
  const [documentType, file] = currentDocumentEntry || [];

  if (file) {
    const signedUploadURL = await getUploadUrl({
      deceasedPersonId: notifiedInfo.deceasedPerson.id,
      inheritorId: notifiedInfo.inheritor.id,
      filename: `${file.name}`,
      documentType,
      institutionSubdomain: institutionSubdomain,
    });

    await uploadDocument(signedUploadURL, file);

    if (restOfDocuments.length > 0) {
      await uploadAdminDocumentsRecursively(restOfDocuments, notifiedInfo, institutionSubdomain);
    }
  }
};

export const useAdminNotifyInfoStore = create(
  devtools(
    persist<AdminNotifyInfoStore>(
      set => ({
        loading: false,
        deceasedInfo: null,
        inheritorInfo: null,
        notifyResponse: null,
        setInheritorInfo: inheritorInfo => set({ inheritorInfo }),
        setDeceasedInfo: deceasedInfo => set({ deceasedInfo }),
        createInheritanceCase: async (notifyInfo, documentEntries, institutionSubdomain) => {
          set({ loading: true });

          const notifyResponse = await adminNotifyInfo(notifyInfo);
          await uploadAdminDocumentsRecursively(
            documentEntries,
            notifyResponse,
            institutionSubdomain
          );

          set({ loading: false, notifyResponse });

          return notifyResponse;
        },
        clearPersistedStore: () => {
          set({ loading: false, deceasedInfo: null, inheritorInfo: null, notifyResponse: null });
          useAdminNotifyInfoStore.persist.clearStorage();
        },
      }),
      {
        name: "adminNotifyInfo-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    {
      enabled: process.env.NODE_ENV === "development",
      anonymousActionType: "set adminNotifyInfo",
      store: "AdminNotifyInfoStore",
      name: "admin Notify Info",
    }
  )
);
