import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  ModalFooter,
} from "@chakra-ui/react";
import { Button } from "@shared/components/Button/Button.component";

interface IdleDialog {
  open: boolean;
  onLogout: () => void;
  onContinue: () => void;
}

export function IdleDialog({ open, onContinue, onLogout }: IdleDialog) {
  const { onClose } = useDisclosure();

  return (
    <Modal isOpen={open} onClose={onClose} size={{ base: "xs", md: "lg" }}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Are you still around?</ModalHeader>
        <ModalBody bg="transparent">
          <Text fontSize={{ base: "sm", md: "lg" }}>
            In order to protect your account, we are going to automatically log you out.
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onContinue}>
            I'm still here
          </Button>
          <Button variant="whiteLabelOutline" onClick={onLogout}>
            Log out
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
