import {
  Flex,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { Button } from "@shared/components/Button/Button.component";
import { FiPlus } from "react-icons/fi";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { CampaignLanesComponent } from "./components/CampaignLaneView/CampaignLanes.component";
import { CreateCampaignModalComponent } from "./components/CreateCampaignModal.component";

export function CampaignsTemplate() {
  const { isOpen, onClose, onOpen } = useDisclosure();

  function onClickCreateNewCampaignButton() {
    onOpen();
  }

  return (
    <Stack
      position="relative"
      h="full"
      w="full"
      alignItems="flex-start"
      gap={6}
      p={9}
      pt={10}
      maxW="calc(100vw - 280px)"
    >
      <VStack w="full" gap={7} px={1}>
        <HStack w="full" justifyContent="space-between" alignItems="flex-start">
          <Heading variant="h1" fontSize="24px" color="gray.700" lineHeight="36px" fontWeight={600}>
            Campaigns
          </Heading>
          <Flex gap={4} justifyContent="flex-end" flexWrap="wrap"></Flex>
        </HStack>

        {/* Campaign header */}
        <HStack justifyContent="space-between" w="full">
          <Button
            variant="whiteLabelOutline"
            leftIcon={<FiPlus />}
            onClick={onClickCreateNewCampaignButton}
          >
            Create new campaign
          </Button>

          <InputGroup width="280px">
            <InputLeftElement pointerEvents="none">
              <HiMagnifyingGlass />
            </InputLeftElement>
            <Input
              bgColor="white"
              borderColor="#A0AEC0"
              placeholder="Search by title or description"
            />
          </InputGroup>
        </HStack>

        <CampaignLanesComponent />

        <CreateCampaignModalComponent isOpen={isOpen} onClose={onClose} />
      </VStack>
    </Stack>
  );
}
