import useSWR from "swr";
import { fetchInheritanceCases } from "@services/inheritor.service";

export function useInheritanceCases() {
  const { data, error, isLoading, mutate } = useSWR("/inheritance-cases", fetchInheritanceCases, {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 120000,
  });

  return {
    mutate,
    isLoading,
    inheritanceCases: data ?? [],
    error: error as Error,
  };
}
