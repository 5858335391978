import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface NavigationStore {
  customHistory: string[];
  addToHistory: (route: string) => void;
  removeFromHistory: (route: string) => void;
}

export const useNavigationStore = create(
  devtools<NavigationStore>(
    set => ({
      customHistory: [window.location.pathname],
      addToHistory: route => set(state => ({ customHistory: [...state.customHistory, route] })),
      removeFromHistory: route =>
        set(state => ({ customHistory: state.customHistory.filter(item => item !== route) })),
    }),
    {
      enabled: process.env.NODE_ENV === "development",
      anonymousActionType: "set navigation",
      store: "navigationStore",
      name: "Navigation",
    }
  )
);
