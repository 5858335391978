import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import { Institution } from "@trustribbon/ribbon-ec-types";

interface InstitutionStore {
  currentInstitution: Institution | null;
  setCurrentInstitution: (institution: Institution) => void;
  clearPersistedStore: () => void;
}

export const useInstitutionStore = create(
  devtools(
    persist<InstitutionStore>(
      set => ({
        currentInstitution: null,
        setCurrentInstitution: institution => {
          set({ currentInstitution: institution });
        },
        clearPersistedStore: () => {
          set({ currentInstitution: null });
          useInstitutionStore.persist.clearStorage();
        },
      }),
      {
        name: "institutionStore-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    {
      enabled: process.env.NODE_ENV === "development",
      anonymousActionType: "set institution",
      store: "institutionStore",
      name: "Institution",
    }
  )
);
