import {
  Flex,
  Stepper as DefaultStepper,
  StepIndicator,
  StepStatus,
  StepTitle,
  StepSeparator,
  StepNumber,
  Step,
  StepIcon,
  FlexProps,
} from "@chakra-ui/react";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { useNavigation } from "@shared/hooks/useNavigation.hook";

type StepperProps = {
  steps: { label: string; path?: string }[];
  currentStepIndex?: number;
  stepperWrapperProps?: FlexProps;
};

export function Stepper({ steps, currentStepIndex, stepperWrapperProps }: Readonly<StepperProps>) {
  const isMobile = useIsMobile();
  const { currentPathname } = useNavigation();

  const internalCurrentStepIndex =
    currentStepIndex ?? steps.findIndex(step => step?.path === currentPathname);
  const activeStep = internalCurrentStepIndex === -1 ? steps.length : internalCurrentStepIndex;

  return (
    <Flex id="stepper-wrapper" flexDir="column" width="100%" {...stepperWrapperProps}>
      <DefaultStepper
        size="lg"
        index={activeStep}
        color="#2D3748"
        userSelect="none"
        orientation={isMobile ? "vertical" : "horizontal"}
        sx={{
          "& .chakra-step__title": {
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "24px",
            letterSpacing: "0.1px",
          },
          "& [class^='chakra-step__']": {
            transition: "background 0.3s ease, color 0.3s ease, border-color 0.3s ease",
          },
          "& .chakra-step__number": {
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "16px",
            letterSpacing: "0.1px",
          },
          "& .chakra-step__icon": {
            width: "18px",
            height: "18px",
          },
          "& .chakra-step__indicator": {
            width: "24px",
            height: "24px",
            borderWidth: "1px !important",
          },
          "& .chakra-step__separator": {
            background: "#A0AEC0 !important",
            height: "1px !important",
          },
          "& [data-status='active'] .chakra-step__indicator": {
            color: "#2D3748",
            borderColor: "#48BB78",
            borderRadius: "9999px",
            borderWidth: "2px !important",
            "& .chakra-step__number": {
              fontWeight: 700,
            },
          },
          "& [data-status='active'] .chakra-step__title": {
            fontWeight: 700,
          },
          "& [data-status='complete'] .chakra-step__separator": {
            background: "#48BB78 !important",
            height: "2px !important",
          },
          "& [data-status='complete'] .chakra-step__indicator": {
            background: "#48BB78",
          },
        }}
      >
        {steps.map(step => (
          <Step key={step.label}>
            <StepIndicator width={6} height={6} color="#2D3748">
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>
            <StepTitle>{step.label}</StepTitle>
            <StepSeparator />
          </Step>
        ))}
      </DefaultStepper>
    </Flex>
  );
}
