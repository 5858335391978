import { useFormContext } from "react-hook-form";
import { Checkbox, HStack, Input, Tag, VStack } from "@chakra-ui/react";
import { formatNumberToCurrency, formatNumberToPercentage } from "@shared/utils/string.utils";
import { EditableInput } from "@shared/components/FormComponents/EditableInput.component";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";
import { CustomSelect } from "@shared/components/FormComponents/CustomSelect.component";
import { FinancialAccountWithAllocationAndSelect } from "./DeceasedAccounts.component";
import {
  ACCOUNT_TYPES_SELECT_OPTIONS,
  DISTRIBUTION_REQUEST_VIEW_OPTIONS,
} from "@shared/config/constants.config";
import { InheritanceCaseStatus } from "@trustribbon/ribbon-ec-types";
import { useNavigation } from "@shared/hooks/useNavigation.hook";

type DeceasedAccountProps = {
  idx: number;
  zIndex: number;
  account: FinancialAccountWithAllocationAndSelect;
  isEditing: boolean;
  isLoading: boolean;
  newAccount?: boolean;
  caseStatus?: InheritanceCaseStatus;
};

export function DeceasedAccount({
  idx,
  zIndex,
  account,
  isEditing,
  isLoading,
  newAccount,
  caseStatus,
}: Readonly<DeceasedAccountProps>) {
  const isMobile = useIsMobile();
  const { register, setValue } = useFormContext();
  const { location } = useNavigation();

  const fieldBaseKey = newAccount ? `newAccounts[${idx}]` : `financialAccounts[${idx}]`;
  const shouldDisplayAllocation = !location.pathname.includes("deceased-view");
  const shouldDisplayDistributionMethod =
    (shouldDisplayAllocation && caseStatus === "confirmed") ||
    caseStatus === ("withdrawal_requested" as unknown as InheritanceCaseStatus);

  function vStackWidth() {
    if (isMobile) return "100%";
    if (shouldDisplayDistributionMethod && shouldDisplayAllocation) return 100 / 5 + "%";
    if (shouldDisplayDistributionMethod || shouldDisplayAllocation) return 100 / 4 + "%";
    return 100 / 3 + "%";
  }

  return (
    <>
      <VStack alignItems="flex-start" gap={2} width={vStackWidth()} justifyContent="space-evenly">
        <Input
          defaultValue={account.id}
          sx={{
            visibility: "hidden",
            margin: 0,
            width: 0,
            height: 0,
            position: "absolute",
          }}
          {...register(`${fieldBaseKey}.id`, {
            required: false,
          })}
        />
        <HStack w="100%">
          {!newAccount && (
            <Checkbox
              aria-label="account"
              defaultChecked={account.selected}
              transition="all 0.1s"
              transform="translateX(-1rem)"
              width="20px"
              height="20px"
              isDisabled={isLoading}
              sx={{
                display: isEditing || newAccount ? "flex" : "none",
                "& .chakra-checkbox__control": {
                  transition: "background 0.1s",
                  bg: "#fff",
                  borderColor: "#CBD5E0",
                  width: "20px",
                  height: "20px",
                },
                "& .chakra-checkbox__control:hover": {
                  color: "white",
                  bg: "#319795",
                  borderColor: "#319795",
                },
                "& .chakra-checkbox__control[data-checked]": {
                  borderColor: "#319795",
                  color: "white",
                  bg: "#319795",
                },
              }}
              {...register(`${fieldBaseKey}.selected`, {
                required: false,
              })}
            />
          )}
          <EditableInput
            aria-label="account number"
            name={`${fieldBaseKey}.accountNumber`}
            defaultValue={account.accountNumber ?? ""}
            fontSize={14}
            color="#171923"
            fontWeight={500}
            lineHeight="20px"
            w="100%"
            isEditing={isEditing}
            isLoading={isLoading}
            registerOptions={{ required: "Account Number is required." }}
          />
        </HStack>
      </VStack>

      <VStack alignItems="flex-start" gap={2} width={vStackWidth()} justifyContent="space-evenly">
        <CustomSelect
          isEditing={isEditing}
          name={`${fieldBaseKey}.accountType`}
          placeholder="Select an account type"
          registerOptions={{ required: "Account type is required." }}
          closeMenuOnSelect
          options={ACCOUNT_TYPES_SELECT_OPTIONS}
          isLoading={isLoading}
          inputGroupProps={{
            zIndex,
            backgroundColor: "#fff",
            sx: {
              "& .react-select": {
                width: "100%",
                transform: "translateX(-1.3rem)",
                "& [data-readonly=true]": {
                  borderColor: "transparent",
                  "& div:nth-of-type(2)": {
                    display: "none",
                  },
                },
              },
            },
          }}
          chakraStyles={{
            option: styles => ({
              ...styles,
            }),
            dropdownIndicator: provided => ({
              ...provided,
              px: 4,
            }),
          }}
        />
      </VStack>

      <VStack alignItems="flex-start" gap={2} width={vStackWidth()} justifyContent="space-evenly">
        <EditableInput
          aria-label="account amount"
          name={`${fieldBaseKey}.amount`}
          defaultValue={account.amount ? formatNumberToCurrency(+account.amount) : ""}
          fontSize={16}
          color="#2D3748"
          fontWeight={700}
          lineHeight="24px"
          w="full"
          isEditing={isEditing}
          isLoading={isLoading}
          onChange={e => {
            const value = e.target.value.replace(/[^0-9.]/g, "");
            const newValue = formatNumberToCurrency(+value);

            return setValue(`${fieldBaseKey}.amount`, newValue);
          }}
          registerOptions={{
            required: "Account amount is required.",
          }}
        />
      </VStack>

      {shouldDisplayAllocation && (
        <VStack alignItems="flex-start" gap={2} width={vStackWidth()} justifyContent="space-evenly">
          <EditableInput
            aria-label="allocation percentage"
            name={`${fieldBaseKey}.percentage`}
            defaultValue={account.percentage ? formatNumberToPercentage(+account.percentage) : ""}
            fontSize={16}
            color="#2D3748"
            fontWeight={700}
            lineHeight="24px"
            w="full"
            isEditing={isEditing}
            isLoading={isLoading}
            onChange={e => {
              const value = e.target.value.replace(/[^0-9.]/g, "");
              const maxValue = 100;
              const minValue = 0;
              const allowedValue = Math.min(Math.max(+value, minValue), maxValue);
              const newValue = formatNumberToPercentage(allowedValue / 100);

              return setValue(`${fieldBaseKey}.percentage`, newValue);
            }}
            registerOptions={{
              required: "Inheritor percentage allocation is required.",
            }}
          />
        </VStack>
      )}

      {shouldDisplayDistributionMethod && (
        <VStack alignItems="flex-start" gap={2} width={vStackWidth()} justifyContent="space-evenly">
          <DistributionRequestTag value={account.distributionMethod} />
        </VStack>
      )}
    </>
  );
}

export function DistributionRequestTag({ value }: { value: string | null }) {
  const option = DISTRIBUTION_REQUEST_VIEW_OPTIONS.find(option => option.value === value);

  if (!option) {
    return null;
  }

  return (
    <HStack gap="6px">
      <option.icon color={option.tagColor} size="20px" />

      <Tag
        aria-label="distribution method requested"
        backgroundColor={option.tagColor}
        color={option.textColor}
        fontWeight="700"
        fontSize="14px"
        lineHeight="16px"
        whiteSpace="nowrap"
        borderRadius="2px"
        p="2px 6px"
      >
        {option.label}
      </Tag>
    </HStack>
  );
}
