/* eslint-disable @typescript-eslint/no-explicit-any */
import { captureException } from "@sentry/react";
import {
  BulkCreateFinancialAccountsRequest,
  BulkCreateFinancialAccountsResponse,
  BulkDeleteFinancialAccountsRequest,
  BulkDeleteFinancialAccountsResponse,
  BulkUpdateFinancialAccountsRequest,
  BulkUpdateFinancialAccountsResponse,
  GetDeceasedPersonDetailsResponse,
  GetInheritorsResponse,
  GetInheritorWithDeceasedPersonDetailsResponse,
  ListInheritanceCasesResponse,
  UpdateDeceasedPersonRequest,
  UpdateDeceasedPersonResponse,
  UpdateDeceasedPersonSsnRequest,
  UpdateDeceasedPersonSsnResponse,
  UpdateInheritanceCaseRequest,
  UpdateInheritanceCaseResponse,
  UpdateInheritorRequest,
  UpdateInheritorResponse,
  UpdateInheritorSsnRequest,
  UpdateInheritorSsnResponse,
} from "@trustribbon/ribbon-ec-types";
import { CONSTANTS } from "@config/constants.config";
import { AuthenticationModule } from "@services/authenticate.service";
import { deleteMethod, getMethod, patchMethod, postMethod } from "./base.service";

export const fetchInheritors = async (endpoint: string): Promise<GetInheritorsResponse> => {
  try {
    const accessToken = await AuthenticationModule.getAccessTokenSilently();

    return await getMethod<GetInheritorsResponse>(
      `${CONSTANTS.VITE_APP_ADMIN_API_URL}${endpoint}`,
      {
        headers: accessToken
          ? {
              Authorization: accessToken,
            }
          : undefined,
      }
    );
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const fetchInheritor = async (
  endpoint: string
): Promise<GetInheritorWithDeceasedPersonDetailsResponse> => {
  try {
    const accessToken = await AuthenticationModule.getAccessTokenSilently();

    return await getMethod<GetInheritorWithDeceasedPersonDetailsResponse>(
      `${CONSTANTS.VITE_APP_ADMIN_API_URL}${endpoint}`,
      {
        headers: accessToken
          ? {
              Authorization: accessToken,
            }
          : undefined,
      }
    );
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const fetchDeceased = async (
  endpoint: string
): Promise<GetDeceasedPersonDetailsResponse> => {
  try {
    const accessToken = await AuthenticationModule.getAccessTokenSilently();

    return await getMethod<GetDeceasedPersonDetailsResponse>(
      `${CONSTANTS.VITE_APP_ADMIN_API_URL}${endpoint}`,
      {
        headers: accessToken
          ? {
              Authorization: accessToken,
            }
          : undefined,
      }
    );
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const fetchInheritanceCases = async (
  endpoint: string
): Promise<ListInheritanceCasesResponse> => {
  try {
    const accessToken = await AuthenticationModule.getAccessTokenSilently();

    return await getMethod<ListInheritanceCasesResponse>(
      `${CONSTANTS.VITE_APP_ADMIN_API_URL}${endpoint}`,
      {
        headers: accessToken
          ? {
              Authorization: accessToken,
            }
          : undefined,
      }
    );
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const fetchSSN = async (endpoint: string): Promise<string | null> => {
  try {
    const accessToken = await AuthenticationModule.getAccessTokenSilently();

    return await getMethod<string | null>(`${CONSTANTS.VITE_APP_ADMIN_API_URL}${endpoint}`, {
      headers: accessToken
        ? {
            Authorization: accessToken,
          }
        : undefined,
    });
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const deleteFinancialAccount = async (
  endpoint: string,
  body: BulkDeleteFinancialAccountsRequest
) => {
  try {
    const accessToken = await AuthenticationModule.getAccessTokenSilently();

    return await deleteMethod<
      BulkDeleteFinancialAccountsRequest,
      BulkDeleteFinancialAccountsResponse
    >(`${CONSTANTS.VITE_APP_ADMIN_API_URL}${endpoint}`, body, {
      headers: accessToken
        ? {
            Authorization: accessToken,
          }
        : undefined,
    });
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const postFinancialAccount = async (
  endpoint: string,
  body: BulkCreateFinancialAccountsRequest
) => {
  try {
    const accessToken = await AuthenticationModule.getAccessTokenSilently();

    return await postMethod<
      BulkCreateFinancialAccountsRequest,
      BulkCreateFinancialAccountsResponse
    >(`${CONSTANTS.VITE_APP_ADMIN_API_URL}${endpoint}`, body, {
      headers: accessToken
        ? {
            Authorization: accessToken,
          }
        : undefined,
    });
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const patchFinancialAccount = async (
  endpoint: string,
  body: BulkUpdateFinancialAccountsRequest
) => {
  try {
    const accessToken = await AuthenticationModule.getAccessTokenSilently();

    return await patchMethod<
      BulkUpdateFinancialAccountsRequest,
      BulkUpdateFinancialAccountsResponse
    >(`${CONSTANTS.VITE_APP_ADMIN_API_URL}${endpoint}`, body, {
      headers: accessToken
        ? {
            Authorization: accessToken,
          }
        : undefined,
    });
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const patchSSN = async (
  endpoint: string,
  body: UpdateDeceasedPersonSsnRequest | UpdateInheritorSsnRequest
) => {
  try {
    const accessToken = await AuthenticationModule.getAccessTokenSilently();

    return await patchMethod<
      UpdateDeceasedPersonSsnRequest | UpdateInheritorSsnRequest,
      UpdateDeceasedPersonSsnResponse | UpdateInheritorSsnResponse
    >(`${CONSTANTS.VITE_APP_ADMIN_API_URL}${endpoint}`, body, {
      headers: accessToken
        ? {
            Authorization: accessToken,
          }
        : undefined,
    });
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const patchInheritor = async (endpoint: string, body: UpdateInheritorRequest) => {
  try {
    const accessToken = await AuthenticationModule.getAccessTokenSilently();

    return await patchMethod<UpdateInheritorRequest, UpdateInheritorResponse>(
      `${CONSTANTS.VITE_APP_ADMIN_API_URL}${endpoint}`,
      body,
      {
        headers: accessToken
          ? {
              Authorization: accessToken,
            }
          : undefined,
      }
    );
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const patchDeceasedPerson = async (endpoint: string, body: UpdateDeceasedPersonRequest) => {
  try {
    const accessToken = await AuthenticationModule.getAccessTokenSilently();

    return await patchMethod<UpdateDeceasedPersonRequest, UpdateDeceasedPersonResponse>(
      `${CONSTANTS.VITE_APP_ADMIN_API_URL}${endpoint}`,
      body,
      {
        headers: accessToken
          ? {
              Authorization: accessToken,
            }
          : undefined,
      }
    );
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const patchInheritanceCase = async (
  endpoint: string,
  body: UpdateInheritanceCaseRequest
) => {
  try {
    const accessToken = await AuthenticationModule.getAccessTokenSilently();

    return await patchMethod<UpdateInheritanceCaseRequest, UpdateInheritanceCaseResponse>(
      `${CONSTANTS.VITE_APP_ADMIN_API_URL}${endpoint}`,
      body,
      {
        headers: accessToken
          ? {
              Authorization: accessToken,
            }
          : undefined,
      }
    );
  } catch (error) {
    captureException(error);
    throw error;
  }
};
