import { defineStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const secondaryGray100 = "secondaryGray.100";
const secondaryGray600 = "secondaryGray.600";

const Input = defineStyleConfig({
  // Styles for the base style
  baseStyle: {
    fontWeight: 700,
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    main: props => ({
      field: {
        bg: mode("transparent", "navy.800")(props),
        border: "1px solid",
        color: mode("secondaryGray.900", "white")(props),
        borderColor: mode(secondaryGray100, "whiteAlpha.100")(props),
        borderRadius: "16px",
        fontSize: "sm",
        p: "20px",
        _placeholder: { color: "secondaryGray.400" },
      },
    }),
    whiteLabel: () => ({
      field: {
        transition: "all 0.2s ease-in-out",
        bg: "transparent",
        _invalid: {
          borderColor: "red.500",
          boxShadow: "inset 0 0 0 2px #E53E3E",
        },
        boxShadow: "inset 0 0 3px #007dba",
        _placeholder: { color: secondaryGray600, fontWeight: "400" },
      },
    }),
  },
  // The default `size` or `variant` values
  defaultProps: {},
});

const NumberInput = defineStyleConfig({
  // Styles for the base style
  baseStyle: {
    field: {
      fontWeight: 400,
    },
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    main: () => ({
      field: {
        bg: "transparent",
        border: "1px solid",

        borderColor: secondaryGray100,
        borderRadius: "16px",
        _placeholder: { color: secondaryGray600 },
      },
    }),
  },
  // The default `size` or `variant` values
  defaultProps: {},
});

const Select = defineStyleConfig({
  // Styles for the base style
  baseStyle: {
    field: {
      fontWeight: 400,
      color: "gray.700",
    },
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    main: props => ({
      field: {
        bg: mode("transparent", "navy.800")(props),
        border: "1px solid",
        color: "navy.700",
        borderColor: mode(secondaryGray100, "whiteAlpha.100")(props),
        borderRadius: "16px",
        _placeholder: { color: secondaryGray600 },
      },
      icon: {
        color: secondaryGray600,
      },
    }),
    mini: props => ({
      field: {
        bg: mode("transparent", "navy.800")(props),
        border: "0px solid transparent",
        fontSize: "0px",
        p: "10px",
        _placeholder: { color: secondaryGray600 },
      },
      icon: {
        color: secondaryGray600,
      },
    }),
    subtle: () => ({
      box: {
        width: "unset",
      },
      field: {
        bg: "transparent",
        border: "0px solid",
        color: secondaryGray600,
        borderColor: "transparent",
        width: "max-content",
        _placeholder: { color: secondaryGray600 },
      },
      icon: {
        color: secondaryGray600,
      },
    }),
    transparent: props => ({
      field: {
        bg: "transparent",
        border: "0px solid",
        width: "min-content",
        color: mode(secondaryGray600, secondaryGray600)(props),
        borderColor: "transparent",
        padding: "0px",
        paddingLeft: "8px",
        paddingRight: "20px",
        fontWeight: "700",
        fontSize: "14px",
        _placeholder: { color: secondaryGray600 },
      },
      icon: {
        transform: "none !important",
        position: "unset !important",
        width: "unset",
        color: secondaryGray600,
        right: "0px",
      },
    }),
    whiteLabel: () => ({
      field: {
        bg: "transparent",
        _invalid: {
          borderColor: "red.500",
          boxShadow: "inset 0 0 0 1px #E53E3E",
        },
        boxShadow: "inset 0 0 2px #007dba",
        _placeholder: { color: secondaryGray600, fontWeight: "400" },
      },
    }),
  },
  // The default `size` or `variant` values
  defaultProps: {},
});

const FormLabel = defineStyleConfig({
  // Styles for the base style
  baseStyle: {},
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    formLabel: () => ({
      color: "gray.700",
      fontSize: { base: "md", lg: "lg" },
      _focus: { background: "none" },
    }),
  },
  // The default `size` or `variant` values
  defaultProps: {},
});

const FormErrorMessage = defineStyleConfig({
  // Styles for the base style
  baseStyle: {
    field: {
      color: "red",
    },
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  // The default `size` or `variant` values
  defaultProps: {},
});

export const inputStyles = {
  components: {
    Input,
    NumberInput,
    Select,
    FormLabel,
    FormErrorMessage,
  },
};
