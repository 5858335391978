import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, HStack, Text } from "@chakra-ui/react";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { Button } from "@shared/components/Button/Button.component";

type BreadcrumbsProps = {
  replaceUuidWith?: (uuid: string) => string;
};

export function Breadcrumbs({ replaceUuidWith }: Readonly<BreadcrumbsProps>) {
  const { handleGoBack, currentPathsArray } = useNavigation();

  return (
    <HStack gap={0}>
      <Button variant="whiteLabelGhost" p={0} onClick={() => handleGoBack()}>
        <IoMdArrowBack size={16} />
      </Button>
      <Breadcrumb separator="/">
        {currentPathsArray.map((item, idx) => {
          const isLastIndex = idx === currentPathsArray.length - 1;
          const isRoot = idx === 0;
          const capitalizedItem =
            item.length <= 3 ? item.toUpperCase() : item.charAt(0).toUpperCase() + item.slice(1);
          const itemWithNoSlash = capitalizedItem.replace("/", "");
          const isItemUuid = itemWithNoSlash?.length === 36;
          const normalizedItem =
            isItemUuid && replaceUuidWith
              ? replaceUuidWith(itemWithNoSlash)
              : itemWithNoSlash.replace(/-/g, " ");

          const resolvedPath = `/${currentPathsArray.slice(0, idx + 1).join("/")}`;

          return (
            <BreadcrumbItem
              key={item}
              isCurrentPage={isLastIndex}
              fontWeight={isLastIndex ? 600 : 400}
              fontSize={16}
            >
              {isLastIndex || isRoot ? (
                <BreadcrumbLink as={Link} to={resolvedPath}>
                  {normalizedItem}
                </BreadcrumbLink>
              ) : (
                <Text>{normalizedItem}</Text>
              )}
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </HStack>
  );
}
