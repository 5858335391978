import { CONSTANTS } from "@config/constants.config";

export async function baseFetcher(pathname: string) {
  return fetch(`${CONSTANTS.VITE_APP_ADMIN_API_URL}/${pathname}`).then(async res => {
    return res.json();
  });
}

async function api<T>(path: string, config: RequestInit): Promise<T> {
  const request = new Request(path, config);
  const response = await fetch(request);
  if (!response.ok) {
    throw new Error(response.statusText || "An error occurred");
  }
  // may error if there is no body, return empty array
  return response.json().catch(() => ({}));
}

export async function getMethod<T>(path: string, config?: RequestInit): Promise<T> {
  const init = {
    method: "GET",
    ...config,
  };

  return api<T>(path, init);
}

export async function deleteMethod<T, U>(path: string, body: T, config?: RequestInit): Promise<U> {
  const init = {
    method: "DELETE",
    body: JSON.stringify(body),
    ...config,
  };
  return api<U>(path, init);
}

export async function postMethod<T, U>(path: string, body: T, config?: RequestInit): Promise<U> {
  const init = {
    method: "POST",
    body: JSON.stringify(body),
    ...config,
  };
  return api<U>(path, init);
}

export async function patchMethod<T, U>(path: string, body: T, config?: RequestInit): Promise<U> {
  const init = {
    method: "PATCH",
    body: JSON.stringify(body),
    ...config,
  };
  return api<U>(path, init);
}

export async function putMethod<T extends BodyInit, U>(
  path: string,
  body: T,
  config?: RequestInit
): Promise<U> {
  const init = { method: "PUT", body: body, ...config };
  return api<U>(path, init);
}
