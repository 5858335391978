import {
  NavigateOptions,
  To,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useNavigationStore } from "@shared/store/navigation.store";

export function useNavigation() {
  const defaultNavigate = useNavigate();
  const search = useSearchParams();
  const params = useParams();
  const customLocation = useLocation();

  const { customHistory, addToHistory, removeFromHistory } = useNavigationStore(state => ({
    customHistory: state.customHistory,
    addToHistory: state.addToHistory,
    removeFromHistory: state.removeFromHistory,
  }));

  const navigate = (to: To, options?: NavigateOptions) => {
    const route = typeof to === "string" ? to : to.pathname ?? "";

    if (customHistory[customHistory.length - 1] !== route) {
      addToHistory(route);
    }

    return defaultNavigate(route, options);
  };

  const handleGoBack = (to?: To, options?: NavigateOptions) => {
    if (to) {
      const route = typeof to === "string" ? to : to.pathname ?? "";

      removeFromHistory(route);

      return defaultNavigate(route, { ...options, state: { from: location.pathname } });
    }

    removeFromHistory(customHistory[customHistory.length - 1]);

    return defaultNavigate(-1 as To, { state: { shouldBlock: true } });
  };

  const currentPathname = location.pathname;
  const currentPathsArray = currentPathname.split("/").filter(Boolean);

  return {
    navigate,
    handleGoBack,
    removeFromHistory,
    search,
    params,
    location,
    history,
    customLocation,
    customHistory,
    currentPathname,
    currentPathsArray,
  };
}
