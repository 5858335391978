import { ReactElement, useState } from "react";
import {
  Alert,
  AlertTitle,
  AlertDescription,
  AlertProps,
  IconProps,
  VStack,
  CloseButton,
  AlertIcon,
} from "@chakra-ui/react";
type UseAlertReturn = {
  setShowAlert: (options?: {
    title?: string;
    description?: string;
    status?: AlertProps["status"];
    icon?: ReactElement<IconProps>;
  }) => void;
  AlertComponent: () => JSX.Element | null;
};

export type SetShowAlert = (options?: {
  title?: string;
  description?: string;
  status?: AlertProps["status"];
  icon?: ReactElement<IconProps>;
}) => void;

export function useAlert(duration: number = 5000, hideClose: boolean = false): UseAlertReturn {
  const [alertOptions, setAlertOptions] = useState<{
    title?: string;
    description?: string;
    status?: AlertProps["status"];
    icon?: ReactElement<IconProps>;
  }>({});
  const [showAlertState, setShowAlertState] = useState(false);

  const setShowAlert = (options?: {
    title?: string;
    description?: string;
    status?: AlertProps["status"];
    icon?: ReactElement<IconProps>;
  }) => {
    setAlertOptions(options || {});
    setShowAlertState(true);
  };

  const AlertComponent = () => {
    if (!showAlertState) return null;

    if (duration > 0) {
      setTimeout(() => {
        setShowAlertState(false);
      }, duration);
    }

    const { status, icon, title, description } = alertOptions;
    const renderedIcon = icon ?? <AlertIcon mr={0} />;

    return (
      <Alert status={status} gap={3}>
        {renderedIcon}
        <VStack alignItems="start" justifyContent="center" gap={0} width="full">
          {title && <AlertTitle mr={2}>{title}</AlertTitle>}
          {description && <AlertDescription>{description}</AlertDescription>}
        </VStack>
        {hideClose ? null : (
          <CloseButton
            alignSelf="flex-start"
            position="relative"
            right={-1}
            top={-1}
            onClick={() => setShowAlertState(false)}
          />
        )}
      </Alert>
    );
  };

  return { setShowAlert, AlertComponent };
}
