import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

type DataTableErrorProps = {
  error?: Error;
};

export function DataTableError({ error }: Readonly<DataTableErrorProps>) {
  return (
    <TableContainer borderRadius="12px" border="1px solid #CBD5E0" backgroundColor="white" w="full">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th paddingTop={6} paddingBottom={3} px={8}>
              Error
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td paddingTop={4} paddingBottom={3} px={8}>
              {error?.message ?? "An error occurred"}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
}
