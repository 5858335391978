import { useRouteError } from "react-router-dom";
import { Flex, Heading, Text } from "@chakra-ui/react";

export function NotFoundPage() {
  const error = useRouteError() as Error;

  return (
    <Flex direction="column" gap={4}>
      <Heading>Page not found</Heading>
      <Text>{error.message}</Text>
    </Flex>
  );
}
