import { Code, Flex, VStack, useClipboard } from "@chakra-ui/react";
import { useNavigation } from "@shared/hooks/useNavigation.hook";
import { Button } from "@shared/components/Button/Button.component";

export function Embed() {
  const { location } = useNavigation();
  const iframeStyle = {
    border: 0,
    width: "100%",
    height: "100%",
    "min-width": "240px",
    "min-height": "508px",
  };

  const currentIframeSrc = `${location.origin}/estate-services/passed-one-information`;
  const iframeStyleString = JSON.stringify(iframeStyle).replace(/[{}"]/g, "").replace(/,/g, ";");
  const iframeCode = `
    <iframe
    src="${currentIframeSrc}"
    width="100%"
    height="100%"
    frameBorder="0"
    sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
    style=${iframeStyleString}
    ></iframe>
  `;

  const { onCopy, hasCopied } = useClipboard(iframeCode);

  return (
    <Flex p={4} boxShadow="md" width="full">
      <VStack alignItems="flex-end" width="full">
        <Code display="block" width="full">
          {iframeCode}
        </Code>
        <Button size="lg" onClick={onCopy}>
          {hasCopied ? "Copied!" : "Copy"}
        </Button>
      </VStack>
    </Flex>
  );
}
