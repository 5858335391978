import { ReactNode } from "react";
import { Card, HStack, Text, VStack } from "@chakra-ui/react";

type DocumentUploadLoadingProps = {
  label: string;
  icon: ReactNode;
  documentName?: string;
  media?: ReactNode;
};

export function DocumentUploadLoading({
  label,
  icon,
  documentName,
  media,
}: Readonly<DocumentUploadLoadingProps>) {
  return (
    <VStack>
      {media}
      <Card height="40px" alignItems="center" justifyContent="center" px="16px" boxShadow="md">
        <HStack gap={3}>
          <HStack gap={1}>
            <Text as="span" fontWeight={600} fontSize="14px" lineHeight="20px" color="#2D3748">
              {label}
            </Text>
            {documentName && (
              <Text
                as="span"
                fontWeight={400}
                fontSize="14px"
                lineHeight="20px"
                color="#718096"
                maxW="350px"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                pl="8px"
              >
                {documentName}
              </Text>
            )}
          </HStack>
          {icon}
        </HStack>
      </Card>
    </VStack>
  );
}
