import { HStack } from "@chakra-ui/react";
import { FormInput, FormInputProps } from "@shared/components/FormComponents/FormInput.component";
import { ssnMask } from "@shared/config/masks.config";
import { useIsMobile } from "@shared/hooks/useIsMobile.hook";

type PIIInputsProps = {
  formInputProps?: Partial<FormInputProps>;
};

export function PIIInputs({ formInputProps }: Readonly<PIIInputsProps>) {
  const isMobile = useIsMobile();
  const defaultFormInputProps = formInputProps ?? {
    size: "lg",
    labelProps: { backgroundColor: "#F8F8F8" },
    formControlProps: {
      width: "auto",
      flexGrow: 1,
    },
  };

  return (
    <>
      <HStack gap={6} align="flex-start" direction="row" flexWrap="wrap">
        <FormInput
          data-testid="first-name-input"
          label="First Name"
          name="firstName"
          registerOptions={{ required: true }}
          {...defaultFormInputProps}
        />
        <FormInput
          data-testid="middle-name-input"
          label={isMobile ? "Middle Name" : "Middle Name (optional)"}
          name="middleName"
          registerOptions={{ required: false }}
          {...defaultFormInputProps}
        />
      </HStack>
      <HStack gap={6} align="flex-start" direction="row" flexWrap="wrap">
        <FormInput
          data-testid="last-name-input"
          label="Last Name"
          name="lastName"
          registerOptions={{ required: true }}
          {...defaultFormInputProps}
        />
        <FormInput
          data-testid="ssn-input"
          label={isMobile ? "SSN" : "Social Security Number"}
          name="ssn"
          type="password"
          variant="whiteLabel"
          mask={ssnMask}
          maxLength={11}
          registerOptions={{ required: true }}
          {...defaultFormInputProps}
        />
      </HStack>
    </>
  );
}
