import {
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Textarea,
  UseModalProps,
  VStack,
} from "@chakra-ui/react";
import { Button } from "@shared/components/Button/Button.component";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";

export interface RequestMissingDocumentsModalProps {
  isOpen: boolean;
  onClose: UseModalProps["onClose"];
  onSave: (data: RequestMissingDocumentsForm) => void;
}

interface RequestMissingDocumentsForm {
  instructions: string;
  "government-ids": boolean;
  "letters-of-administration": boolean;
  "power-of-attorneys": boolean;
  "account-closure-forms": boolean;
  "account-opening-forms": boolean;
  "trust-agreements": boolean;
  "certificate-of-trusts": boolean;
  wills: boolean;
  "letters-of-instruction": boolean;
  "death-certificates": boolean;
  "letters-of-testamentary": boolean;
  "small-estate-affidavits": boolean;
}

const initialFormValues: RequestMissingDocumentsForm = {
  instructions: "",
  "government-ids": false,
  "letters-of-administration": false,
  "power-of-attorneys": false,
  "account-closure-forms": false,
  "account-opening-forms": false,
  "trust-agreements": false,
  "certificate-of-trusts": false,
  wills: false,
  "letters-of-instruction": false,
  "death-certificates": false,
  "letters-of-testamentary": false,
  "small-estate-affidavits": false,
};

interface MissingDocument {
  label: string;
  value: keyof RequestMissingDocumentsForm;
}

const missingDocuments: MissingDocument[] = [
  { label: "Government ID", value: "government-ids" },
  { label: "Letters of Administration", value: "letters-of-administration" },
  { label: "Power of Attorney", value: "power-of-attorneys" },
  { label: "Account Closure Form", value: "account-closure-forms" },
  { label: "Account Opening Form", value: "account-opening-forms" },
  { label: "Trust Agreement", value: "trust-agreements" },
  { label: "Certificate of Trust", value: "certificate-of-trusts" },
  { label: "Will", value: "wills" },
  { label: "Letters of Instruction", value: "letters-of-instruction" },
  { label: "Death certificate", value: "death-certificates" },
  { label: "Letters of Testamentary", value: "letters-of-testamentary" },
  { label: "Small Estate Affidavit", value: "small-estate-affidavits" },
];

export function RequestMissingDocumentsModal({
  isOpen,
  onClose,
  onSave,
}: Readonly<RequestMissingDocumentsModalProps>) {
  const formMethods = useForm({ defaultValues: initialFormValues });
  const { handleSubmit, register, control, formState } = formMethods;

  const handleSubmitForm: SubmitHandler<RequestMissingDocumentsForm> = data => {
    console.log("submitting form");
    onSave(data);
  };

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          fontWeight={700}
          display="flex"
          flexDir="column"
          alignItems="flex-start"
          gap="8px"
        >
          Request missing document
          <Text color="gray.600" fontSize="14px" lineHeight="20px" fontWeight="400">
            The documents that will be selected must be uploaded by the inheritor in order to
            complete the inheritance process.
          </Text>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody p="24px" pb="32px">
          <FormProvider {...formMethods}>
            <form id="request-missing-documents" onSubmit={handleSubmit(handleSubmitForm)}>
              <SimpleGrid columns={2} spacingX="24px" spacingY="16px">
                {missingDocuments.map(({ label, value }) => (
                  <Controller
                    control={control}
                    name={value}
                    defaultValue={false}
                    key={value}
                    render={({ field: { onChange, value: controllerValue, ref } }) => (
                      <Checkbox
                        ref={ref}
                        onChange={onChange}
                        isChecked={!!controllerValue}
                        colorScheme="teal"
                        color="gray.800"
                        fontWeight="600"
                      >
                        {label}
                      </Checkbox>
                    )}
                  />
                ))}
              </SimpleGrid>

              <VStack
                align="flex-start"
                mt="24px"
                maxH={formState.isDirty ? "200px" : "0px"}
                overflow="hidden"
                transition="all 0.1s ease-in-out"
              >
                <Text fontWeight="600" color="gray.800">
                  Instructions:
                </Text>
                <Text color="gray.600" fontSize="14px" lineHeight="20px" fontWeight="400">
                  Add any additional instructions or information for the inheritor to know about
                </Text>

                <Textarea size="sm" {...register("instructions", {})} />
              </VStack>
            </form>
          </FormProvider>
        </ModalBody>

        <ModalFooter display="flex" gap="16px">
          <Button
            w="100%"
            variant="outline"
            borderRadius="6px"
            _hover={{ bg: "whiteLabel.primary", color: "white" }}
            onClick={onClose}
            aria-label="Cancel"
          >
            Cancel
          </Button>
          <Button
            w="100%"
            variant="whiteLabelOutline"
            aria-label="Request documents"
            form="request-missing-documents"
            type="submit"
          >
            Request documents
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
