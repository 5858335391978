import { defineStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const whiteAlpha100 = "whiteAlpha.100";
const secondaryGray300 = "secondaryGray.300";
const secondaryGray400 = "secondaryGray.400";

const Button = defineStyleConfig({
  // Styles for the base style
  baseStyle: props => ({
    borderRadius: "6px",
    boxShadow: "45px 76px 113px 7px rgba(112, 144, 176, 0.08)",
    transition:
      ".25s color ease, .25s height ease, .25s width ease, .25s background ease, .25s filter ease, .25s transform ease",
    boxSizing: "border-box",
    textWrap: "wrap",
    color: props.colorMode === "light" ? "#171923" : "#ffffff",
    _focus: {
      boxShadow: "none",
    },
    _active: {
      boxShadow: "none",
    },
  }),
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    outline: () => ({
      borderRadius: "16px",
    }),
    gold: props => ({
      bg: mode("gold.400", "gold.500")(props),
      color: "white",
      _focus: {
        bg: mode("gold.500", "gold.400")(props),
      },
      _active: {
        bg: mode("gold.500", "gold.400")(props),
      },
      _hover: {
        bg: mode("gold.500", "gold.400")(props),
      },
    }),
    brand: props => ({
      bg: mode("brand.500", "brand.400")(props),
      color: "white",
      _focus: {
        bg: mode("brand.500", "brand.400")(props),
      },
      _active: {
        bg: mode("brand.500", "brand.400")(props),
      },
      _hover: {
        bg: mode("brand.600", "brand.400")(props),
      },
    }),
    lightBrand: props => ({
      bg: mode("#F2EFFF", whiteAlpha100)(props),
      color: mode("brand.500", "white")(props),
      _focus: {
        bg: mode("#F2EFFF", whiteAlpha100)(props),
      },
      _active: {
        bg: mode(secondaryGray300, whiteAlpha100)(props),
      },
      _hover: {
        bg: mode(secondaryGray400, "whiteAlpha.200")(props),
      },
    }),
    light: props => ({
      bg: mode(secondaryGray300, whiteAlpha100)(props),
      color: mode("secondaryGray.900", "white")(props),
      _focus: {
        bg: mode(secondaryGray300, whiteAlpha100)(props),
      },
      _active: {
        bg: mode(secondaryGray300, whiteAlpha100)(props),
      },
      _hover: {
        bg: mode(secondaryGray400, "whiteAlpha.200")(props),
      },
    }),
    action: props => ({
      fontWeight: "500",
      borderRadius: "50px",
      bg: mode(secondaryGray300, "brand.400")(props),
      color: mode("brand.500", "white")(props),
      _focus: {
        bg: mode(secondaryGray300, "brand.400")(props),
      },
      _active: { bg: mode(secondaryGray300, "brand.400")(props) },
      _hover: {
        bg: mode("secondaryGray.200", "brand.400")(props),
      },
    }),
    hookLaw: props => ({
      fontWeight: "500",
      borderRadius: "50px",
      bg: mode("#243b5c", "#33527f")(props),
      color: mode("black.800", "white")(props),
      _focus: {
        bg: mode("#243b5c", "#33527f")(props),
      },
      _active: { bg: mode("#243b5c", "brand.400")(props) },
      _hover: {
        bg: mode("#142339", "#243b5c")(props),
      },
    }),
    setup: props => ({
      fontWeight: "500",
      borderRadius: "50px",
      bg: mode("transparent", "brand.400")(props),
      border: mode("1px solid", "0px solid")(props),
      borderColor: mode(secondaryGray400, "transparent")(props),
      color: mode("secondaryGray.900", "white")(props),
      _focus: {
        bg: mode("transparent", "brand.400")(props),
      },
      _active: { bg: mode("transparent", "brand.400")(props) },
      _hover: {
        bg: mode("secondaryGray.100", "brand.400")(props),
      },
    }),
    whiteLabelSidebarItem: props => ({
      color: props.colorMode === "light" ? "#718096" : "#ffffff",
      bg: mode("#EDF2F7", "whiteLabel.secondary")(props),
      borderRadius: props.theme.radii.whiteLabel.buttonBorderRadius,
      _hover: {
        color: mode("#171923", "#ffffff")(props),
        bg: mode("#EDF2F7", "whiteLabel.primary")(props),
      },
      _active: {
        color: mode("#171923", "#ffffff")(props),
        bg: mode("#EDF2F7", "whiteLabel.primary")(props),
      },
    }),
    whiteLabelLink: () => ({
      height: "inherit",
      color: "whiteLabel.primary",
      textWrap: "nowrap",
      _hover: {
        textDecoration: "underline",
      },
    }),
    whiteLabelGhost: props => ({
      color: props.colorMode === "light" ? "#171923" : "#ffffff",
      _hover: {
        textShadow: "0px 0px 0px rgba(0, 0, 0, 0.25)",
      },
    }),
    whiteLabelOutline: props => ({
      color: "whiteLabel.primary",
      border: `1px solid ${props.theme.colors.whiteLabel.primary}`,
      borderRadius: props.theme.radii.whiteLabel.buttonBorderRadius,
      _hover: {
        bg: "whiteLabel.primary",
        color: "white",
      },
    }),
    whiteLabel: props => ({
      color:
        props.theme.button?.whiteLabel?.color ??
        (props.colorMode === "light" ? "#ffffff" : "#171923"),
      bg:
        props.theme.button?.whiteLabel?.bg ??
        mode("whiteLabel.primary", "whiteLabel.secondary")(props),
      borderRadius:
        props.theme.button?.whiteLabel?.borderRadius ??
        props.theme.radii.whiteLabel.buttonBorderRadius,
      borderColor: props.theme.button?.whiteLabel?.borderColor ?? "whiteLabel.borderColor",
      _focus: {
        bg: mode("whiteLabel.primary", "whiteLabel.secondary")(props),
      },
      _active: {
        bg: mode("whiteLabel.primary", "whiteLabel.secondary")(props),
      },
      _hover: {
        color: props.theme.button?.whiteLabel?.hover.color ?? "#ffffff",
        bg:
          props.theme.button?.whiteLabel?.hover.bg ??
          mode("whiteLabel.secondary", "whiteLabel.primary")(props),
        _disabled: {
          bg: mode("whiteLabel.secondary", "whiteLabel.secondary")(props),
        },
      },
    }),
  },
  // The default `size` or `variant` values
  defaultProps: {},
});

export const buttonStyles = {
  components: {
    Button,
  },
};
